import { SPSAuthToken } from './SPSSettings';
import { SPSBatch, SPSStatusBatch, SPSDeleteBatch, SPSGetBatch } from './SPSBatchs';
import {SPSLocalSites} from  './SPSSites';
import axios from "axios";
import { getThemeProps } from '@mui/system';
import { json } from 'react-router-dom';
const token = SPSAuthToken();
const Xvisite = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "stoptask", 
  headers : { Authorization : 'Bearer ' + token }
});

var SetSTCallBack = false;
var idSTLocalInSaved = 0;
var dbTableNameST = 'stoptask';

// Initialisation de l'objet indexedDB qui stocke les arrets de tache en local sur le navigateur client
let openRequestST = indexedDB.open(dbTableNameST, 1);
let db = null;

openRequestST.onupgradeneeded = function(event) {
  // déclenche si le client n'avait pas de base de données
  // ...effectuer l'initialisation...
  db = openRequestST.result;
  switch(event.oldVersion) { // version db existante
    case 0:
      // la version 0 signifie que le client n'avait pas de base de données
      // effectuer l'initialisation
      if (!db.objectStoreNames.contains(dbTableNameST)) {
        // s'il n'y a pas de magasin "books"
        db.createObjectStore(dbTableNameST, {keyPath: 'id'}); // créez-le
      }
    case 1:
      // le client avait la version 1
      // mettre à jour
  }
};

openRequestST.onerror = function() {
  console.error("Error", openRequestST.error);
};

openRequestST.onsuccess = function() {
  db = openRequestST.result;
};


function SPSAddLocalST(data)
{
  let transaction = db.transaction(dbTableNameST, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let st = transaction.objectStore(dbTableNameST); // (2)

  let request = st.add(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("Stop Task added to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSUpdateLocalST(data)
{
  let transaction = db.transaction(dbTableNameST, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let st = transaction.objectStore(dbTableNameST); // (2)
  let request = st.put(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("Stop Task updated to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSDeleteLocalST(id)
{
  let transaction = db.transaction(dbTableNameST, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let st = transaction.objectStore(dbTableNameST); // (2)

  let request = st.delete(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("Stop Task deleted of the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSGetLocalST(id, SetDatas)
{
  let transaction = db.transaction(dbTableNameST, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let st = transaction.objectStore(dbTableNameST); // (2)

  let request = st.get(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("Stop Task loaded of the store", request.result);
    SetDatas(request.result);
  };

  request.onerror = function() {
    console.log("Error ouverture local stop task", request.error);
    SetDatas([]);
  };
}

/*************************************************************
 * SPSLocalST
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des arrets de tache contenues en local (localstorage)
 *************************************************************/
function SPSLocalST(SetDatas)
{
  let transaction = db.transaction(dbTableNameST, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let st = transaction.objectStore(dbTableNameST); // (2)

  let request = st.getAll(); // (3)

  request.onsuccess = function() { // (4)
    if (request.result !== undefined) {
      request.result.forEach((d) => {
        if(SPSStatusBatch("stoptask", d.id)==0)
          d.local=true;
        else
          d.local = false;
      });
      SetDatas(request.result);
    } else {
      console.log("aucun ST local");
    }
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
/*
  var st = [];
  const ls = localStorage.getItem('stoptask');
  if(ls)
  {
    try {
      st = JSON.parse(ls);
    } catch (e) {
      st = [];
    }

    
  }
  if(!st.length || st[0].id == undefined) 
      st = [];
  else
  {
    // on ajoute le libellé de l'opération pour qu'il apparaisse dans la liste
    for(var i=0; i < st.length; i++)
    {
      if(st[i].IdSite > 0 && !st[i].site_Title)
      {
        const sites = SPSLocalSites();
        const s = sites.filter((site) => site.id == st[i].IdSite);
        if(s.length > 0)
          st[i].site_Title = s[0].Title;
      }
    }
  }
    
  return st;*/
}

export async function SPSSynchroST(callback)
{
  // on ne lance un synchro que si le navigateur est en ligne...
  if(window.SpsServerAvailable)
  {
    // ensuite on ne lance une synchro que s'il y a des batchs à mettre à jour
    const batchs = SPSGetBatch('stoptask');
    var idbatchs = [];
    for(var i=0; i < batchs.length; i++)
    {
      if(batchs[i].status==0)
      {
        idbatchs.push(batchs[i].id);
      }
    }
    // nous avons plusieurs batchs à mettre à jour
    if(idbatchs.length > 0)
    {
      // il faut savoir si nous avons reellement une connexion internet
      try {
        const st = await Xvisite.get('/');
        if(st)
        {
          callback(1);
          // nous avons eu un retour donc on peut synchroniser
          let transaction = db.transaction(dbTableNameST, "readonly"); // (1)

          let st = transaction.objectStore(dbTableNameST); // (2)

          let request = st.getAll(); // (3)

          request.onsuccess = function() { // (4)
            if (request.result !== undefined) {
              const filtered = request.result.filter(i => idbatchs.includes(i['id']));
              filtered.forEach((d) => {
                SPSSaveStopTask(d, null, null, true);
                SPSDeleteLocalST(d.id);
                SPSDeleteBatch('stoptask', d.id);
              });
              callback(0);
            } 
          };

          request.onerror = function() {
            callback(-1);
            console.log("Error", request.error);
          };
        }


      } catch(err) {
        // erreur donc pas de connexion on arrete la
        console.log("SYNCHRO ST ERROR : NO INTERNET : "+err);
        callback(-1);
      }
    }
  }
}

/*************************************************************
 * SPSStoreLocalVisites
 * -----------------------------------------------------------
 * data : tableau des visites locales
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
function SPSStoreLocalST(data)
{
  var tx;
  tx = db.transaction([dbTableNameST], "readwrite") // in read write
  data.forEach(d => {
    let request = tx.objectStore(dbTableNameST).add(d);
  });
  //localStorage.setItem('stoptask', JSON.stringify(data));
}

/*************************************************************
 * SPSGetSiteStopTasks
 * -----------------------------------------------------------
 * idSite : id de  l'operation
 * showDatas : fonction appelée avec le resultat
 * -----------------------------------------------------------
 * Recherche les arrets de tache de l'operation passée
 *************************************************************/
export async function SPSGetSiteStopTasks(idSite, showDatas)
{
  try {
    const st = await Xvisite.get('/site/'+idSite);
    showDatas(st.data);
  } catch(err) {

  }
}

export async function SPSDownloadRapportST(IdST, ref)
{
  const pdf = await Xvisite.get('/rapport/?id='+IdST, {responseType: 'blob'});
  const url = URL.createObjectURL(new Blob([pdf.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ref+".pdf");
      link.click();
}

/*************************************************************
 * SPSGetStopTasks
 * -----------------------------------------------------------
 * SetDatas : Callback a appeler avec les données
 * -----------------------------------------------------------
 * Retourne la liste des stop tasks (local ou serveur)
 *************************************************************/
export async function SPSGetStopTasks(SetDatas, type, search)
{
  var stoptasks = [];
  var returnlocal = false;

  if(window.SpsServerAvailable)
  {
    try {
      var qs = 'type='+type;
      if(search)qs+= '&search='+search;
      const datas = await Xvisite.get('/?'+qs);
      
      // il faut également charger les informations locales
      SPSLocalST((localST) => {
        var stoptasks = [];
        for(var i=0; i < localST.length; i++)
        {
          if(SPSStatusBatch('stoptask', localST[i].id) == 0)
          {
            localST[i].local = true;
            stoptasks.push(localST[i]);
          }
          else
          {
            // on supprime cette version locale qui est peut etre oboslete
            SPSDeleteLocalST(localST[i].id);
          }
        }  
        stoptasks = stoptasks.concat(datas.data);
        SetDatas(stoptasks);
      });    
      
      // stock cette selection dans le JSON local
      //SPSStoreLocalST(datas.data);
    } catch(err) {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  
  if(returnlocal)
  {
    stoptasks = SPSLocalST(SetDatas);
  }
  /*else {
    SetDatas(stoptasks);
  }*/
}

/*************************************************************
 * SPSGetStopTask
 * -----------------------------------------------------------
 * id : id de l'arret de tache a retourner
 * SetTheValue : Callback a appeler avec les données
 * -----------------------------------------------------------
 * Retourne l'arret de tache demandé
 *************************************************************/
export async function SPSGetStopTask(id, SetTheValue)
{
  var returnlocal = false;
  var modeoffline = false;

  if(!window.SpsServerAvailable)
    modeoffline = true;

  if(id > 0)
  {
    const status = SPSStatusBatch('stoptask', id);
    if(status == 0) // si c'est egale à 0, cela veut dire que la derniere version est en local 
    {
      returnlocal = true;
    }
  }
  else  // l'Id est negatif donc c'est du local
    returnlocal = true;

  if(window.SpsServerAvailable && returnlocal==false)  
  {
    try {
      const st = await Xvisite.get('/'+id);
      // recherche cette visite dans le JSON local pour l'avoir en local
      SPSGetLocalST(id, (datas) => {
      if(!datas)
        SPSAddLocalST(st.data[0]); //temp.push(datas.data[0]);
      else
        SPSUpdateLocalST(st.data[0]); //temp[index] = datas.data[0];
      //SPSStoreLocalST(temp);
      SetTheValue(st.data[0]);
      });
      
    }
    catch(err) {
      returnlocal = true;
      modeoffline = true;
    }
    
  }
  else
    returnlocal=true;

  if(returnlocal)
  {
    SPSGetLocalST(id, (datas) => {
      if(datas && datas.id)
      {
        if(!modeoffline)
        {
          SPSSaveLocalST(datas, SetTheValue);
        }
        else
          SetTheValue(datas);
      }
      else
        SetTheValue([]);
    });
  }
}
/*export async function SPSGetStopTask(id, SetTheValue)
{
  var returnlocal = false;
  if(navigator.onLine)
  {
    // recherche du batch de cet arret de tache
    const status = SPSStatusBatch('stoptask', id);
    if(status == 0) // celui-ci est stocké en local. Il faut peut etre tenter de l'enregistrer
    {
      SPSGetLocalST(id, (datas) => {
        SPSSaveLocalST(datas, SetTheValue);
      });
      
    }
    else
    {
      try {
        const st = await Xvisite.get('/'+id);
        
        // recherche cette visite dans le JSON local
       SPSGetLocalST(id, (datas) => {
          if(datas.length==0)
            SPSAddLocalST(st.data[0]); //temp.push(datas.data[0]);
          else
            SPSUpdateLocalST(st.data[0]); //temp[index] = datas.data[0];
          //SPSStoreLocalST(temp);
          SetTheValue(st.data[0]);
        });
        
      }
      catch(err) {
        returnlocal = true;
      }
    }
  }
  else
    returnlocal=true;

  if(returnlocal)
  {
    SPSGetLocalST(id, (datas) => {
      if(datas.length==0)
        SetTheValue(datas);
      else
        SetTheValue([]);
      
    });
    
  }
}*/

/*************************************************************
 * findStopTask
 * -----------------------------------------------------------
 * id : id de l'arret de tache a trouver
 * -----------------------------------------------------------
 * Retourne l'indice local de l'arret de tache demandé
 *************************************************************/
/*function findStopTask(id)
{
  const temp = SPSLocalST();
  let index = -1;
  for(var i = 0; i < temp.length && index < 0; i++)
  {
    if(temp[i].id == id)
      index = i;
  }

  return index;
}*/

/*************************************************************
 * SPSDeleteStopTask
 * -----------------------------------------------------------
 * id : id de l'arret de tache a trouver
 * afterDelete : callback appelé après la suppression
 * -----------------------------------------------------------
 * Supprime l'arret de tache demandé
 *************************************************************/
export async function SPSDeleteStopTask(id, afterDelete)
{
  if(id > 0)
  {
    if(window.SpsServerAvailable)
    {
      try {
        const result = await Xvisite.delete("/"+id);
      } catch(err)
      {
        alert("Erreur lors de la suppression. Veuillez vérifier votre connexion internet !")
      }
    }
    else
      alert("Vous devez être connecté pour utiliser cette fonction!");
  }
  else
  {
    SPSDeleteLocalST(id);
    /*const lst = SPSLocalST();
    const stoptasks = lst.filter((st) => st.id != id);
    SPSStoreLocalST(stoptasks);*/
  }

  // il faut supprimer les batchs de cette visite
  SPSDeleteBatch("stoptask", id);

  afterDelete();
}

/*************************************************************
 * SPSSaveStopTask
 * -----------------------------------------------------------
 * newtask : objet de l'arret de tache a enregistrer
 * setIntertedId : Callback à appeler avec son id
 * setShowForm : Callback appelé pour l'affichage
 * -----------------------------------------------------------
 * Enregistre l'arret de tâche envoyé
 *************************************************************/
export async function SPSSaveStopTask(newtask, setIntertedId, setShowForm, onlineuniquement)
{
  var v = [];
  var status = 0;
  var savelocal = false;
  

  if(window.SpsServerAvailable)
  {
    
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      IdSite: newtask.IdSite,
      Description: newtask.Description,
      Localisation: newtask.Localisation,
      Risque: newtask.Risque,
      Concerne: newtask.Concerne,
      IdContact: newtask.IdContact,
      ActionCorrective: newtask.ActionCorrective,
      DateStop: newtask.DateStop,
      ControllerSignature: newtask.ControllerSignature,
      EmployeeSignature: newtask.EmployeeSignature,
      EmployeeName: newtask.EmployeeName,
      Photos: newtask.Photos
      
    };

    if(newtask.Rapport != undefined)
    {
      body.Rapport = newtask.Rapport;
    }
    if(newtask.Recipients != undefined)
    {
      body.Recipients = newtask.Recipients;
    }
    var result = [];
    try {
      var idorig = newtask.id;
      if(newtask.id > 0)
      {
        result = await Xvisite.put("/"+newtask.id, body);
        setIntertedId(newtask.id);
        //if(setShowForm)
        //  setShowForm(0);
      }
      else
      {
        
        result = await Xvisite.post("/", body);
        setIntertedId(result.data[0].id);
        newtask.id = result.data[0].id;
        //if(setShowForm)
        //  setShowForm(0);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
      {
        status = 1;
        // si nous sommes sur un enregistrement d'un local qui passe en "web", il change d'id et donc on supprime l'ancien batch
        if(idorig < 0)
          SPSDeleteBatch("stoptask", idorig);
      }
      
        SPSBatch("stoptask", newtask.id, status);
        if(setShowForm)
          setShowForm(0);

    } catch(err) {
      savelocal  =true;
    }
    
  }
  else
    savelocal = true;

  if(savelocal)
  {
    if(onlineuniquement)
    {
      // si on est en mode online uniquement, on se contente de charger le local à l'affichage
      if(SetSTCallBack)
        SetSTCallBack(newtask);
    }
    else
    {
      /*var stoptasks = SPSLocalST();
      // on enregistre de toutes facons en local
      if(stoptasks.length)
          v = stoptasks.filter((val) => val.id == newtask.id);*/
      if(newtask.id == 0)
      {
        newtask.id = (new Date().getTime()) * -1;
        newtask.local = true;
        SPSAddLocalST(newtask);
        setShowForm(0);
        SPSBatch("stoptask", newtask.id, status);
      }
      else
      {
        // autrement on cherche s'il existe en local 
        SPSGetLocalST(newtask.id, (datas) => {
          if(datas.id)
            SPSUpdateLocalST(newtask);
          else
            SPSAddLocalST(newtask);
          setShowForm(0);
          SPSBatch("stoptask", newtask.id, status);
        });
      }
    }
      
  
    
  }
 
  SPSBatch("stoptask", newtask.id, status);
}

/*************************************************************
 * SPSSaveLocalST
 * -----------------------------------------------------------
 * st : objet contenant l'arret de tache a enregistrer
 * SetTheVisit : Callback ou envoyer les données
 * -----------------------------------------------------------
 * Enregistre une visite enregistrée en local vers le serveur
 *************************************************************/
async function SPSSaveLocalST(st, SetTheST)
{
  SetSTCallBack = SetTheST;
  idSTLocalInSaved = st.id;
  SPSSaveStopTask(st, SPSAfterSavedLocalST, false, true);
}


/*************************************************************
 * SPSAfterSavedLocalST
 * -----------------------------------------------------------
 * id : id de l'arret de tâche fraichement enregistré
 * -----------------------------------------------------------
 * nettoie les batch et enlève l'enregistrement temporaire en local
 * Ensuite charge la visite pour l'afficher
 *************************************************************/
function SPSAfterSavedLocalST(id)
{
  // il faut nettoyer les batchs et les enregistrements locaux
  SPSDeleteBatch('stoptask', idSTLocalInSaved);
  // suppression de la fiche de cet ID en local
  SPSDeleteLocalST(idSTLocalInSaved);
  /*const lst= SPSLocalST();
  const newLst = lst.filter((st) => st.id != idSTLocalInSaved);
  SPSStoreLocalST(newLst);*/
  SPSGetStopTask(id, SetSTCallBack);
}

/*************************************************************
 * SPSSendRapportTask
 * -----------------------------------------------------------
 * IdTask : Id de l'arret de tache
 * afterSendRapport : Callback après l'envoi
 * -----------------------------------------------------------
 * demande l'envoi du rapport d'arret de tache
 *************************************************************/
export async function SPSSendRapportTask(IdTask, afterSendRapport)
{
  if(window.SpsServerAvailable)
  {
    try {
      var result = [];
      result = await Xvisite.get("/sendrapport/?id="+IdTask);
    } catch(err) {
      alert("Erreur, veuillez vérifier votre connexion internet !");
    }
  }
  else
  {
    alert("Vous devez être connecté pour utiliser cette fonction!");
  }
  afterSendRapport();
}
