import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faPaperPlane, faTrashCan,  faCamera, faImage, faPencil, faTriangleExclamation, faLightbulb, faMagnifyingGlass, faSave,faRotate, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Dropdown from 'react-bootstrap/Dropdown';
import Toast from 'react-bootstrap/Toast';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { SPSMyVisites, SPSGetVisite, SPSSaveVisit, SPSSendRapport, SPSDeleteVisit, SPSSynchroVisits } from '../functions/SPSVisites';
import { SPSSiteLabel, SPSSiteLabelLocal } from '../functions/SPSSites';
import { datetime_to_fr, getCurrentDate,getCurrentTime } from '../functions/tools';
import { SPSGetMemos } from '../functions/SPSSettings';
import  {SPSMemos, SPSCatMemos, PathDocumentMemo} from '../functions/SPSMemos';
import ModalSearchSite from './ModalSearchSite';
import ModalPhoto from './ModalPhoto';
import ModalCamera from './ModalCamera';
import ModalGallery from './ModalGallery';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SPSLoader from './SPSLoader';
import { Editor } from '@tinymce/tinymce-react';
import MemoDocuments from './MemoDocuments';
import OfflineIco from './Offline';

import parse from 'html-react-parser';




function SiteVisit(props) {

  const [stateWindowVisit, setStateWindowVisit] = useState(0);
  const [stateModalSearchSite, setStateSearchSite] = useState(false);
  const [stateModalPhoto, setStatePhoto] = useState(false);
  const [stateModalRemark, setStateModalRemark] = useState(false);
  const [showEnvoiRapport, setShowEnvoiRapport] = useState(false);
  const [sitevisits, SetSitesVisits] = useState([]);
  const [theVisit, SetTheVisit] = useState([]);
  const [photoToEdit, SetEditPhoto] = useState({id: 0, Photo: "", Description: ""});
  const [currentFilter, SetFilter] = useState("MY");
  const [currentSearchFilter, SetSearchFilter] = useState("");
  const [firstInit, SetFirstInit] = useState(false);

  const [showCamera, setShowCamera] = useState(false);
  const [ongletEdit, setOngletEdit] = useState(1);
  
  var insertedId = 0;
  const [siteVisitId, setSiteVisitId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [siteLabel, setSiteLabel] = useState("Choisir une opération...");
  const [visitDate, setVisiteDate] = useState(getCurrentDate('-'));
  const [visitTime, setVisiteTime] = useState(new Date().toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",hour12: false}));
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState("");

  const [remarks, setRemarks] = useState([]);
  const [idRemark, setIdRemark] = useState(0);
  const [photosRemark, setPhotosRemark] = useState([]);
  const [remark, setRemark] = useState('');
  const [orginalRemark, setOriginalRemark] = useState('');

  const [stateModalGallery, setStateGallery] = useState(false);

  const [rapport, setRapport] = useState("");
  const [rapportUpdated, setUpdateRapport] = useState(false);
  const editorRef = useRef(null);
  const [visitContacts, setContacts] = useState([]);

  const [memoSticker, setMemoSticker] = useState('');
  const [displayMemoSticker, setDisplayMemoSticker] = useState(false);
  const [memocats, setmemoCats] = useState([]);
  const [memocat, setmemoCat] = useState(0);
  const [memos, setMemos] = useState([]);
  
  const editorRemarkRef = useRef(null);
  let [loading, setLoading] = useState(false);

  const [updateNotSaved, setUpdateNotSaved] = useState(false);

  const [synchroState, setSynchroState] = useState(0);

  
  if(firstInit==false)
  {
    
    if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }
    else if(props.idOperation > 0)
    {
      prepareNewVisit(props.idOperation);

    }
    SetFirstInit(true);
    SPSGetMemos(setMemo);
    SPSMemos(setMemos);
    SPSCatMemos(setmemoCats);
    SPSSynchroVisits(SynchroProgress);
  }
  
  useEffect(() => {
    SPSSiteLabel(siteId, setSiteLabel);
    setStateSearchSite(false);
  },[siteId]);

  useEffect(() => {
    if(memocats.length > 0)
      setmemoCat(memocats[0].id);
  }, [memocats])
  useEffect(() => {
    
    if(stateWindowVisit == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindowVisit]);

  useEffect(() => {
    if(synchroState == 0)
    {
      searchDatas();
    }
  }, [synchroState]);

  useEffect(() => {
    if(theVisit.id != undefined)
    {
      setLoading(true);
      setSiteVisitId(theVisit.id);
      setSiteId(theVisit.IdSite);
      setVisiteDate(theVisit.VisitDate.substring(0, 10));
      setVisiteTime(theVisit.VisitDate.substring(11, 16));
      if(theVisit.Photos != undefined)
        setPhotos(theVisit.Photos);
      else
        setPhotos([]);
       if(theVisit.Remarks != undefined)
       {
        /*if(!Array.isArray(theVisit.Remarks.Photos))
        {
          theVisit.Remarks.Photos = JSON.parse(theVisit.Remarks.Photos);
        }
        else*/
        setRemarks(theVisit.Remarks);
       }
      else
        setRemarks([]);
      setDescription(theVisit.Description);
      setRapport(theVisit.Rapport);
      if(window.SpsServerAvailable && theVisit.Recipients != "" && theVisit.Recipients != undefined)
        setContacts(theVisit.Recipients);
      else  // en mode offline, nous n'avons pas la liste des destinataires donc on ne la charge pas
       setContacts([]);
      setStateWindowVisit(1);
      setLoading(false);
      var idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
    }
  }, [theVisit]);

  useEffect(() => {
    setUpdateNotSaved(true);
  }, [siteId, visitDate, visitTime, photos, remarks, description]);
  
  useEffect(() => {
    if(!window.SpsServerAvailable)
    {
      if(ongletEdit == 2)
      {
        alert("Vous devez être connecté pour utiliser cette fonction!");
        setOngletEdit(1);
      }
    }
    else
    {
      if(ongletEdit == 2)
      {
        // si nous passons sur l'onglet de rapport, il faut enregistrer la fiche et charger la generation de rapport
        saveVisit(loadCurrentRecord)
      }
    }
  }, [ongletEdit]);
  
  useEffect(() => {
    searchDatas();
  }, [currentFilter]);
  useEffect(() => {
    searchDatas();
  }, [currentSearchFilter]);

  function searchDatas()
  {
    SPSMyVisites(SetSitesVisits, currentFilter, currentSearchFilter);
  }

  function setMemo (data) {
    
    if(data.length)
    {
      // on ne récupère que celle qui nous interesse
      const memo = data.filter((m) => m.KeySetting == 'MEMO_VISIT');
      if(memo)
      {
        setMemoSticker(memo[0].Value);
      }
    }
  }

  const handleAddVisit = (event) => {
    prepareNewVisit(0);
  };

  const handleDisplayMemo = () => {
    setDisplayMemoSticker(true);
  }

  const handleCloseMemo = () => {
    setDisplayMemoSticker(false);
  }
  function SynchroProgress(state)
  {
    setSynchroState(state);
  }
  function prepareNewVisit(idSite)
  {
    setSiteVisitId(0);
    setSiteId(idSite);
    const dateJ = new Date();
    setVisiteDate(getCurrentDate('-'));
    setVisiteTime(getCurrentTime(':'));
    setPhotos([]);
    setDescription("");
    setRemarks([]);
    setStateWindowVisit(1);
    var idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
  }

  const handleCancelAddVisit= (event) => {
    if(props.idVisit > 0)
      props.screenHandling(0);
    else
    {
      if(updateNotSaved)
      {
        confirmAlert({
          title: 'Annulation',
          message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                setStateWindowVisit(0);
              }
            },
            {
              label: 'Non',
              onClick: () => { 

              }
            }
          ]
        });
      }
      else 
        setStateWindowVisit(0);
    }
   
  };
  const handleClickVisit = (id) => {
    setLoading(true);
    SPSGetVisite(id, SetTheVisit);
  };
  function loadCurrentRecord()
  {
    setLoading(true);
    SPSGetVisite(insertedId, SetTheVisit);
  }
  const handleDeleteVisite = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cette visite ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteVisit(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    //SPSMyVisites(SetSitesVisits);
    searchDatas();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveVisit(setStateWindowVisit);
    /*setLoading(true);
    const body = {
      id:siteVisitId,
      IdSite: siteId,
      Description: description,
      VisitDate: visitDate+' '+visitTime,
      Photos: photos
    };
    SPSSaveVisit(body, setStateWindowVisit);*/
    //setStateWindowVisit(0);
  };
  
  const saveVisit = (handleEnd) => {
    setLoading(true);
    const body = {
      id:siteVisitId,
      IdSite: siteId,
      Description: description,
      VisitDate: visitDate+' '+visitTime,
      Photos: photos,
      Remarks: remarks
    };
    if(rapportUpdated)
    {
      body.Rapport = rapport;
    }
    SPSSaveVisit(body, handleInsertedId, handleEnd);
  };

  const handleSendVisit = (event) => {
    setShowEnvoiRapport(true);
  };
  
  const handleInsertedId = (id) => {
    setSiteVisitId(id);
    insertedId = id;
  }

  const handleBack = (event) => {
    props.screenHandling(0);
  };

  const handleShowModalSearchSite = () => {
    if(stateModalSearchSite==true)
      setStateSearchSite(false);
    setStateSearchSite(true);
  }
  const handleCloseSearchSite = () => {
    setStateSearchSite(false);
  }
  const handleShowModalPhoto = () => {
    SetEditPhoto({id: 0, Photo: "", Desription: ""});
    if(stateModalPhoto==true)
      setStatePhoto(false); 
    setStatePhoto(true); 
  }
  const handleCamera = () => {
    if(showCamera==true)
      setShowCamera(false);
    setShowCamera(true);
  };

  const handleGalerie = (e) => {
    readImage(e.target.files[0]);
  };

  function handleTakePhoto (dataUri) {
      
      // copie du state
      const photoCopy = [...photos];

      const p = {id:(new Date().getTime()) * -1, Photo: dataUri}
      
        photoCopy.push(p);
      
      // modifie le state original
      setPhotos(photoCopy);
  }

    const handleAddPhoto = (newPhoto) => {
      // copie du state
      const photoCopy = [...photos];

      const p = {id:(new Date().getTime()) * -1, Photo: newPhoto}
      // cette photo etait elle déjà présente dans la liste
      /*const p = photoCopy.filter((photo) => photo.id == newPhoto.id);
      if(p.length > 0)
      {
        p[0].Photo = newPhoto.Photo;
        p[0].Description = newPhoto.Description;
      }
      else*/
        photoCopy.push(p);
      
      // modifie le state original
      setPhotos(photoCopy);
    };
    const handleDeletePhoto = (id) => {
      confirmAlert({
        title: 'Suppression',
        message: 'Souhaitez-vous supprimer cette photo ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              // copie du state
              const photoCopy = [...photos];
              // suppression de la photo
              const photoCopyUpdated = photoCopy.filter((photo) => photo.id !== id);

              // modifie le state original
              setPhotos(photoCopyUpdated);
            }
          },
          {
            label: 'Non',
            onClick: () => { 

            }
          }
        ]
      });
      
    };

    const handleEditPhoto = (id) => {
      /*const p = photos.filter((photo) => photo.id == id);
      if(p.length > 0)
      {
        SetEditPhoto(structuredClone(p[0]));
        setStatePhoto(true);
      }*/
    };

    const handleChangeRapport = (value, editor) => {
      setUpdateRapport(true);
      setRapport(value);
    };

    const handleClosedRapport = () => {
      setShowEnvoiRapport(false);
    };

    const handleSendRapport= () => {
      saveVisit(sendRapport);
      
    };

    const sendRapport = (handleEnd) => {
      SPSSendRapport(siteVisitId, afterSendRapport);
    }

    const afterSendRapport = () => {
      setLoading(false);
      alert("Le rapport a été envoyé.");
      setShowEnvoiRapport(false);
    }

    const handleAddRemark = () => {
      setIdRemark(0);
      setPhotosRemark([]);
      setRemark("Localisation(s) :\n\nObservations(s) : \n\nEntreprises concernée(s) : \n\nRisques(s) : \n\nActions correctives(s) : ");
      setOriginalRemark('<p><strong>Localisation(s)</strong>&nbsp;:&nbsp;<br><br></p><p><strong>Observations(s)</strong>&nbsp;:&nbsp;<br><br></p><p><strong>Entreprises concernée(s)</strong>&nbsp;:&nbsp;<br><br></p><p><strong>Risques(s)</strong>&nbsp;:&nbsp;<br><br></p><p><strong>Actions correctives(s)</strong>&nbsp;:&nbsp;<br><br></p>');
      setStateModalRemark(true);
    }

    const handleEditRemark = (id) => {
      var rem = remarks.filter((remark) => remark.id == id);

      if(rem)
      {
        setIdRemark(rem[0].id);
        setPhotosRemark(rem[0].Photos);
        setRemark(rem[0].Description);
        setOriginalRemark(rem[0].Description);
        setStateModalRemark(true);
      }
    }
    const handleDeleteRemark = (id) => {
      confirmAlert({
        title: 'Suppression de remarque',
        message: 'Souhaitez-vous supprimer cette remarque ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              var rems = remarks.filter((remark) => remark.id !== id);
              setRemarks(rems);
            }
          },
          {
            label: 'Non',
            onClick: () => { 
  
            }
          }
        ]
      });
      
    }

    const handleClosedRemark = () => {
      setStateModalRemark(false);
    }

    const handleSaveRemark = () => {
      

      var rems = [...remarks];
      if(idRemark != 0)
      {
        var editedRem = rems.filter((r) => r.id == idRemark);
        if(editedRem)
        {
          editedRem[0].Description = remark;
          editedRem[0].Photos = [...photosRemark];
        }
      }
      else
      {
        var rem = {id:new Date().getTime() * -1, Description:remark, Photos: [...photosRemark]};
        rems.push(rem);
      }
        
      setRemarks(rems);
      setStateModalRemark(false);
    }

    const handleChangeRemark = (value, editor) => {
      setRemark(value);
    }

    const handleShowGallery = () => {
      setStateGallery(true);
    }

    const handleSelPhotos = (selection) => {
      var remarkPhotos = [];
      for(var i=0; i < selection.length; i++)
      {
        remarkPhotos.push(selection[i].id);
      }
      setPhotosRemark(remarkPhotos);
    }

    const handleDeletePhotoRemark = (id) => {
       // copie du state
       const photoCopy = [...photosRemark];
       // suppression de la photo
       const photoCopyUpdated = photoCopy.filter((photo) => photo !== id);

       // modifie le state original
       setPhotosRemark(photoCopyUpdated);
    }

    const getPhoto = (id) => {
      var retour = '';
      const p = photos.filter((photo) => photo.id == id);
      if(p.length > 0)
      {
        retour = p[0].Photo;
      }
      
      return retour;
    }

    function readImage(file) {
      // Check if the file is an image.
      if (file.type && file.type.indexOf('image') === -1) {
        console.log('File is not an image.', file.type, file);
        return;
      }
    
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
          //let image = "";
          const image = <img src={event.target.result} className="w-100"/>;
          // copie du state
          const photoCopy = [...photos];

          const p = {id:(new Date().getTime()) * -1, Photo: event.target.result}
          
          photoCopy.push(p);
          
          // modifie le state original
          setPhotos(photoCopy);

      });
      reader.readAsDataURL(file);
    }

    
    function createMarkup(val) {
      return {__html: val};
    }


    switch(stateWindowVisit)
    {
      case 1:

        var RemarkEditor = '';
        if(window.SpsServerAvailable)
        {
          RemarkEditor = <Editor
                            onInit={(evt, Remarkeditor) => editorRemarkRef.current = Remarkeditor}
                            initialValue={orginalRemark}
                            init={{
                              height: 450,
                              menubar: false,
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help'
                              ],
                              toolbar: 'formatselect | ' +
                              'bold italic underline backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                              'removeformat | help',
                            }}
                            tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                            onEditorChange={handleChangeRemark}
                          />;
        }
        else
        {
          RemarkEditor = <textarea name="Remarque" rows="5" className="form-control"  onChange={e => { setRemark(e.target.value); }} value={remark}></textarea>;
        }

        return (
          <div className="containerxl">
            <div className="row headScreen fixed-top px-0">
              <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancelAddVisit}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6"><h1 className="h4 py-3">Visite <OfflineIco /></h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" type="button" onClick={handleSubmit}><FontAwesomeIcon icon={faSave} /></button></div>
            </div>
            <SPSLoader load={loading} />
            {memoSticker != '' && displayMemoSticker == false ?
              <div className="btn-memo">
                <button className="btn btn-warning btn-lg" onClick={() => {handleDisplayMemo()}}><FontAwesomeIcon icon={faLightbulb} /></button>
              </div>
              :''
            }
            {displayMemoSticker ?
               <Toast id="toast_memo" show={displayMemoSticker} onClose={handleCloseMemo}>
               <Toast.Header>
                 <strong className="me-auto">Mémo</strong>
               </Toast.Header>
               <Toast.Body>
                  <select className="form-select" name="memocats" onChange={e => { setmemoCat(e.target.value)}}>
                  {memocats.map((r) =>(
                  <option key={r.id} value={r.id}>{r.Libelle}</option>
                  ))}
                  </select>
                  <div>
                    {memos.filter(m => m.Type==1 &&  m.IdCatMemo == memocat).map((r) =>(
                    <div className="border p-2 mt-2" key={r.id}  >
                      <div dangerouslySetInnerHTML={{__html: r.Descriptif}}></div>
                      {r.documents?
                        <MemoDocuments idmemo={r.id} documents={r.documents}></MemoDocuments>
                        
                      :''}
                      

                    </div>
                    ))}
                  </div>
                  
               </Toast.Body>
             </Toast>
              
            :""}
            <div id="onglet1" className={ongletEdit==1 ? 'onglet d-block' : 'onglet d-none'}>
              <form onSubmit={handleSubmit}>
                <div className="p-3">
                  <div className="text-center"><input type="hidden" name="id" value={siteVisitId} />{theVisit.SentDate && theVisit.SentDate != "0000-00-00 00:00:00" ? <span className="text-success font-sm">{datetime_to_fr(theVisit.SentDate).date}</span>:''}</div>
                  <div className="form-group mb-3">
                    <label className="me-3">Opération</label>
                    <input type="hidden" name="IdSite" value={siteId} />
                    <button type="button" className="btn btn-outline-secondary" onClick={handleShowModalSearchSite}>{siteLabel}</button>
                    
                  </div>
                  <div className="form-group mb-3">
                    <label id="label-chantier" >Date</label>
                    <div className="row">
                      <div className="col-6 col-md-2">
                        <input type="date" name="dateVisit" value={visitDate} className="form-control" onChange={e => { setVisiteDate(e.target.value); }}/>
                      </div>
                      <div className="col-6 col-md-2">
                        <input type="time" name="dateVisit" value={visitTime} className="form-control" onChange={e => { setVisiteTime(e.target.value); }}/>
                      </div>
                    </div>
                    
                  </div>
                  <div className="form-group pos-relative">
                    <label id="label-chantier" className="mb-2">Photos</label>
                    <ul className="row liste-photo">
                      <li className="col-3 col-md-1 mb-2 p-0 line-photo ">
                        <button id="btnCamera" type="button" className="btn btn-light color-primary btn-xl w-100" onClick={handleCamera}>
                            <FontAwesomeIcon icon={faCamera} />
                            <br />Prendre une photo
                        </button>
                      </li>
                      <li className="col-3 col-md-1 mb-2 p-0 line-photo ">
                        <button id="btnGalerie" type="button" className="btn btn-light color-primary btn-xl btn-file w-100" >
                            <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleGalerie} required/><br />Envoyer une photo</label>
                        </button>
                      </li>
                      {photos.map((photo) =>(
                      <li className="col-3 col-md-1 mb-2 line-photo text-start" key={photo.id}>
                          <img src={photo.Photo} onClick={() => handleEditPhoto(photo.id)} className="w-100"/>
                          <p>{photo.Description}</p>
                          <button type="button" className="btn-delete-line" onClick={() => handleDeletePhoto(photo.id)}><FontAwesomeIcon icon={faXmark} /></button>
                      </li>
                      ))}
                    </ul>
                  </div>
                  <fieldset className="border pos-relative mb-3">
                    <legend>Remarques</legend>
                    <table className="table table-striped">
                      <tbody>
                      
                        {remarks.map((rem) =>(
                          <tr key={rem.id} >
                            <td className="text-start" onClick={() => handleEditRemark(rem.id)}>
                                {parse(rem.Description)}
                            </td>
                            <td className="text-start" onClick={() => handleEditRemark(rem.id)}>
                            {rem.Photos.map((photo, index) =>(
                                <img key={index} src={getPhoto(photo)} className="previewRemark me-2"/>
                              ))}
                            </td>
                            <td className="align-middle text-end">
                              <button type="button" className='btn btn-danger' onClick={() => handleDeleteRemark(rem.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-grid gap-2">
                      <button type="button" className="btn btn-link btn-block" onClick={handleAddRemark}>Ajouter une remarque</button>
                    </div>
                    
                  </fieldset>

                  <div className="form-group">
                    <label id="label-chantier" >Remarque interne</label>
                    <textarea name="Description" rows="5" className="form-control"  onChange={e => { setDescription(e.target.value); }} value={description}></textarea>
                  </div>
                </div>
                
              </form>
                      
              <ModalSearchSite showModal={stateModalSearchSite} setSiteId={setSiteId} handleClosed={handleCloseSearchSite}/>
              <ModalPhoto showModal={stateModalPhoto} editPhoto={photoToEdit} handleAdd={handleAddPhoto} handleClosed={() => setStatePhoto(false)} />
              <ModalCamera showModal={showCamera} handleClosed={() => setShowCamera(false)} takePhoto={handleTakePhoto}></ModalCamera>
            
              <Modal show={stateModalRemark} onHide={handleClosedRemark} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Remarque</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>

                        {RemarkEditor}
                        <fieldset className="form-group pos-relative mt-3">
                          <legend>Photos</legend>
                          <button type="button" className="btn btn-light color-primary pos-top-right btn-outil" onClick={handleShowGallery}><FontAwesomeIcon icon={faPlus} /></button>
                          <div className="row">
                            {photosRemark.map((p) =>(
                            <div className="col-4 col-md-2 pos-relative" key={p} >
                              <img className="mw-100" src={getPhoto(p)} />
                              <button type="button" className="btn-delete-line" onClick={() => handleDeletePhotoRemark(p)}><FontAwesomeIcon icon={faXmark} /></button>
                            </div>
                            ))}
                            <div className="col-4 col-md-2">
                                <button type="button" className="btn btn-light color-primary btn-xl" onClick={handleShowGallery}><FontAwesomeIcon icon={faPlus} /><br />Ajouter une photo</button>
                            </div>
                          </div>
                        </fieldset>
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedRemark}>Annuler</button>
                      <button type="button" className="btn btn-primary" onClick={handleSaveRemark}>Enregistrer</button>
                  </Modal.Footer>
              </Modal>
              <ModalGallery showModal={stateModalGallery} editPhoto={photoToEdit} handleValidate={handleSelPhotos} handleClosed={() => setStateGallery(false)} gallery={photos} />
            </div>
            <div id="onglet2" className={ongletEdit==2 ? 'onglet d-block': 'onglet d-none'}>
              <div className="container-xxl mt-2">
              <Tabs defaultActiveKey="content" id="uncontrolled-tab-example" className="mb-3" >
                  <Tab eventKey="content" title="Contenu">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={theVisit.Rapport}
                        init={{
                          height: 500,
                          menubar: true,
                          plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                            'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'help', 'wordcount'
                          ],
                          toolbar: 'formatselect | ' +
                          'bold italic underline backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                          'removeformat | help',
                        }}
                        tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                        onEditorChange={handleChangeRapport}
                      />
                  </Tab>
                  <Tab eventKey="diffusion" title="Liste de diffusion">
                    <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Identité</th>
                            <th>Société</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          {visitContacts.map((contact) =>(
                          <tr key={contact.id} >
                              <td>{contact.Civility} {contact.FirstName} {contact.Name}</td>
                              <td>{contact.company_Name} </td>
                              <td>{contact.Email} </td>
                          </tr>
                          ))}
                        </tbody>
                        
                    </table>
                  </Tab>
                  
                </Tabs>
                <div className="my-3">
                  <button type="button" className="btn btn-primary" onClick={handleSendRapport}>Envoyer</button>
                </div>
              </div>
            </div>
            
            <div className="row footerToolbar px-0">
              <div className="col text-center px-0"><button className={ongletEdit==1 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(1)}}><FontAwesomeIcon icon={faPencil} /></button></div>
              <div className="col text-center px-0"><button className="btn btn-link text-secondary" type="button" onClick={handleCamera}><FontAwesomeIcon icon={faCamera} /></button></div>
              <div className="col text-center px-0"><button className={ongletEdit==2 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(2)}}><FontAwesomeIcon icon={faPaperPlane} /></button></div>
            </div>
          </div>
        );
        break;
      default:
        if(!props.idVisit)
        {
          var liste_vide = "";
          if(sitevisits.length == 0)
          {
            liste_vide = <div className="lineEmptyTab">Aucune visite de chantier</div>
          }
          return (
            <div className="containerxl" >
              <div className="row headScreen fixed-top px-0">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
                <div className="col-6"><h1 className="h4 py-3">Visites de chantier <OfflineIco /></h1></div>
                <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAddVisit}><FontAwesomeIcon icon={faPlus} /></button></div>
              </div>
              <SPSLoader load={loading} />
              <div className={synchroState > 0?"synchro_bar active":"synchro_bar"}>Synchronisation en cours <FontAwesomeIcon icon={faRotate} /></div>
              <div className="content-liste">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="px-0">
                        <ul className="container">
                          {sitevisits.map((sv) =>(
                          <li className={sv.local? "row mb-2 alternate-color bg-warning-subtle py-2":  "row mb-2 alternate-color py-2"} key={sv.id}  >
                              <div className="d-none">{sv.id}</div>
                              <div className="col-12 col-md-7" onClick={() => handleClickVisit(sv.id)}>{sv.local?<span className="text-warning me-3"><FontAwesomeIcon icon={faTriangleExclamation} /></span>:""}{sv.site_Title!= undefined?sv.site_Title+" "+sv.site_Zip+" "+sv.site_City: SPSSiteLabelLocal(sv.IdSite)}</div>
                              <div className="col-8 col-md-4 text-start font-mobile-sm" onClick={() => handleClickVisit(sv.id)}>
                                {datetime_to_fr(sv.VisitDate).date} {sv.SentDate=="0000-00-00 00:00:00" || sv.SentDate==undefined || sv.SentDate=='' ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(sv.SentDate).date}</span>}
                              </div>
                              <div className="col-4 col-md-1 text-end">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteVisite(sv.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </li>
                          ))}
                        </ul>
                        {liste_vide}
                        <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAddVisit}>Créer une visite</button></div>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row footerToolbar px-0">
                <div className="col-6 text-center px-2">
                  <select className="form-select text-center border-0 my-2" onChange={e => { SetFilter(e.target.value); }} value={currentFilter}>
                    <option value="MY">Mes visites en cours</option>
                    <option value="ALLMY">Toutes mes visites</option>
                    <option value="ALLCURRENT">Toutes les visites en cours</option>
                    <option value="ALL">Toutes les visites</option>
                  </select>
                </div>
                <div className="col-6 text-center px-2">
                  <div className="input-group my-2">
                    <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    <input type="text" className="form-control" value={currentSearchFilter} placeholder="Opération, Code postal..." aria-label="Username" aria-describedby="basic-addon1"  onChange={e => { SetSearchFilter(e.target.value); }}/>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else
          return('');
        break;
    }
  
}

export default SiteVisit;
