import { useEffect, Component } from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons'

import {SPSCompanyTypes, SPSGetCompanyType, SPSSaveCompanyType, SPSDeleteCompanyType} from '../../functions/SPSCompanyType'
import { SPSGetUsers, SPSGetUser, SPSSaveUser, SPSDeleteUser } from '../../functions/SPSUsers'

export class Users extends Component {  
  constructor() {  
    super();  
    this.state = {
      id: 0,
        Active: 0,
        Type: 0,
        FirstName: "",
        Name: "",
        Poste: "",
        Email: "",
        Phone: "",
        MailSignature: "",
        Password:"",
        users: []
    }
    this.SearchDatas();
    
  }  

  handleRetour = (event) => {
    this.props.parentStateModifier({"showSettingsUsers" : false} );
  }

  handleAddUser = (event) => {
    this.setState({
      id: 0,
        Active: 0,
        Type: 0,
        FirstName: "",
        Name: "",
        Poste: "",
        Email: "",
        Phone: "",
        MailSignature: "",
        Password:"",
        stateWindowEdit:1
   
  });
  }


  handleClickLine = (id) => {
    SPSGetUser(id, this.SetData);
    //setLoading(true);
    //SPSGetVisite(id, SetTheVisit);
  }
  handleDeleteUser = (id) => {
    SPSDeleteUser(id, this.SearchDatas)
  }

  SearchDatas = () => {
    SPSGetUsers(this.SetUsers);
  }
  SetUsers = (data) => {
    this.setState({users: data});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    var datas = {
        id: this.state.id, 
        Active: this.state.Active, 
        Type: this.state.Type, 
        FirstName: this.state.FirstName, 
        Name: this.state.Name, 
        Poste: this.state.Poste, 
        Email: this.state.Email, 
        Phone: this.state.Phone, 
        MailSignature: this.state.MailSignature, 
        Password: this.state.Password};
    
    SPSSaveUser(datas, this.afterSave);
  }

  afterSave = () => {
    this.setState({id: 0, Active: 0, Type: 0, FirstName: "",Name: "", Poste: "", MailSignature: "", Password: "", stateWindowEdit: 0});
    this.SearchDatas();
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.afterSave();
  }
  SetTypes = (data) => {
    this.setState({
      users: data
    })
  };

  SetData = (data) => {
    this.setState({
        id: data.id,
        Active: data.Active, 
        Type: data.Type, 
        FirstName: data.FirstName, 
        Name: data.Name, 
        Poste: data.Poste, 
        Email: data.Email, 
        Phone: data.Phone, 
        MailSignature: data.MailSignature, 
        
     
    });

    if(data.id > 0)
      this.state.stateWindowEdit = 1;
  }
  
  render() {  
    if(this.state.stateWindowEdit == 1)
    {
      
      
      return (
      <div className="full-screen containerxl ">
        <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
          <div className="col-6"><h1 className="h4 py-3">Utilisateur</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container d-block my-2">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group row mb-3">
              <div className="offset-md-3 col-md-9 ">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="Active" value="1" onChange={e => { this.setState({Active: e.target.checked?1:0});}} checked={this.state.Active>0?"checked":""}/>
                  <label className="form-check-label" htmlFor="Actif">Actif</label>
                </div>
              </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Type</label>
                <div className="col-md-9">
                    <select name="Type" className="form-select" value={this.state.Type} onChange={e => { this.setState({Type: e.target.value}); }}>
                        <option value="1">Administrateur</option>
                        <option value="2">Utilisateur</option>
                    </select>
                </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Prénom</label>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="FirstName" value={this.state.FirstName} onChange={e => { this.setState({FirstName: e.target.value}); }} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Nom</label>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="Name" value={this.state.Name} onChange={e => { this.setState({Name: e.target.value}); }} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Poste</label>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="Poste" value={this.state.Poste} onChange={e => { this.setState({Poste: e.target.value}); }} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Email</label>
                <div className="col-md-9">
                  <input type="email" className="form-control" name="Email" value={this.state.Email} onChange={e => { this.setState({Email: e.target.value}); }} />
                </div>
            </div>
            <div className="form-group row mb-3">
                <label className="col-md-3">Mot de passe</label>
                <div className="col-md-9">
                  <input type="password" className="form-control" name="Password" value={this.state.Password} onChange={e => { this.setState({Password: e.target.value}); }} />
                </div>
            </div>
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </form>
          
        </div>
      </div>

      );

    }
    else
    {
      return (  
          <div className="full-screen containerxl ">
            <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Utilisateurs</h1></div>
            <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={this.handleAddUser}><FontAwesomeIcon icon={faPlus} /></button></div>
          </div>
          <div className="d-grid gap-2 d-block">
            <div className="container py-3">
               <ul className="my-2 liste-settings ps-1">
                {this.state.users.map((r) =>(
                <li className="row mb-2 alternate-color py-2" key={r.id}  >
                    <div className="col-8 col-md-10" onClick={() => this.handleClickLine(r.id)}>{r.Name} {r.FirstName}</div>
                    <div className="col-4 col-md-2 text-end">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDeleteTC(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                    </div>
                </li>
                ))}
              </ul>
             
            </div>
          </div>
          
          
      </div>);
    }
  }  
}

export default Users;
