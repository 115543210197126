import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { SPSCompanies, SPSSaveCompany, SPSGetCompany, SPSDeleteCompany, SPSRequestCompanies } from '../functions/SPSCompany';
import {SPSCompanyTypes} from '../functions/SPSCompanyType';
import {SPSGetContact} from "../functions/SPSContact";
import SPSLoader from './SPSLoader';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";



function Company(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [companies, SetListe] = useState([]);
  const [theCompany, SetTheCompany] = useState([]);
  
  const [uniqueId, setUniqueId] = useState(0);
  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");
  const [ZipCode, setZip] = useState("");
  const [City, setCity] = useState("");
  const [IdTypeCompany, setTypeCompany] = useState(0);

  const [TypesCompany, setTypesCompany] = useState([]);
  
  const [contacts, setContacts] = useState([]);
  const [contact, setTheContact] = useState([]);

  const [showContact, setShowContact] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [contactCivility, setContactCivility] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactService, setContactService] = useState('');
  const [contactTitle, setContactTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [contactComment, setContactComment] = useState('');
  const [updateNotSaved, setUpdateNotSaved] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  
  var idtimer = 0;
  let [loading, setLoading] = useState(false);
  
  const { register,
          formState: { errors, isDirty, isSubmitting, touchedFields, submitCount } } = useForm();

  if(TypesCompany.length == 0)
  {
    SPSCompanyTypes(setTypesCompany);
  }
  useEffect(() => {
    console.log("event changement");
    if(stateWindowEdit == false)
    {
      SPSCompanies(SetListe);
    }
    //else
    //  setUpdateNotSaved(false);
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    if(theCompany.id != undefined)
    {
      setLoading(true);
      setUniqueId(theCompany.id);
      setName(theCompany.Name);
      setAddress(theCompany.Address);
      setZip(theCompany.ZipCode);
      setCity(theCompany.City);
      setTypeCompany(theCompany.IdTypeCompany);
      setContacts(theCompany.Contacts);
      
      setStateWindowEdit(true);
      setLoading(false);
      
      idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer); }, 1000);
    }
  }, [theCompany]);

  useEffect(() => {
    if(contact.id != undefined)
    {
      setContactId(contact.id);
      setContactFirstName(contact.FirstName);
      setContactName(contact.Name);
      setContactService(contact.Service);
      setContactTitle(contact.Title);
      setContactEmail(contact.Email);
      setContactPhone(contact.Phone);
      setContactMobile(contact.Mobile);
      setContactComment(contact.Comment);
      
      setShowContact(true);
    }
    
  }, [contact]);

  useEffect(() => {
    SPSRequestCompanies(filterSearch, SetListe);
  }, [filterSearch]);
  

  useEffect(() => {
    setUpdateNotSaved(true);
  }, [Name, Address, ZipCode, City,IdTypeCompany, contacts]);

  const handleDeleteCompany = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cette société ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCompany(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const afterDelete = () => {
    //SPSMyVisites(SetSitesVisits);
    SPSCompanies(SetListe);
  };

  const handleAdd = (event) => {
    setUniqueId(0);
    setName("");
    setAddress("");
    setZip("");
    setCity("");
    
    setStateWindowEdit(true);
  };
  const handleCancel= (event) => {
    if(updateNotSaved)
    {
      confirmAlert({
        title: 'Annulation',
        message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              setStateWindowEdit(false);
            }
          },
          {
            label: 'Non',
            onClick: () => { 

            }
          }
        ]
      });
    }
    else
      setStateWindowEdit(false);
    
    
  };
  const handleClickEdit = (id) => {
    setLoading(true);
    SPSGetCompany(id, SetTheCompany);
  };

  const handleClickContact = (id) => {
    const p = contacts.filter((contact) => contact.id == id);
    if(p.length > 0)
      setTheContact(p[0]);
    //SPSGetContact(id, setTheContact);
  };
  
  const handleDeleteContact = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer ce contact ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            console.log("suppression : "+id);
            var contactscopy = [...contacts];
            const p = contactscopy.filter((contact) => contact.id != id);
            setContacts(p);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const handleAddContact = () => {
    emptyContact();
    setShowContact(true);
  };

  function emptyContact()
  {
    setContactId(0);
    setContactCivility('');
    setContactFirstName('');
    setContactName('');
    setContactService('');
    setContactTitle('');
    setContactEmail('');
    setContactPhone('');
    setContactMobile('');
    setContactComment('');
    setTheContact({});
  }

  const handleClosedContact = () => {
    setShowContact(false);
    emptyContact();
  };

  const handleOkContact = () => {
    // enregistrement dans les contacts
    // recopie du state
    var contactscopy = [...contacts];
    const p = contactscopy.filter((contact) => contact.id == contactId);
    if(p.length > 0)
    {
      p[0].Civility = contactCivility;
      p[0].FirstName = contactFirstName;
      p[0].Name = contactName;
      p[0].Service = contactService;
      p[0].Title = contactTitle;
      p[0].Email = contactEmail;
      p[0].Phone = contactPhone;
      p[0].Mobile = contactMobile;
      p[0].Comment = contactComment;
    }
    else
    {
      var newContact = {};
      newContact.id = (new Date().getTime()) * -1;
      newContact.Civility = contactCivility;
      newContact.FirstName = contactFirstName;
      newContact.Name = contactName;
      newContact.Service = contactService;
      newContact.Title = contactTitle;
      newContact.Email = contactEmail;
      newContact.Phone = contactPhone;
      newContact.Mobile = contactMobile;
      newContact.Comment = contactComment;
      contactscopy.push(newContact);
    }
    setTheContact({});
    setContacts(contactscopy);
    setShowContact(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    setLoading(true);
    const body = makeobject();
    SPSSaveCompany(body, setStateWindowEdit);
  };

  function makeobject()
  {
    const body = {
      id:uniqueId,
      Name: Name,
      Address: Address,
      ZipCode: ZipCode,
      City: City,
      IdTypeCompany: IdTypeCompany,
      Contacts: contacts
      
    };
    return body;
  }
    
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };
  switch(stateWindowEdit)
  {
    case true:
      return (
        <div className="containerxl">
          <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
            <div className="col-6"><h1 className="h4 py-3">Société</h1></div>
            <div className="col-3"></div>
          </div>
          <SPSLoader load={loading} />
          <div className="container py-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
              <div className="col-md-6">
                  <input type="hidden" name="id" value={uniqueId} />
                  <div className="form-group mb-3 row">
                    <label className="col-md-2">Nom</label>
                    <div className="col-md-10">
                      <input type="text" className="form-control" name="Name" value={Name} onChange={e => { setName(e.target.value); }} />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-2">Adresse</label>
                    <div className="col-md-10">
                      <textarea className="form-control" name="Address" rows="2" value={Address} onChange={e => { setAddress(e.target.value); }}></textarea>
                    </div>
                  </div>
                  
                  <div className="form-group mb-3 row">
                    <label className="col-md-2">Code postal</label>
                    <div className="col-md-2 mb-3 mb-md-0">
                      <input type="text" className="form-control" name="ZipCode" value={ZipCode} onChange={e => { setZip(e.target.value); }} />
                    </div>
                    <label className="col-md-2">Ville</label>
                    <div className="col-md-6 ">
                      <input type="text" className="form-control" name="City" value={City} onChange={e => { setCity(e.target.value); }} />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-2">Type</label>
                    <div className="col-md-10">
                      <select name="IdTypeCompany" className='form-select' onChange={e => {setTypeCompany(e.target.value); }} value={IdTypeCompany} >
                        <option value="0">Choisir...</option>
                        {TypesCompany.map((r) => (
                        <option key={r.id} value={r.id} >{r.Label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header pos-relative">
                      Contacts
                      <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddContact}>+</button>
                    </div>
                    <div className="card-body">
                      <ul className="liste liste-incluse">
                        {contacts.length == 0 &&
                        <div className="text-center">Aucun contact</div>
                        }
                        {contacts.map((r) =>(
                        <li className="row mb-2 alternate-color" key={r.id}  >
                            <div className="col-12 col-md-5" onClick={() => handleClickContact(r.id)}>{r.Civility} {r.FirstName} {r.Name}</div>
                            <div className="col-12 col-md-5 text-start" onClick={() => handleClickContact(r.id)}>{r.Email}<br />{r.Mobile}</div>
                            <div className="col-4 col-md-2 text-end">
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteContact(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                            </div>
                        </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="text-end mt-3 p-3 border-top">
                <button className="btn btn-primary" type="submit">Valider</button>
              </div>
            </form>
            <Modal show={showContact} onHide={handleClosedContact} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                <Modal.Title>Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <select name="Civility" className="form-select" value={contactCivility} onChange={e => { setContactCivility(e.target.value); }}>
                              <option value="">Civilité</option>
                              <option value="M.">Monsieur</option>
                              <option value="Mme">Madame</option>
                            </select>
                          </div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Prénom" value={contactFirstName}  onChange={e => { setContactFirstName(e.target.value); }}/></div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Nom" value={contactName}  onChange={e => { setContactName(e.target.value); }}/></div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Service" value={contactService}  onChange={e => { setContactService(e.target.value); }}/></div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Poste" value={contactTitle}  onChange={e => { setContactTitle(e.target.value); }}/></div>
                          
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Email" value={contactEmail}  onChange={e => { setContactEmail(e.target.value); }}/></div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Téléphone" value={contactPhone}  onChange={e => { setContactPhone(e.target.value); }}/></div>
                          <div className="form-group mb-3"><input type="text" className="form-control" placeholder="Mobile" value={contactMobile}  onChange={e => { setContactMobile(e.target.value); }}/></div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Commentaires</label>
                            <textarea className="form-control" value={contactComment} onChange={e => { setContactComment(e.target.value); }} rows="5"></textarea>
                          </div>
                        </div>
                      </div>
                    
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClosedContact}>Annuler</button>
                    <button type="button" className="btn btn-primary" onClick={handleOkContact}>Valider</button>
                </Modal.Footer>
            </Modal>
          </div>
        </div>
      );
      break;
    default:
      var liste_vide = "";
      if(companies.length == 0)
      {
        liste_vide = <div className="lineEmptyTab">Aucune société</div>
      }
      return (
        <div className="containerxl" >
          <div className="row headScreen">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Sociétés</h1></div>
            <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button></div>
          </div>
          <SPSLoader load={loading} />
          <div className="content-screen">
          <table className="table">
            <tbody>
              <tr>
                <td className="px-0">
                  <ul className="container">
                    {companies.map((r) =>(
                    <li className="row mb-2 alternate-color" key={r.id}  >
                        <div className="col-7 col-md-7" onClick={() => handleClickEdit(r.id)}>{r.Name}</div>
                        <div className="col-4 col-md-4 text-start" onClick={() => handleClickEdit(r.id)}>{r.ZipCode} {r.City}</div>
                        <div className="col-1 col-md-1 text-end">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteCompany(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                          </div>
                    </li>
                    ))}
                  </ul>
                  {liste_vide}
                  <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer une société</button></div>
                  
                </td>
              </tr>
            </tbody>
          </table>
          </div>
            <div className="footerToolbar px-0">
                <div className="container">
                  <div className="text-center px-2 my-2">
                    <input type="text" className="form-control" placeholder='Recherche...' onChange={e => { setFilterSearch(e.target.value); } } value={filterSearch} />
                    
                  </div>
                </div>
                
            </div>
          
          
        </div>
      );
      break;
  }
  
}

export default Company;
