import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XRequest = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "fonction", 
  headers : { Authorization : 'Bearer ' + token }
});


export async function SPSFonctions(SetValues)
{
  const result = await XRequest.get('');
  SetValues(result.data);
  
}

export async function SPSGetFonction(id, SetValues)
{
  const result = await XRequest.get(id);
  SetValues(result.data[0]);
  
}


