import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { Editor } from '@tinymce/tinymce-react';
import { initConfig } from '../functions/initConfig';

function EditCourier({show, courier,onValidate, onCancel}) {
    

    const editorRef = useRef(null);
    const [editCourier, setEditCourier] = useState("");
   
    const handleChangeCourier = (value, editor) => {
        setEditCourier(value);
      };
    
    const handleCancelEdit = () => {
      onCancel();
    };

    const handleSubmit = () => {
      onValidate(editCourier, false);
    };

    const handleEnvoyer = () => {
      onValidate(editCourier, true);
    };

    useEffect(() => {
      setEditCourier(courier);
      
    }, [courier]);    

    return(
    <div className={show ==true?"fullscreen z-top":"d-none"} >
        <div className="containerxl">
          <div className="row headScreen fixed-top px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancelEdit}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
            <div className="col-6"><h1 className="h4 py-3">Rédaction document</h1></div>
            <div className="col-3 py-2 text-end"><button className="btn btn-link" type="button" onClick={handleSubmit}>Enregistrer</button></div>
          </div>
          <div className="container mt-5">
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={courier}
                init={initConfig}
                tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                onEditorChange={handleChangeCourier}
            />
            <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleEnvoyer}>Envoyer</button></div>
          </div>
            
        </div>
    </div>);

}

export default EditCourier;