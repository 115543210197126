import { createRef, Component } from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPlus, faTrashCan, faFilePdf, faImage, faXmark} from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert'; // Import


import {SPSGetSetting, SPSSaveSetting} from '../../functions/SPSSettings'
import {SPSSaveCatMemo, SPSGetCatMemo, SPSDeleteCatMemo, SPSCatMemos} from '../../functions/SPSMemos';



export class SettingsCatMemo extends Component {  
  constructor() {  
    super();  
    this.state = {
      IdCatMemo: -1,
      Libelle: '',
      cats: []
    }

    
    this.chargeCatsMemos();
    
  }  
  
  handleRetour = (event) => {
    this.props.parentStateModifier({"showSettingsCatMemo" : false} );
  }


  handleClickCatMemo = (id) => {
    SPSGetCatMemo(id, this.SetTheCatMemo);
  }



  handleDeleteCatMemo = (id) => {
    SPSDeleteCatMemo(id, this.afterDeleteCatMemo);
  }
  

  handleSubmit = (e) => {
    e.preventDefault();
    var datas = {id: this.state.IdCatMemo, Libelle: ''};
    datas.IdCatMemo = this.state.IdCatMemo;
    datas.Libelle = this.state.Libelle;
    
    SPSSaveCatMemo(datas, this.afterSaveCatMemo);
  }

  afterSaveCatMemo = () => {
    this.setState({IdCatMemo: -1, Libelle: ''});
    this.chargeCatsMemos();
  }

  afterDeleteCatMemo = (message) => {
    if(message)
    {
        alert(message);
    }
    this.chargeCatMemos();
  }

  

  handleCancel = (e) => {
    e.preventDefault();
    this.afterSaveCatMemo();
  }

  handleAddCatMemo = (e) => {
    this.setState({IdCatMemo: 0, Libelle: ''});
  }

  

  SetTheCatMemo = (data) => {
    console.log(JSON.stringify(data));
    this.setState({
      IdCatMemo: data.id, 
      Libelle: data.Libelle,
    });
    
    
  }

  chargeCatsMemos = () => {
    SPSCatMemos(this.setCats);
  }

  setCats = (datas) => {
    this.setState({cats: datas});
  }

  
  render() {  
    if(this.state.IdCatMemo >= 0)
    {
      
      return (
      <div className="full-screen containerxl ">
        <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
          <div className="col-6"><h1 className="h4 py-3">Catégorie de Mémo</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container d-block my-2">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group row mb-3">
              <label className="col-md-4">Libellé</label>
              <div className="col-md-8">
                <input name="libelle" className="form-control" value={this.state.Libelle} onChange={e => { this.setState({Libelle: e.target.value})}} />
              </div>
            </div>
            
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </form>
          
        </div>
      </div>

      );

    }
    else
    {
      return (  
          <div className="full-screen containerxl ">
            <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Catégories de Mémos</h1></div>
            <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={this.handleAddCatMemo}><FontAwesomeIcon icon={faPlus} /></button></div>
          </div>
          <div className="container py-3">
            <h2 className="text-center">Catégories de Mémos</h2>
            <ul className="my-2 liste-settings ps-1">
            {this.state.cats.map((r) =>(
              <li className="row mb-2 alternate-color py-2" key={r.id}  >
                  <div className="col-8" onClick={() => this.handleClickCatMemo(r.id)}>{r.Libelle}</div>
                  <div className="col-2 text-end">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDeleteCatMemo(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                  </div>
              </li>
              ))}
              
            </ul>
            
          </div>
          
          
          
      </div>);
    }
  }  
}

export default SettingsCatMemo;
