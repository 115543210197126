import axios from "axios";
import {SPSActionBadToken} from "./SPSUsers.js"
const token = SPSAuthToken();
const Xvisite = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "settings", 
  headers : { Authorization : 'Bearer ' + token }
});

export function SPSClearToken()
{
    localStorage.removeItem('userAuth');
}

export function SPSAuthToken(check=false)
{
    const authInfos = localStorage.getItem('userAuth');
    var tmpToken = '';
    if(authInfos)
    {
        const authObject = JSON.parse(authInfos);
        if(authObject.token)
        {
            tmpToken = authObject.token;
            // si nous sommes en ligne on vérifie le token
            if(window.SpsServerAvailable && check)
            {
                SPSActionBadToken(SPSClearToken);
            }
        }
        
    }
    return tmpToken;
}



export async function SPSGetSetting(ref, SetTheSetting)
{
    if(window.SpsServerAvailable)
    {
        try {
            const result = await Xvisite.get('/'+ref);
            SetTheSetting(result.data);
        } catch(err) {

        }
    }
  
}

export async function SPSSaveSetting(ref, datas, callbackSave)
{
    if(window.SpsServerAvailable)
    {
        try {
            const body = {
                Value: datas.Value,
                Extra1: datas.Extra1,
                Extra2: datas.Extra2
                };
            const result = await Xvisite.put('/'+ref, body);
            callbackSave();
        } catch(err) {

        }
    } 
}

export async function SPSLoadMemos()
{
    if(window.SpsServerAvailable)
    {
        try {
            const result = await Xvisite.get('/memos');
            // et on enregistre dans le local storage
            SPSSetLocalMemos(result.data);
        }
        catch(err) {

        }
    }
}

export async function SPSGetMemos(setMemos)
{
    if(window.SpsServerAvailable)
    {
        try {
            const result = await Xvisite.get('/memos');
            setMemos(result.data);
            // et on enregistre dans le local storage
            SPSSetLocalMemos(result.data);
        } catch(err) {
            setMemos(SPSLocalMemos());
        }
    }
    else
    {
        setMemos(SPSLocalMemos());
    }
}

/*************************************************************
 * SPSLocalMemos
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des mémos contenus en local (localstorage)
 *************************************************************/
function SPSLocalMemos()
{
  var memos = [];
  const ls = localStorage.getItem('memos');
  if(ls)
  {
    try {
      memos = JSON.parse(ls);
    } catch (e) {
      memos = [];
    }
    
  }
  if(!memos.length || memos[0].id == undefined) 
    memos = [];

  return memos;
}

function SPSSetLocalMemos(data)
{
    localStorage.setItem('memos',  JSON.stringify(data));
}


export async function SPSSendStatusRapport()
{
    var body = {};
    body.companies = localStorage.getItem('companies');
    body.sites = localStorage.getItem('sites');
    body.memos = localStorage.getItem('memos');
    body.visites = localStorage.getItem('visites');
    body.ICs = localStorage.getItem('ICs');
    body.userAuth = localStorage.getItem('userAuth');
    const result = await Xvisite.post('/rapport', body);
}