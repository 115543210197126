import React from "react";
import { useState, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";


function SPSLoader({load}) {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#314C98");
    
    useEffect(() => {
        setLoading(load);
      },[load]);

    return(
        <div className="myloader">
            {loading?
            <MoonLoader
                color={color}
                loading={loading}
                size={75}
                aria-label="CircleLoader"
                data-testid="CircleLoader"
            />
            :''}
        </div>
    );
}

export default SPSLoader;