import React, { Component } from 'react' 
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { SPSSaveSetting, SPSGetSetting } from '../../functions/SPSSettings';

export class SettingsGeneral extends React.Component {  

  constructor(props) {  
    super(props);  
    this.props = props;
    this.state = { email_copie: "", loaded: false };

   this.handleSettingsPhoto = (event) => {
        event.preventDefault();
        SPSSaveSetting('EMAIL_COPIE_ENVOIS', {Value: this.state.email_copie, Extra1: '', Extra2: ''},  this.afterSave);
    }

    this.afterSave = () => {
      this.props.parentStateModifier({"showSettingsGeneral" : false} );
    }

    this.handleRetour = (event) => {
        this.props.parentStateModifier({"showSettingsGeneral" : false} );
    }
    this.loadSetting = (data) => {
      this.setState({loaded: true});
      this.setState({email_copie: data.Value});

    }

    if(this.state.loaded===false)
    {
      SPSGetSetting('EMAIL_COPIE_ENVOIS', this.loadSetting);
    }
    axios.get(process.env.REACT_APP_USER_API_BASE_URL + "settings/EMAIL_COPIE_ENVOIS")
      .then((res) => {
        //setData(res.data)
      })

    
  }  
  
   

  render() {  
    return (  
        <div className="full-screen text-start">
            <div className="container">
            <div className="row headScreen mb-4">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
                <div className="col-6 text-center"><h1 className="h4 py-3">Général</h1></div>
                <div className="col-3 text-end py-2"></div>
            </div>
            <form action="submit"  onSubmit={this.handleSettingsPhoto}>
                <div className="form-group row">
                    <label className="col-md-3 text-left">Email en copie</label>
                    <div className="col-md-9"><input type="email" className="form-control" onChange={e => { this.setState({email_copie: e.target.value}); }} value={this.state.email_copie} /></div>
                </div>
                <button className="btn btn-primary" type="submit" >Enregistrer</button>
            </form>
            </div>
        </div>
    );  
  }  
}

export default SettingsGeneral;
