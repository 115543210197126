import { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark} from '@fortawesome/free-solid-svg-icons'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function ModalCamera({showModal, handleClosed, takePhoto}) {
    const [show, setShow] = useState(showModal);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(showModal);
        
    },[showModal]);
    
    function handleTakePhoto (dataUri) {
        takePhoto(dataUri);
        handleClosed();
    }

    return(
        <Modal show={show} fullscreen={true} onHide={handleClosed} className="modalCamera">
            <Modal.Header closeButton>
                <Modal.Title>Prise de photo</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } idealFacingMode = {FACING_MODES.ENVIRONMENT} idealResolution  = {{width: 800}} imageType = {IMAGE_TYPES.JPG} imageCompression={0.5}/>
            </Modal.Body>
        </Modal>);
}



export default ModalCamera;