import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SPSCompanies } from '../functions/SPSCompany';
import { SPSSearchContact } from '../functions/SPSContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare} from '@fortawesome/free-solid-svg-icons'


function ModalSearchContacts({showContact, handleClickContact, handleClosed}) {
    const [company, setSearchCompany] = useState('');
    const [keywords, setKeywords] = useState('');
    const [companies, setCompanies] = useState([]);
    const [searchContacts, setSearchContacts] = useState([]);
    const [searchContactsCopy, setSearchContactsCopy] = useState([]);

    const [firstInit, SetFirstInit] = useState(false);

    useEffect(() => {
    
        if(showContact)
        {
          // on ne charge les contenus que si l'interface est ouverte
          SPSSearchContact(company, keywords, setSearchContacts);
        }
        
      }, [company, keywords]);

    if(firstInit == false)
    {
        SPSCompanies(setCompanies);
        SetFirstInit(true);
    }
    const handleSearchContact = (event) => {
        event.preventDefault();
    };


      return(
                <Modal show={showContact} onHide={handleClosed} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Contacts</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <nav className="navbar navbar-expand-lg bg-light">
                      <div className="container-fluid">
                        <div className="navbar-collapse" id="navbarSupportedContent">
                          <form className="d-flex" role="search" onSubmit={handleSearchContact}>
                            <select className="form-select me-2" value={company} onChange={e => { setSearchCompany(e.target.value); }}>
                              <option value="">Société</option>
                              {companies.map((r) =>(
                                <option key={r.id} value={r.id}>{r.Name}</option>
                              ))}
                            </select>
                            <input className="form-control me-2" type="search" placeholder="Rechercher" value={keywords}  onChange={e => { setKeywords(e.target.value); }} aria-label="Search" />
                          </form>
                        </div>
                      </div>
                    </nav>
                    <ul className="liste liste-incluse mt-3">
                      {searchContacts.map((r) =>(
                        <li className="alternate-color px-3" key={r.id} >
                          <div className="row">
                            <div className="col-11">{r.FirstName} {r.Name} ({r.Title})<br/>{r.company_Name}</div>
                            <div className="col-1 pos-relative"><button title="Ajouter à la liste de diffusion" className="btn btn-outline-primary btn-sm pos-right" type="button" onClick={() => handleClickContact(r.id)}><FontAwesomeIcon icon={faShare} /></button></div>
                          </div>
                        </li>
                      ))}
                    </ul>
                      
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosed}>Fermer</button>
                  </Modal.Footer>
              </Modal>);
}


export default ModalSearchContacts;