import { SPSAuthToken } from './SPSSettings';
import { SPSBatch, SPSGetBatch, SPSStatusBatch, SPSDeleteBatch } from './SPSBatchs';
import {SPSLocalSites} from './SPSSites';
import axios from "axios";
const token = SPSAuthToken();
const Xvisite = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "sitevisit", 
  headers : { Authorization : 'Bearer ' + token }
});

var SetVisitCallBack = false;
var idVisitLocalInSaved = 0;
var dbTableNameSV = 'sitevisit';

// Initialisation de l'objet indexedDB qui stocke les arrets de tache en local sur le navigateur client
let openRequestSV = indexedDB.open(dbTableNameSV, 1);
let db = null;

openRequestSV.onupgradeneeded = function(event) {
  // déclenche si le client n'avait pas de base de données
  // ...effectuer l'initialisation...
  db = openRequestSV.result;
  switch(event.oldVersion) { // version db existante
    case 0:
      // la version 0 signifie que le client n'avait pas de base de données
      // effectuer l'initialisation
      if (!db.objectStoreNames.contains(dbTableNameSV)) {
        // s'il n'y a pas de magasin "books"
        db.createObjectStore(dbTableNameSV, {keyPath: 'id'}); // créez-le
      }
    case 1:
      // le client avait la version 1
      // mettre à jour
  }
};

openRequestSV.onerror = function() {
  console.error("Error", openRequestSV.error);
};

openRequestSV.onsuccess = function() {
  console.log("db SV chargée");
  db = openRequestSV.result;
};

function SPSAddLocalVisit(data)
{
  console.log("save local visit");
  let transaction = db.transaction(dbTableNameSV, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let sv = transaction.objectStore(dbTableNameSV); // (2)

  let request = sv.add(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("Stop Task added to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSUpdateLocalVisit(data)
{
  let transaction = db.transaction(dbTableNameSV, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let sv = transaction.objectStore(dbTableNameSV); // (2)
  let request = sv.put(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("Site Visit updated to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSDeleteLocalVisit(id)
{
  let transaction = db.transaction(dbTableNameSV, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let sv = transaction.objectStore(dbTableNameSV); // (2)

  let request = sv.delete(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("Site Visit deleted of the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSGetLocalVisit(id, SetDatas)
{
  let transaction = db.transaction(dbTableNameSV, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let sv = transaction.objectStore(dbTableNameSV); // (2)

  let request = sv.get(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("Site Visit loaded of the store", request.result);
    SetDatas(request.result);
  };

  request.onerror = function() {
    console.log("Error ouverture local site visit", request.error);
    SetDatas([]);
  };
}

/*************************************************************
 * SPSLocalST
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des arrets de tache contenues en local (localstorage)
 *************************************************************/
function SPSLocalVisit(SetDatas)
{
  let transaction = db.transaction(dbTableNameSV, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let sv = transaction.objectStore(dbTableNameSV); // (2)

  let request = sv.getAll(); // (3)

  request.onsuccess = function() { // (4)
    if (request.result !== undefined) {
      request.result.forEach((d) => {
        if(SPSStatusBatch("visites", d.id)==0)
          d.local=true;
        else
          d.local = false;
      });
      SetDatas(request.result);
    } else {
      console.log("aucun SV local");
    }
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };

}

export async function SPSSynchroVisits(callback)
{
  console.log("SYNCHRO SV");
  // on ne lance un synchro que si le navigateur est en ligne...
  if(window.SpsServerAvailable)
  {
    console.log("SYNCHRO SV : ONLINE");
    // ensuite on ne lance une synchro que s'il y a des batchs à mettre à jour
    const batchs = SPSGetBatch('visites');
    var idbatchs = [];
    for(var i=0; i < batchs.length; i++)
    {
      if(batchs[i].status==0)
      {
        idbatchs.push(batchs[i].id);
      }
    }
    console.log("SYNCHRO SV BATCHES : "+JSON.stringify(idbatchs));
    // nous avons plusieurs batchs à mettre à jour
    if(idbatchs.length > 0)
    {
      // il faut savoir si nous avons reellement une connexion internet
      try {
        const st = await Xvisite.get('/');
        if(st)
        {
          callback(1);
          // nous avons eu un retour donc on peut synchroniser
          let transaction = db.transaction(dbTableNameSV, "readonly"); // (1)

          let sv = transaction.objectStore(dbTableNameSV); // (2)

          let request = sv.getAll(); // (3)

          request.onsuccess = function() { // (4)
            if (request.result !== undefined) {
              const filtered = request.result.filter(i => idbatchs.includes(i['id']));
              filtered.forEach((d) => {
                SPSSaveVisit(d, null, null, true);
                SPSDeleteLocalVisit(d.id);
                SPSDeleteBatch('visites', d.id);
              });
              console.log("SYNCHRO SV : TERMINE");
              callback(0);
            } 
          };

          request.onerror = function() {
            callback(-1);
            console.log("SYNCHRO SV ERROR : RECHERCHE LOCAL");
            console.log("Error", request.error);
          };
        }


      } catch(err) {
        // erreur donc pas de connexion on arrete la
        console.log("SYNCHRO SV ERROR : NO INTERNET : "+err);
        callback(-1);
      }
    }
  }
}
/*************************************************************
 * SPSMyVisites
 * -----------------------------------------------------------
 * SetSitesVisits : Callback ou envoyer les enregistrements
 * -----------------------------------------------------------
 * Charge les visites depuis le serveur REST ou en Local 
 * si non connecté
 *************************************************************/
export async function SPSMyVisites(SetSitesVisits, type, search)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      var returnVisites = [];
      var qs = 'type='+type;
      if(search)qs+= '&search='+search;
      const datas = await Xvisite.get('/myvisits?'+qs);
      
      // attention, il ne faut pas écraser les visites locales qui ne sont pas enregistrées
      // il faut donc récupérer toutes les fiches qui sont en batch non enregistrées
      SPSLocalVisit((localSV) => {
        var visits = [];
        for(var i=0; i < localSV.length; i++)
        {
          if(SPSStatusBatch('visites', localSV[i].id) == 0)
          {
            localSV[i].local = true;
            visits.push(localSV[i]);
          }
          else
          {
            // on supprime cette version locale qui est peut etre oboslete
            SPSDeleteLocalVisit(localSV[i].id);
          }
        }  
        visits = visits.concat(datas.data);
        SetSitesVisits(visits);
      });    
      
    }
    catch(err) {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;

  if(returnlocal)
  {
    SPSLocalVisit(SetSitesVisits);
  }
}

export async function SPSGetSiteVisits(idSite, showDatas)
{
  try {
    const visites = await Xvisite.get('/site/'+idSite);
    showDatas(visites.data);
  } catch(err) {

  }
}

export async function SPSDownloadRapportVisit(IdVisit, ref)
{
  try {
    const pdf = await Xvisite.get('/rapport/?id='+IdVisit, {responseType: 'blob'});
    const url = URL.createObjectURL(new Blob([pdf.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", ref+".pdf");
        link.click();
  }
  catch(err) {
    alert("Veuillez vérifier votre connexion internet !")
  }
}

/*************************************************************
 * SPSSaveLocalVisite
 * -----------------------------------------------------------
 * visit : objet contenant la visite a enregistrer
 * SetTheVisit : Callback ou envoyer les données
 * -----------------------------------------------------------
 * Enregistre une visite enregistrée en local vers le serveur
 *************************************************************/
async function SPSSaveLocalVisite(visit, SetTheVisit)
{
  SetVisitCallBack = SetTheVisit;
  idVisitLocalInSaved = visit.id;
  SPSSaveVisit(visit, SPSAfterSavedLocalVisite, false, true);
}

/*************************************************************
 * SPSLocalVisites
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des visites contenues en local (localstorage)
 *************************************************************/
function SPSLocalVisites()
{
  var visites = [];
  const ls = localStorage.getItem('visites');
  if(ls)
  {
    
    try {
      visites = JSON.parse(ls);
    } catch (e) {
      visites = [];
    }
  }
  if(!visites.length || visites[0].id == undefined) 
    visites = [];
  else
  {
    // on ajoute le libellé de l'opération pour qu'il apparaisse dans la liste
    for(var i=0; i < visites.length; i++)
    {
      if(visites[i].IdSite > 0 && !visites[i].site_Title)
      {
        const sites = SPSLocalSites();
        const s = sites.filter((site) => site.id == visites[i].IdSite);
        if(s.length > 0)
          visites[i].site_Title = s[0].Title;
      }
    }
  }
  return visites;
}

/*************************************************************
 * SPSStoreLocalVisites
 * -----------------------------------------------------------
 * data : tableau des visites locales
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
function SPSStoreLocalVisites(data)
{
  // on supprime les éléments trop volumineux (images...)
  for(var i = 0;i < data.length;i++)
  {
    data[i].alter_pilote_Signature = '';
    data[i].pilote_Signature = '';
    data[i].user_Signature = '';
  }
  localStorage.setItem('visites', JSON.stringify(data));
}

/*************************************************************
 * SPSAfterSavedLocalVisite
 * -----------------------------------------------------------
 * id : id de la visite fraichement enregistrée
 * -----------------------------------------------------------
 * nettoie les batch et enlève l'enregistrement temporaire en local
 * Ensuite charge la visite pour l'afficher
 *************************************************************/
function SPSAfterSavedLocalVisite(id)
{
  // il faut nettoyer les batchs et les enregistrements locaux
  SPSDeleteBatch('visites', idVisitLocalInSaved);
  // suppression de la fiche de cet ID en local
  const lv= SPSLocalVisites();
  const newLv = lv.filter((visit) => visit.id != idVisitLocalInSaved);
  SPSStoreLocalVisites(newLv);
  SPSGetVisite(id, SetVisitCallBack);
}

/*************************************************************
 * SPSGetVisite
 * -----------------------------------------------------------
 * id : id de la visite à charger
 * SetTheVisit : Callback à appeler suite au chargement des données
 * -----------------------------------------------------------
 * Charge a visite depuis le serveur ou en local si le périphérique 
 * n'est pas connecté
 *************************************************************/
export async function SPSGetVisite(id, SetTheVisit)
{
  var storelocal = false;
  var modeoffline = false;
  if(!window.SpsServerAvailable)
    modeoffline = true;
  
  if(id > 0)
  {
    const status = SPSStatusBatch('visites', id);
    if(status == 0) // si c'est egale à 0, cela veut dire que la derniere version est en local 
    {
      storelocal = true;
    }
  }
  else  // l'Id est negatif donc c'est du local
    storelocal = true;

  
  if(window.SpsServerAvailable && storelocal == false)
  {
    // recherche du batch de cette visite
    const status = SPSStatusBatch('visites', id);
    // si le status est à 0 cela veut dire qu'il existe une version non enregistrée de la visite en local
    if(status == 0)
    {
      storelocal = true;
    }
    else
    {
      try {
        const visite = await Xvisite.get('/'+id);
        // recherche cette visite dans le JSON local pour l'avoir en local
        SPSGetLocalVisit(id, (datas) => {
        if(!datas)
          SPSAddLocalVisit(visite.data[0]); //temp.push(datas.data[0]);
        else
          SPSUpdateLocalVisit(visite.data[0]); //temp[index] = datas.data[0];
        
        SetTheVisit(visite.data[0]);
        });
      }
      catch(err) {
        console.log(err);
        storelocal = true;
        modeoffline = true;
      }
    }
  }
  else
    storelocal = true;
  
  if(storelocal)
  {
    SPSGetLocalVisit(id, (datas) => {
      if(datas && datas.id)
      {
        if(!modeoffline)
        {
          console.log("enregistrement sur le Web ")
          SPSSaveLocalVisite(datas, SetTheVisit);
        }
        else
          SetTheVisit(datas);
      }
      else
        SetTheVisit([]);
    });
    
  }
}

/*************************************************************
 * findVisit
 * -----------------------------------------------------------
 * id : id de la visite à chercher
 * -----------------------------------------------------------
 * Cherche l'index de la visite dans le localstorage
 *************************************************************/
function findVisit(id)
{
  const temp = SPSLocalVisites();
  let index = -1;
  for(var i = 0; i < temp.length && index < 0; i++)
  {
    if(temp[i].id == id)
      index = i;
  }

  return index;
}

/*************************************************************
 * SPSDeleteVisit
 * -----------------------------------------------------------
 * id : id de la visite à supprimer
 * afterDelete : Callback à appeler après la suppression
 * -----------------------------------------------------------
 * Supprime une visite sur le serveur
 *************************************************************/
export async function SPSDeleteVisit(id, afterDelete)
{
  if(id > 0)
  {
    if(window.SpsServerAvailable)
    {
      try {
        const result = await Xvisite.delete("/"+id);
      } catch(err) {
        alert("Vous devez être connecté pour utiliser cette fonction!");
      }
    }
    else
      alert("Vous devez être connecté pour utiliser cette fonction!");
  }
  else
  {
    SPSDeleteLocalVisit(id);
  }

  // il faut supprimer les batchs de cette visite
  SPSDeleteBatch("visites", id);

  afterDelete();
}

/*************************************************************
 * SPSSaveVisit
 * -----------------------------------------------------------
 * newvisit : objet de la visite à enregistrer (Create / Update)
 * setIntertedId : Callback à appeler avec l'Id nouvellement créé
 * setShowForm : Callback à appeler pour masquer une interface
 * -----------------------------------------------------------
 * Enregistre la visite envoyée en paramètre 
 * (en local ou sur le serveur suivant l'état de la connexion)
 *************************************************************/
export async function SPSSaveVisit(newvisit, setIntertedId, setShowForm, onlineuniquement)
{
  var status = 0;
  var storelocal=false;
  
  
  if(window.SpsServerAvailable)
  {
    try {
      // si l'utilisateur est connecté, on met à jour les données sur le serveur
      const body = {
        IdSite: newvisit.IdSite,
        Description: newvisit.Description,
        VisitDate: newvisit.VisitDate,
        Photos: newvisit.Photos,
        Remarks: newvisit.Remarks
      };
      if(newvisit.Rapport != undefined)
      {
        body.Rapport = newvisit.Rapport;
      }
      var result = [];
      var idorig = newvisit.id;
      if(newvisit.id > 0)
      {
        result = await Xvisite.put("/"+newvisit.id, body);
        
        setIntertedId(newvisit.id);
        //if(setShowForm)
        //  setShowForm(0);
      }
      else
      {
        result = await Xvisite.post("/", body);
        setIntertedId(result.data[0].id);
        //if(setShowForm)
        //  setShowForm(0);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
      {
        status = 1;
        // si nous sommes sur un enregistrement d'un local qui passe en "web", il change d'id et donc on supprime l'ancien batch
        if(idorig < 0)
          SPSDeleteBatch("visites", idorig);
      }
      SPSBatch("visites", newvisit.id, status);
      if(setShowForm)
        setShowForm(0);
    }
    catch(err) {
      storelocal = true;
    }
  }
  else
    storelocal = true;
  if(storelocal)
  {
    if(onlineuniquement)
    {
      // si on est en mode online uniquement, on se contente de charger le local à l'affichage
      if(SetVisitCallBack)
        SetVisitCallBack(newvisit);
    }
    else
    {
      if(newvisit.id == 0)
      {
        newvisit.id = (new Date().getTime()) * -1;
        newvisit.local = true;
        SPSAddLocalVisit(newvisit);
        if(setShowForm)
          setShowForm(0);
        SPSBatch("visites", newvisit.id, status);
      }
      else
      {
        // autrement on cherche s'il existe en local 
        SPSGetLocalVisit(newvisit.id, (datas) => {
          if(datas.id)
            SPSUpdateLocalVisit(newvisit);
          else
            SPSAddLocalVisit(newvisit);
            if(setShowForm)
              setShowForm(0);
          SPSBatch("visites", newvisit.id, status);
        });
      }
    }
    /*
    const visites = SPSLocalVisites();
    //const v = visites.filter((visite) => visite.id == newvisit.id);
    const indiceV = findVisit(newvisit.id);
    if(indiceV >= 0)
    {
      // si la fiche existe on se content de la mettre à jour
      visites[indiceV].IdSite = newvisit.IdSite;
      visites[indiceV].Description = newvisit.Description;
      visites[indiceV].VisitDate = newvisit.VisitDate;
      visites[indiceV].Photos = newvisit.Photos;
      visites[indiceV].Remarks = newvisit.Remarks;
      visites[indiceV].local = true;
      if(newvisit.Rapport != '')
        visites[indiceV].Rapport = newvisit.Rapport;
        SPSStoreLocalVisites(visites);
    }
    else  // autrement on la push
    {
      newvisit.id = (new Date().getTime()) * -1;
      newvisit.local = true;
      visites.push(newvisit);
      SPSStoreLocalVisites(visites);
    }

    if(setShowForm)
      setShowForm(0);*/
  }

  SPSBatch("visites", newvisit.id, status);
}

/*************************************************************
 * SPSSendRapport
 * -----------------------------------------------------------
 * IdVisit : id de la visite
 * -----------------------------------------------------------
 * Demande au serveur d'envoyer le rapport de la visite concernée
 *************************************************************/
export async function SPSSendRapport(IdVisit, afterSendRapport)
{
  if(window.SpsServerAvailable)
  {
    try {
      var result = [];
      result = await Xvisite.get("/sendrapport/?id="+IdVisit);
      afterSendRapport();
    } catch (err) {
      alert("Veuillez vérifier votre connexion internet");
    }
  }
  else
    alert("Vous devez être connecté pour utiliser cette fonction!");
}
