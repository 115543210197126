import { createRef, Component } from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faImage, faFile} from '@fortawesome/free-solid-svg-icons'

import {SPSGetSetting, SPSSaveSetting} from '../../functions/SPSSettings'

import { Editor } from '@tinymce/tinymce-react';

export class SettingsModels extends Component {  
  editorRef = createRef();
  editorFooterRef = createRef();
  typeFormlaire = "";
  constructor() {  
    super();  
    this.state = {
      key: '',
      title: '',
      value: '',
      length: '',
      valueFooter: '',
      lengthFooter: '',
      valueSubject: '',
    }

    this.typeFormlaire = '';
   

    this.handleRetour = (event) => {
      this.props.parentStateModifier({"showSettingsModels" : false} );
    }
    
  }  
  
  handleChange = (value, editor) => {
    this.setState({value: value});
    this.setState({length: editor.getContent({ format: 'text' }).length});
  }

  handleChangeFooter = (value, editor) => {
    this.setState({valueFooter: value});
    this.setState({lengthFooter: editor.getContent({ format: 'text' }).length});
  }

  
  handleChangeSubject = (event) => {
    this.setState({valueSubject: event.target.value});
  }

  handleClickLine = (ref) => {
    console.log("click pref : "+ref);
    SPSGetSetting(ref, this.SetTheSetting);
    //setLoading(true);
    //SPSGetVisite(id, SetTheVisit);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var datas = {Value: '', Extra1: '', Extra2: ''};
    switch(this.typeFormlaire)
    {
      case 'DOCUMENT':
        datas.Value = this.state.value;
        datas.Extra2 = this.state.valueFooter;
        break;
      case 'MAIL':
        datas.Value = this.state.value;
        datas.Extra1 = this.state.valueSubject;
        break;
      default:
        datas.Value = this.state.value;
    }
    
    SPSSaveSetting(this.state.key, datas, this.afterSaveModel)
  }

  afterSaveModel = () => {
    this.setState({key: '', value: '', valueFooter: '', valueSubject: ''});
  }

  SetTheSetting = (data) => {
    
    var vFooter = '';
    var vObjet = '';
    var vValue = '';
    //var vObj = {};
    console.log("Key setting : "+data.KeySetting);
    switch(data.KeySetting)
    {
      case 'DOC_RAPPORT_VISITE':
      case 'DOC_ARRET_TACHE':
      case 'DOC_IC':
      case 'DOC_INVITATION_CISSCT':
      case 'DOC_CR_CISSCT':
      case 'DOC_DIUO':
      case 'DOC_PGC':
      case 'DOC_COURRIER':
      case 'DOC_EXEC':
        this.typeFormlaire = 'DOCUMENT';
        vValue = data.Value;
        vFooter = data.Extra2;
        
        break;
      case 'MAIL_RAPPORT':
      case 'MAIL_RAPPORT_ARRET_TACHE':
      case 'MAIL_RAPPORT_PGC':
      case 'MAIL_RAPPORT_IC':
      case 'MAIL_RAPPORT_DIUO':
      case 'MAIL_INVITATION_CISSCT':
      case 'MAIL_CR_CISSCT':
      case 'MAIL_COURRIER':
      case 'MAIL_DOCEXEC':
        this.typeFormlaire = 'MAIL';
        vValue =data.Value;
        vObjet = data.Extra1;
        
        break;
      default:
        vValue = data.Value;
    }
    
    this.setState({
      key: data.KeySetting, 
      title: data.Title,
      value: vValue,
      valueFooter: vFooter,
      valueSubject: vObjet
    });

    
    
  }
  
  render() {  
    if(this.state.key != "")
    {
      var contentSubject = '';
      var contentForm  ='';
      var contentFooter = '';

      if(this.typeFormlaire == 'MAIL')
      {
        contentSubject = (<div className="form-group mb-3">
                        <label className="me-3">Objet</label>
                        <input type="text" className="form-control" name="subject" value={this.state.valueSubject} onChange={e => { this.setState({ valueSubject: e.target.value })}}/>
                        
                      </div>);
      }
      
      contentForm = (<div className="form-group mb-3">
                      <Editor
                            onInit={(evt, editor) => this.editorRef.current = editor}
                            value={this.state.value}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                              ],
                              toolbar: 'formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                              'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              
                            }}
                            tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                            onEditorChange={this.handleChange}
                      />
                    </div>);
      
      if(this.typeFormlaire == 'DOCUMENT')
      {
        contentFooter = (<div className="form-group mb-3">
                      <label>Pied de page</label>
                      <Editor
                            onInit={(evt, editor) => this.editorFooterRef.current = editor}
                            value={this.state.valueFooter}
                            init={{
                              height: 200,
                              menubar: true,
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                              ],
                              toolbar: 'formatselect | ' +
                              'bold italic underline backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                              'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              
                            }}
                            tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                            onEditorChange={this.handleChangeFooter}
                      />
                    </div>);
      }
      
      return (
      <div className="full-screen containerxl ">
        <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
          <div className="col-6"><h1 className="h4 py-3">Document : {this.state.title}</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container d-block my-2">
          <form onSubmit={this.handleSubmit}>
            {contentSubject}
            {contentForm}
            {contentFooter}
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </form>
          
        </div>
      </div>

      );

    }
    else
    {
      return (  
          <div className="full-screen containerxl ">
            <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Documents & courriers</h1></div>
            <div className="col-3 text-end py-2"></div>
          </div>
          <div className="">
            <div className="container py-3">
              <h4>Documents</h4>
              <ul className="my-2 liste-settings ps-1">
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_RAPPORT_VISITE")}>
                Visite de chantier <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_ARRET_TACHE")}>
                Arrêt de tâche <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_IC")}>
                Inspection commune <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_INVITATION_CISSCT")}>
                Invitation CISSCT<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_CR_CISSCT")}>
                Compte Rendu CISSCT<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_DIUO")}>
                DIUO<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_PGC")}>
                PGC<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_COURRIER")}>
                Courrier<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("DOC_EXEC")}>
                Documents d'execution<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
              </ul>
              <h4 className="mt-2">Emails</h4>
              <ul className="my-2 liste-settings ps-1">
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_RAPPORT")}>
                Email rapport visite<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_RAPPORT_ARRET_TACHE")}>
                Email rapport arrêt de tâche<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_RAPPORT_IC")}>
                Email rapport Inspection Commune<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_RAPPORT_PGC")}>
                Email rapport PGC<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_RAPPORT_DIUO")}>
                Email rapport DIUO<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_INVITATION_CISSCT")}>
                Email invitation CISSCT<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_CR_CISSCT")}>
                Email PV CISSCT<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_COURRIER")}>
                Email Courrier simple<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
                <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.handleClickLine("MAIL_DOCEXEC")}>
                Email Documents d'execution<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
                </li>
              </ul>
            </div>
          </div>
          
          
      </div>);
    }
  }  
}

export default SettingsModels;
