
import { Component } from 'react' 
import SettingsPhoto from './settings/SettingsPhoto';
import SettingsModels from './settings/SettingsModels';
import SettingsGeneral from './settings/SettingsGeneral';
import SettingsMemo from './settings/SettingsMemo';
import SettingsCatMemo from './settings/SettingsCatMemo';
import { CompanyTypes } from './settings/CompanyType';
import {SPSSendStatusRapport } from '../functions/SPSSettings';
import Users from './settings/Users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faImage, faFile,faGear, faIndustry, faUser, faStickyNote, faBug} from '@fortawesome/free-solid-svg-icons'

export class Settings extends Component {  

  constructor() {  
    super();  
    this.state = {  
        name: "ReactJS",  
        showSettingsPhoto: false,  
        showSettingsModels: false,
        showSettingsGeneral: false,
        showSettingsTypeCompany: false,
        showSettingsUsers: false,
        showSettingsMemo: false,
        showSettingsCatMemo: false
    };  
    this.hideComponent = this.hideComponent.bind(this);  
  }  
  
  hideComponent(name) {  
    switch (name) {  
        case "showSettingsGeneral":
          this.setState({ showSettingsGeneral: !this.state.showSettingsGeneral });  
          break;
        case "showSettingsPhoto":  
          this.setState({ showSettingsPhoto: !this.state.showSettingsPhoto });  
          break;  
        case "showSettingsModels":  
          this.setState({ showSettingsModels: !this.state.showSettingsModels });  
          break;  
        case "showSettingsTypeCompany":
          this.setState({ showSettingsTypeCompany: !this.state.showSettingsTypeCompany });  
          break;  
        case "showSettingsUsers":
            this.setState({ showSettingsUsers: !this.state.showSettingsUsers });  
            break;  
        case "showSettingsMemos":
          this.setState({ showSettingsMemo: !this.state.showSettingsMemo });  
          break;  
        case "showSettingsCatMemos":
          this.setState({ showSettingsCatMemo: !this.state.showSettingsCatMemo });  
          break;   
        //default:  
        //    null;  
    }  

    
}  
sendRapport = () => {
  SPSSendStatusRapport();
  alert("Rapport envoyé");
}

modifyState = (props) =>{
  this.setState(props)   
}

handleBack = (props) =>{
  this.props.screenHandling(0);   
}


  render() {  
    const { showSettingsPhoto, showSettingsModels, showSettingsGeneral, showSettingsTypeCompany, showSettingsUsers, showSettingsMemo, showSettingsCatMemo } = this.state;  
    return (  
      <div className="containerxl ">
         <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
          <div className="col-6"><h1 className="h4 py-3">Réglages</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container">
          <ul className=" my-2 liste-settings">
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsGeneral")}>
            <FontAwesomeIcon icon={faGear} /> Général <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsUsers")}>
            <FontAwesomeIcon icon={faUser} />  Utilisateurs<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsPhoto")}>
            <FontAwesomeIcon icon={faImage} /> Photos <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsModels")}>
            <FontAwesomeIcon icon={faFile} />  Modèles de documents & courriers <div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsTypeCompany")}>
            <FontAwesomeIcon icon={faIndustry} />  Types de société<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsMemos")}>
            <FontAwesomeIcon icon={faStickyNote} />  Mémos<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.hideComponent("showSettingsCatMemos")}>
            <FontAwesomeIcon icon={faStickyNote} />  Catégories de Mémos<div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div>
            </li>
            <li className="mb-2 alternate-color py-2 ps-2" onClick={() => this.sendRapport()}>
            <FontAwesomeIcon icon={faBug} />  Envoyer un état de l'application
            </li>
          </ul>
          
          
        </div>
        
        {showSettingsPhoto && <SettingsPhoto parentStateModifier={this.modifyState}/>}  
        {showSettingsModels && <SettingsModels parentStateModifier={this.modifyState}/>}  
        {showSettingsGeneral && <SettingsGeneral parentStateModifier={this.modifyState}/>}  
        {showSettingsTypeCompany && <CompanyTypes parentStateModifier={this.modifyState}/>}  
        {showSettingsMemo && <SettingsMemo parentStateModifier={this.modifyState}/>}
        {showSettingsCatMemo && <SettingsCatMemo parentStateModifier={this.modifyState}/>}
        {showSettingsUsers && <Users parentStateModifier={this.modifyState}/>}
      </div>
    );  
}  
}

export default Settings;
