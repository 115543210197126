import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XRequest = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "contact", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function SPSSearchContact(IdCompany, search, SetValues)
{
  try{
    if(window.SpsServerAvailable)  
    {
      const result = await XRequest.get('/search/?IdCompany='+IdCompany+'&search='+search);
        //console.log("Retour recherche : "+JSON.stringify(result));
        SetValues(result.data);
    }
    else
      SetValues([]);
  }
  catch(err) {
    SetValues([]);
  }
  
}


export async function SPSGetContact(id, SetValues)
{
  try {
    const result = await XRequest.get('/'+id);
    SetValues(result.data[0]);
  } catch (err) {
    SetValues([]);
  }
}

export async function SPSContactLabel(id, setLabel)
{
  try {
    const result = await XRequest.get('/'+id);
    setLabel(result.data[0].FirstName+" "+result.data[0].Name+" "+result.data[0].company_Name);
  } catch (err) {
    setLabel("");
  }
}

export async function SPSSaveContact(newVal, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Civility: newVal.Civility,
      FirstName: newVal.FirstName,
      Name: newVal.Name,
      Service: newVal.Service,
      Title: newVal.Title,
      Email: newVal.Email,
      Phone: newVal.Phone,
      Mobile: newVal.Mobile,
      Comment: newVal.Comment,
      IdCompany: newVal.IdCompany,
    };
    var result = [];
    try {
      if(newVal.id > 0)
      {
        result = await XRequest.put("/"+newVal.id, body);
        setShowForm(0);
      }
      else
      {
        alert("post");
        result = await XRequest.post("/", body);
        setShowForm(0);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status = 1;
    } catch (err) {
      setShowForm(0);
    } 
  }
  else
    setShowForm(0);
  
  
}


