import { useEffect, useState } from "react";
import Settings from "./Settings";
import Account from "./Account";
import SiteVisit from "./SiteVisit";
import StopTask from "./StopTask";
import Operations from "./Operations";
import Company from "./Companies";
import InspectionCommune from "./InspectionCommune";
import Login from "./Login";
import { SPSAuthToken } from "../functions/SPSSettings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {SPSLogout} from "../functions/SPSUsers";
import { SPSCheckNotification } from "../functions/SPSUsers";
import Dropdown from 'react-bootstrap/Dropdown';
import OfflineIco from "./Offline";


import logo_acb from '../images/acb.png';
import ico_visit from '../images/visit.png';
import ico_stoptask from '../images/stoptask.png';
import ico_docanalysis from '../images/docanalysis.png';
import ico_documents from '../images/documents.png';
import ico_inspection from '../images/inspection.png';
import ico_settings from '../images/settings.png';
import ico_company from '../images/company.png';
import ico_operations from '../images/operations.png';
import ico_account from '../images/account48.png';

import packageInfo from '../../package.json';


function Dashboard(props) {
  const [screenOpen, setScreen] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [manualOffline, setManualOffline] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  

  const handleMyAccount = (event) => {
    setScreen('account');
  }

  const handleLogout = (event) => {
    SPSLogout(afterLogout)
  }

  const handleSettings = (event) => {
    setScreen('settings');
  }

  const handleVisit = (event) => {
    setScreen('visit');
  }

  const handleStopTask = (event) => {
    setScreen('stoptask')
  }
  const handleInspection = (event) => {
    setScreen('inspection')
  }

  const handlePGC = (event) => {
    alert("Fonction en cours de développement")
  }

  const handleAnalyseDoc  = (event) => {
    alert("Fonction en cours de développement")
  }

  const handleOperations = (event) => {
    setScreen('operations');
  }

  const handleSocietes = (event) => {
    setScreen('societes');
  }

  const handleAfterLogin = () => {
    window.location.reload(true);
  };

  const handleOffline = (e) => {
    if(e.target.checked)
    {
      setManualOffline(true);
    }
    else
    {
      setManualOffline(false);
    }
  }

  const afterLogout = () => {
    window.location.reload(true);
  }

  useEffect(() => {
    SPSCheckNotification(dispatchNotifications);
  }, [screenOpen]);

  useEffect(() => {
    window.SpsServerAvailable = !manualOffline;
    setRefreshState(!refreshState);
  }, [manualOffline]);

  function dispatchNotifications(datas)
  {
    var notifs = {sv: 0, st: 0, ic: 0};
    if(datas)
    {
      if(datas.visits)
        notifs.sv = datas.visits;

      if(datas.stoptasks)
        notifs.st = datas.stoptasks;
      
      if(datas.ic)
        notifs.ic = datas.ic;
    }
    setNotifications(notifs);
  }

  if(SPSAuthToken(true) == "")
  {
    return (
      <div>
        <Login screenHandling={handleAfterLogin}/>
      </div>
      );
  }
  else
  {
    switch(screenOpen)
    {
      case 'settings':
        return (
          <div>
            <Settings screenHandling={setScreen} />
          </div>);
        break;
        case 'account':
          return (
            <div>
              <Account screenHandling={setScreen} />
            </div>);
          break;
      case 'visit':
        return (
          <div>
            <SiteVisit  screenHandling={setScreen} />
          </div>);
        break;
      case 'stoptask':
        return (
          <div>
            <StopTask  screenHandling={setScreen} />
          </div>);
        break;
      case 'inspection':
        return (
          <div>
            <InspectionCommune  screenHandling={setScreen} />
          </div>);
        break;
      case 'operations':
          return (
            <div>
              <Operations  screenHandling={setScreen} />
            </div>);
          break;
      case 'societes':
        return (
          <div>
            <Company  screenHandling={setScreen} />
          </div>);
          break;
      default:
        return (
          <div >
            
            <div className="container">
            <div className="row py-2 mb-3">
                <div className="col-3 text-start"><img src={logo_acb} className="mw-100" /></div>
                <div className="col-6 pt-3 text-center"><h1 className="h2">Tableau de bord <OfflineIco /></h1></div>
                <div className="col-3 text-end">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <img src={ico_account} className="mw-100" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleMyAccount}>Mon compte</Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>Deconnexion</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  
                </div>
              </div>
              <div id="maindashboard">
                <div className="row">
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleVisit}>
                      <div className="card-body row">
                        <div className="col-2"><img src={ico_visit} className="mw-100" /></div>
                        <div className="col-8"><h2>Visite de chantier</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                        {notifications.sv > 0?
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notifications.sv}
                        </span>
                        :""}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleStopTask}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_stoptask} className="mw-100" /></div>
                        <div className="col-8"><h2>Arrêt de tâche</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                        {notifications.st > 0?
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notifications.st}
                        </span>
                        :""}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleOperations}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_operations} className="mw-100" /></div>
                        <div className="col-8"><h2>Opérations</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleInspection}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_inspection} className="mw-100" /></div>
                        <div className="col-8"><h2>Inspection commune</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                        {notifications.ic > 0?
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notifications.ic}
                        </span>
                        :""}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleSocietes}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_company} className="mw-100" /></div>
                        <div className="col-8"><h2>Sociétés / Contacts</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleSettings}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_settings} className="mw-100" /></div>
                        <div className="col-8"><h2>Réglages</h2></div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-muted fs-6"><em>Version {packageInfo.version}</em></div>
                <div className="col-8 text-end">
                  <div class="form-check form-switch form-check-reverse">
                    <label class="form-check-label fs-6" for="flexSwitchCheckChecked">Forcer mode deconnecté</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={e => {handleOffline(e)}} checked={manualOffline?"checked":""}/>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        );
    }
  }
  
}
/*
<div className="col-12 col-md-6 mb-2">
                  <div className="card" onClick={handlePGC}>
                    <div className="card-body row">
                      <div className="col-2"><img rel="preload" src={ico_documents} className="mw-100" /></div>
                      <div className="col-8"><h2>PGC, DUIO...</h2></div>
                      <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                    </div>
                  </div>
                </div>
                */
export default Dashboard;
