import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faPaperPlane, faTrashCan, faCamera, faPencil, faTriangleExclamation, faMagnifyingGlass, faSave, faRotate} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef, useDebugValue } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { SPSSiteLabel, SPSSiteLabelLocal } from '../functions/SPSSites';
import { SPSGetStopTasks, SPSGetStopTask, SPSDeleteStopTask, SPSSaveStopTask, SPSSendRapportTask, SPSSynchroST} from '../functions/SPSStopTask';
import {SPSContactLabel} from '../functions/SPSContact';
import { datetime_to_fr, getCurrentDate,getCurrentTime  } from '../functions/tools';
import ModalSearchSite from './ModalSearchSite';
import ModalCamera from './ModalCamera';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SPSLoader from './SPSLoader';
import RecipientsList from './RecipientsList';
import { Editor } from '@tinymce/tinymce-react';
import SignatureCanvas from 'react-signature-canvas'

import ModalSearchContacts from './ModalContacts';
import OfflineIco from './Offline';



function StopTask(props) {

  const [stateWindow, setStateWindow] = useState(0);
  const [stateModalSearchSite, setStateSearchSite] = useState(false);
  const [dataList, SetDataList] = useState([]);
  const [currentRecord, SetCurrentRecord] = useState([]);
  const [photoToEdit, SetEditPhoto] = useState({id: 0, Photo: "", Description: ""});
  const [showCamera, setShowCamera] = useState(false);
  const [currentFilter, SetFilter] = useState("MY");
  const [currentSearchFilter, SetSearchFilter] = useState("");
  const [firstInit, SetFirstInit] = useState(false);

  const [ongletEdit, setOngletEdit] = useState(1);
  
  var insertedId = 0;
  const [dataId, setDataId] = useState(null);
  const [siteId, setSiteId] = useState(0);
  const [siteLabel, setSiteLabel] = useState("Choisir une opération...");
  const [taskDate, setTaskDate] = useState(getCurrentDate('-'));
  const [taskTime, setTaskTime] = useState(getCurrentTime(':'));
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [risque, setRisque] = useState("");
  const [IdContact, setIdContact] = useState(0);
  const [contactLabel, setContactLabel] = useState("Choisir un contact...");
  const [concerne, setConcerne] = useState("");
  const [actionCorrective, setActionCorrective] = useState("");
  const [controllerSignature, setControllerSignature] = useState("");
  const [employeeSignature, setEmployeeSignature] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  const [rapport, setRapport] = useState("");
  const [rapportUpdated, setUpdateRapport] = useState(false);
  const editorRef = useRef(null);
  const [taskContacts, setContacts] = useState([]);
  const [chooseContacts, setChooseContacts] = useState([]);

  let [loading, setLoading] = useState(false);

  const [showSignature, setShowSignature] = useState(false);
  const [typeSignature, setTypeSignature] = useState(false);

  const [stateModalContact, setStateModalContact] = useState(false);
  const [updateNotSaved, setUpdateNotSaved] = useState(false);

  const [synchroState, setSynchroState] = useState(0);
  var sigCanvas = {};
  
  if(firstInit==false)
  {
    if(props.idStopTask > 0)
    {
      setLoading(true);
      SPSGetStopTask(props.idStopTask, SetCurrentRecord);
    }
    else if(props.idOperation > 0)
    {
      prepareNewST(props.idOperation);
    }
    SPSSynchroST(SynchroProgress)
    SetFirstInit(true);
  }
  
  
  useEffect(() => {
    SPSSiteLabel(siteId, setSiteLabel);
    setStateSearchSite(false);
  },[siteId]);

  useEffect(() => {
    if(IdContact > 0)
      SPSContactLabel(IdContact, setContactLabel);
    else
      setContactLabel("Choisir un contact...");
  }, [IdContact]);

  useEffect(() => {
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(synchroState == 0)
    {
      searchDatas();
    }
  }, [synchroState]);

  useEffect(() => {
    if(currentRecord.id != undefined)
    {
      setLoading(true);
      setDataId(currentRecord.id);
      setSiteId(currentRecord.IdSite);
      setTaskDate(currentRecord.DateStop.substring(0, 10));
      setTaskTime(currentRecord.DateStop.substring(11, 16));
      if(currentRecord.Photos != undefined)
        setPhotos(currentRecord.Photos);
      else
        setPhotos([]);
      setDescription(currentRecord.Description);
      setLocalisation(currentRecord.Localisation);
      setRisque(currentRecord.Risque);
      setConcerne(currentRecord.Concerne);
      setIdContact(currentRecord.IdContact);
      setActionCorrective(currentRecord.ActionCorrective);
      setRapport(currentRecord.Rapport);
      setContacts(currentRecord.Recipients);
      setControllerSignature(currentRecord.ControllerSignature);
      setEmployeeSignature(currentRecord.EmployeeSignature);
      setEmployeeName(currentRecord.EmployeeName);
      setStateWindow(1);
      setLoading(false);
      var idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
    }
  }, [currentRecord]);

  useEffect(() => {
    setUpdateNotSaved(true);
  }, [siteId, taskDate, taskTime, photos, description, localisation, risque, concerne, IdContact, actionCorrective])

  useEffect(() => {
    
  }, [photoToEdit]);

  useEffect(() => {
    if(ongletEdit == 2)
    {
      // si nous passons sur l'onglet de rapport, il faut enregistrer la fiche et charger la generation de rapport
      saveData(loadCurrentRecord);
    }
  }, [ongletEdit]);
  
  useEffect(() => {
    searchDatas();
  }, [currentFilter]);
  useEffect(() => {
    searchDatas();
  }, [currentSearchFilter]);

  function searchDatas()
  {
    SPSGetStopTasks(SetDataList, currentFilter, currentSearchFilter);
  }

  const handleAdd = (event) => {
    prepareNewST(0);
  };

  function SynchroProgress(state)
  {
    setSynchroState(state);
  }
  function prepareNewST(idSite)
  {
    setDataId(0);
    setSiteId(idSite);
    const dateJ = new Date();
    setTaskDate(getCurrentDate('-'));
    setTaskTime(getCurrentTime(':'));
    setPhotos([]);
    setDescription("");
    setLocalisation("");
    setRisque("");
    setConcerne("");
    setIdContact(0);
    setActionCorrective("");
    setStateWindow(1);
    setControllerSignature("");
    setEmployeeSignature("");
    setEmployeeName("");
    var idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
  }

  const handleCancelAdd= (event) => {
    if(props.idStopTask > 0)
      props.screenHandling(0);
    else
    {
      if(updateNotSaved)
      {
        confirmAlert({
          title: 'Annulation',
          message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                setStateWindow(0);
              }
            },
            {
              label: 'Non',
              onClick: () => { 

              }
            }
          ]
        });
      }
      else
        setStateWindow(0);
    }
  };
  const handleClickRecord = (id) => {
    setLoading(true);
    SPSGetStopTask(id, SetCurrentRecord);
  };

  function loadCurrentRecord()
  {
    setLoading(true);
    SPSGetStopTask(insertedId, SetCurrentRecord);
  }

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet arrêt de tâche ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteStopTask(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    searchDatas();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData(setStateWindow);
  };

  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:dataId,
      IdSite: siteId,
      Description: description,
      Localisation: localisation,
      Risque: risque,
      IdContact: IdContact,
      Concerne: concerne,
      ActionCorrective: actionCorrective,
      DateStop: taskDate+' '+taskTime,
      Photos: photos,
      ControllerSignature: controllerSignature,
      EmployeeSignature: employeeSignature,
      EmployeeName: employeeName,
      Rapport: '',
      Recipients: ''
    };
    if(rapportUpdated)
    {
      body.Rapport = rapport;
    }
    
    body.Recipients = [...chooseContacts];
       
    SPSSaveStopTask(body, handleInsertedId, handleEnd);
  };

  const handlePrepareRapport = (event) => {
    //setShowEnvoiRapport(true);
  };
  
  const handleInsertedId = (id) => {
    setDataId(id);
    insertedId = id;
  }

  const handleBack = (event) => {
    props.screenHandling(0);
  };

  const handleShowModalSearchSite = () => {
    if(stateModalSearchSite==true)
      setStateSearchSite(false);
    setStateSearchSite(true);
  }
   
  const handleCamera = () => {
    if(showCamera==true)
      setShowCamera(false);
    setShowCamera(true);
  };

    const handleAddPhoto = (dataUri) => {
      const photoCopy = [...photos];

      const p = {id:(new Date().getTime()) * -1, Photo: dataUri}
      photoCopy.push(p);
      
      // modifie le state original
      setPhotos(photoCopy);
      
      
    };
    const handleDeletePhoto = (id) => {
      confirmAlert({
        title: 'Suppression',
        message: 'Souhaitez-vous supprimer cette photo ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              // copie du state
              const photoCopy = [...photos];
              // suppression de la photo
              const photoCopyUpdated = photoCopy.filter((photo) => photo.id !== id);

              // modifie le state original
              setPhotos(photoCopyUpdated);
            }
          },
          {
            label: 'Non',
            onClick: () => { 

            }
          }
        ]
      });
      
    };

    const handleEditPhoto = (id) => {
      /*const p = photos.filter((photo) => photo.id == id);
      if(p.length > 0)
      {
        SetEditPhoto(structuredClone(p[0]));
        setStatePhoto(true);
      }*/
    };

    const handleChangeRapport = (value, editor) => {
      setUpdateRapport(true);
      setRapport(value);
    };

    
    const handleSendRapport= () => {
      saveData(sendRapport);
    };

    const sendRapport = (handleEnd) => {
      SPSSendRapportTask(dataId, afterSendRapport);
    }

    const afterSendRapport = () => {
      setLoading(false);
      alert("Le rapport a été envoyé.");
    }

    const handleClosedSignature = () => {
      setShowSignature(false);
    }

    const handleValideSignature = () => {
      const data = sigCanvas.toDataURL('image/png');
      switch(typeSignature)
      {
        case 'controller':
          setControllerSignature(data);
          break;
        case 'employee':
          setEmployeeSignature(data);
          break;
      }
      setShowSignature(false);
    }

    const handleSigner = (type) => {
      setTypeSignature(type);
      setShowSignature(true);
    }

    const handleShowModalSearchContact = () => {
      setStateModalContact(true);
    }

    const handleChooseContact = (id) => {
      setIdContact(id);
    }

    switch(stateWindow)
    {
      case 1:
        return (
          <div className="containerxl">
            
              <div className="row headScreen fixed-top px-0">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
                <div className="col-6"><h1 className="h4 py-3">Arrêt de tâche <OfflineIco /></h1> </div>
                <div className="col-3 text-end py-2"><button className="btn btn-link" type="button" onClick={handleSubmit}>Enregistrer</button></div>
              </div>
              <SPSLoader load={loading} />

            <div id="onglet1" className={ongletEdit==1 ? 'onglet d-block' : 'onglet d-none'}>
              <form onSubmit={handleSubmit}>
                <div className="p-3">
                  <input type="hidden" name="id" value={dataId} />
                  <div className="text-center">{currentRecord.SentDate && currentRecord.SentDate != "0000-00-00 00:00:00" ? <span className="text-success font-sm">Envoyé le {datetime_to_fr(currentRecord.SentDate).date}</span>:''}</div>
                  <div className="form-group mb-3">
                    <label className="me-3">Opération</label>
                    <input type="hidden" name="IdSite" value={siteId} />
                    <button type="button" className="btn btn-outline-secondary" onClick={handleShowModalSearchSite}>{siteLabel}</button>
                    
                  </div>
                  <div className="form-group mb-3">
                    <label id="label-chantier" >Date</label>
                    <div className="row">
                      <div className="col-6 col-md-2">
                        <input type="date" name="dateVisit" value={taskDate} className="form-control" onChange={e => { setTaskDate(e.target.value); }}/>
                      </div>
                      <div className="col-6 col-md-2">
                        <input type="time" name="dateVisit" value={taskTime} className="form-control" onChange={e => { setTaskTime(e.target.value); }}/>
                      </div>
                    </div>
                    
                  </div>
                  <div className="form-group pos-relative">
                    <label id="label-chantier" className="mb-2">Photos</label>
                    <ul className="row liste-photo">
                      <li className="col-3 col-md-1 mb-2 p-0 line-photo ">
                        <button id="btnCamera" type="button" className="btn btn-light color-primary btn-xl w-100" onClick={handleCamera}>
                            <FontAwesomeIcon icon={faCamera} />
                            <br />Prendre une photo
                        </button>
                      </li>
                      {photos.map((photo) =>(
                      <li className="col-3 col-md-1 mb-2 line-photo text-start" key={photo.id}>
                          <img src={photo.Photo} onClick={() => handleEditPhoto(photo.id)} className="w-100"/>
                          <p>{photo.Description}</p>
                          <button type="button" className="btn-delete-line" onClick={() => handleDeletePhoto(photo.id)}><FontAwesomeIcon icon={faXmark} /></button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Localisation</label>
                    <input type="text" name="Localisation" className="form-control"  onChange={e => { setLocalisation(e.target.value); }} value={localisation} />
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Observations</label>
                    <textarea name="Description" rows="5" className="form-control"  onChange={e => { setDescription(e.target.value); }} value={description}></textarea>
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Risque</label>
                    <textarea name="Risque" rows="5" className="form-control"  onChange={e => { setRisque(e.target.value); }} value={risque}></textarea>
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Concerne</label>
                    <input type="text" name="Concerne" className="form-control"  onChange={e => { setConcerne(e.target.value); }} value={concerne} />
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Contact</label><input type="hidden" name="IdContact" value={IdContact} /><br />
                    <button type="button" className="btn btn-outline-secondary" onClick={handleShowModalSearchContact}>{contactLabel}</button>
                  </div>
                  <div className="form-group">
                    <label id="label-chantier" >Action corrective</label>
                    <textarea name="ActionCorrective" rows="5" className="form-control"  onChange={e => { setActionCorrective(e.target.value); }} value={actionCorrective}></textarea>
                  </div>
                  <div className="form-group mb-2 ">
                    <label className="mb-2">Signatures</label>
                    <div className="row">
                      <div className="col-md-6 p-0">
                        <div className="border me-md-2 mb-2 p-2 pos-relative">
                          <label className="mb-2">Controleur</label>
                          <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner('controller')}> Signer</button>
                          {controllerSignature !== "" ? <img onClick={() => handleSigner('controller')} src={controllerSignature} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner('controller')}>Aucune signature</label>}
                        </div>
                        
                      </div>
                      <div className="col-md-6 p-0 ">
                        <div className="border ms-md-2 p-2 pos-relative">
                          <label className="mb-2">Employé</label>
                          <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner('employee')}> Signer</button>
                          {employeeSignature !== "" ? <img onClick={() => handleSigner('employee')} src={employeeSignature} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner('employee')}>Aucune signature</label>}
                          <input type="text" name="EmployeeName" placeholder="Nom prénom et poste" className="form-control"  onChange={e => { setEmployeeName(e.target.value); }} value={employeeName} />
                        </div>
                      </div>
                    </div>
                    
                  </div>

                  
                </div>
                
              </form>
                      
              <ModalSearchSite showModal={stateModalSearchSite} setSiteId={setSiteId} handleClosed={() => setStateSearchSite(false)}/>
              <ModalCamera showModal={showCamera} handleClosed={() => setShowCamera(false)} takePhoto={handleAddPhoto}></ModalCamera>
              <ModalSearchContacts showContact={stateModalContact} handleClosed={() => setStateModalContact(false)} handleClickContact={handleChooseContact}></ModalSearchContacts>
              <Modal show={showSignature} onHide={handleClosedSignature} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Signature</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <SignatureCanvas canvasProps={{height: 500, className: 'sigCanvas'}} ref={(ref) => { sigCanvas = ref }} />
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedSignature}>Annuler</button>
                      <button type="button" className="btn btn-primary" onClick={handleValideSignature}>Valider</button>
                  </Modal.Footer>
              </Modal>
              
            </div>
            <div id="onglet2" className={ongletEdit==2 ? 'onglet d-block': 'onglet d-none'}>
              <div className="container-xxl mt-2">
                <Tabs defaultActiveKey="content" id="uncontrolled-tab-example" className="mb-3" >
                  <Tab eventKey="content" title="Contenu">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={currentRecord.Rapport}
                        init={{
                          height: 500,
                          menubar: true,
                          plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                            'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'help', 'wordcount'
                          ],
                          toolbar: 'formatselect | ' +
                          'bold italic underline backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                          'removeformat | help',
                        }}
                        tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                        onEditorChange={handleChangeRapport}
                      />
                  </Tab>
                  <Tab eventKey="diffusion" title="Liste de diffusion">
                    <RecipientsList contactsList={taskContacts} chooseContact={true} setRecipients={setChooseContacts}/>
                  </Tab>
                </Tabs>
                <div className="text-center py-2">
                  <button type="button" className="btn btn-primary" onClick={handleSendRapport}>Envoyer</button>
                </div>
              </div>
            </div>
            <div className="row footerToolbar px-0">
              <div className="col text-center px-0"><button className={ongletEdit==1 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(1)}}><FontAwesomeIcon icon={faPencil} /></button></div>
              <div className="col text-center px-0"><button className="btn btn-link text-secondary" type="button" onClick={handleCamera}><FontAwesomeIcon icon={faCamera} /></button></div>
              <div className="col text-center px-0"><button className={ongletEdit==2 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(2)}}><FontAwesomeIcon icon={faPaperPlane} /></button></div>
            </div>
            
        </div>
        );
        break;
      default:
        if(!props.idStopTask)
        {
          var liste_vide = "";
          if(dataList.length == 0)
          {
            liste_vide = <div className="lineEmptyTab">Aucun arrêt de tâche</div>
          }
          return (
            <div className="containerxl" >
              <div className="row headScreen fixed-top px-0">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
                <div className="col-6"><h1 className="h4 py-3">Arrêts de tâche <OfflineIco /></h1></div>
                <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button></div>
              </div>
              <SPSLoader load={loading} />
            <div className={synchroState > 0?"synchro_bar active":"synchro_bar"}>Synchronisation en cours <FontAwesomeIcon icon={faRotate} /></div>
              <div className="content-liste">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="px-0">
                        <ul className="container">
                          {dataList.map((d) =>(
                          <li className={d.local? "row mb-2 alternate-color bg-warning-subtle py-2":  "row mb-2 alternate-color py-2"} key={d.id}  ><div className="d-none">{d.id}</div>
                              <div className="col-12 col-md-7" onClick={() => handleClickRecord(d.id)}>{d.local?<span className="text-warning me-3"><FontAwesomeIcon icon={faTriangleExclamation} /></span>:""}{d.site_Title!= undefined?d.site_Title+" "+d.site_Zip+" "+d.site_City: SPSSiteLabelLocal(d.IdSite)}</div>
                              <div className="col-8 col-md-4 text-start font-mobile-sm" onClick={() => handleClickRecord(d.id)}>
                                {datetime_to_fr(d.DateStop).date} {d.SentDate=="0000-00-00 00:00:00"|| d.SentDate==undefined || d.SentDate=='' ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(d.SentDate).date}</span>}
                              </div>
                              <div className="col-4 col-md-1 text-end">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(d.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </li>
                          ))}
                        </ul>
                        {liste_vide}
                        <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un arrêt de tâche</button></div>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row footerToolbar px-0">
                <div className="col-6 text-center px-2">
                  <select className="form-select text-center border-0 my-2" onChange={e => { SetFilter(e.target.value); }} value={currentFilter}>
                    <option value="MY">Mes Arrêts en cours</option>
                    <option value="ALLMY">Tous mes arrêts</option>
                    <option value="ALLCURRENT">Tous les arrêts en cours</option>
                    <option value="ALL">Tous les arrêts</option>
                  </select>
                </div>
                <div className="col-6 text-center px-2">
                  <div className="input-group my-2">
                    <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    <input type="text" className="form-control" value={currentSearchFilter} placeholder="Opération, Code postal..." aria-label="Username" aria-describedby="basic-addon1"  onChange={e => { SetSearchFilter(e.target.value); }}/>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else
          return('');
        break;
    }
  
}

export default StopTask;
