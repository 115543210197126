import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const Xdata = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "courier", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function SPSGetSitePGCs(idSite, showPGCs)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/pgc?site="+idSite);
    showPGCs(result.data);
  }
}

export async function SPSGetSiteDIUOs(idSite, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/diuo?site="+idSite);
    showDatas(result.data);
  }
}

export async function SPSGetSiteInvitCISSCTs(idSite, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/invitcissct?site="+idSite);
    showDatas(result.data);
  }
}

export async function SPSGetSitePVCISSCTs(idSite, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/cissct?site="+idSite);
    showDatas(result.data);
  }
}

export async function SPSGetSiteCouriers(idSite, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/courier?site="+idSite);
    showDatas(result.data);
  }
}

export async function SPSGetCourier(id, afterGetCourier)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/"+id);
    afterGetCourier(result.data);
  }
}

export async function SPSMakeCourier(idSite, type, afterMake, idContact)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    var urlComplement = '';
    if(idContact) 
      urlComplement = '&contact='+idContact;
    result = await Xdata.get("/make?site="+idSite+"&type="+type+urlComplement);
    console.log("retour courrier : "+JSON.stringify(result));
    afterMake(type, result.data, idContact);
  }
}

export async function SPSSaveCourier(idSite, courier, afterSave)
{
  var result = [];
  var id = -1;
  if(window.SpsServerAvailable)
  {
    var body = {IdSite: idSite, IdContact: courier.IdContact, Type: courier.Type, Courier: courier.Courier}
    if(courier.id  > 0)
    {
      result = await Xdata.put("/"+courier.id, body);
    }
    else
    {
      result = await Xdata.post("/", body);
    }
    if(result.data['id'])
      id=result.data['id'];
    afterSave(id);
  }
  
}

export async function SPSSendCourier(id, afterSent)
{
  if(window.SpsServerAvailable)
  {
    await Xdata.get("/send/"+id);
    afterSent();
  }
}

export async function SPSDeleteCourier(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    await Xdata.delete("/"+id);
    afterDelete();
  }
}

export async function SPSDuplicateCourier(id, afterCopy)
{
  if(window.SpsServerAvailable)
  {
    await Xdata.get("/copy/"+id);
    afterCopy();
  }
}
