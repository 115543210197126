import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const Xdata = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "docexec", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function SPSGetDoc(id, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/"+id);
    showDatas(result.data);
  }
}

export async function SPSGetSiteDocs(idSite, showDatas)
{
  var result = [];
  if(window.SpsServerAvailable)
  {
    result = await Xdata.get("/site/"+idSite);
    showDatas(result.data);
  }
}


export async function SPSSaveDoc(docid, datas, afterSave)
{
  var result = [];
  var id = -1;
  if(window.SpsServerAvailable)
  {
    var body = {IdSite: datas.IdSite, IdCompany: datas.IdCompany, Type: datas.Type, Document: datas.Document, DateDocument: datas.DateDocument, Commentaire: datas.Commentaire}
    if(docid  > 0)
    {
      result = await Xdata.put("/"+docid, body);
    }
    else
    {
      result = await Xdata.post("/", body);
    }
    if(result.data['id'])
      id=result.data['id'];
    console.log("Enregistrement du doc exec : "+JSON.stringify(body));
    afterSave(id);
  }
  
}

export async function SPSSendDoc(id, afterSent)
{
  if(window.SpsServerAvailable)
  {
    const result = await Xdata.get("/send/"+id);
    console.log(JSON.stringify(result));
    afterSent(result);
  }
}

export async function SPSDeleteDoc(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    await Xdata.delete("/"+id);
    afterDelete();
  }
}

export  function SPSPDFDocExec(id_site)
{
  //return 'https://apisps.cornillet.com/CA_EN_web.pdf';
  return process.env.REACT_APP_USER_API_BASE_URL + "docexec"+'/pdf/'+id_site+'/?token='+token;
}
