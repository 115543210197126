import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const Xvisite = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "site", 
  headers : { Authorization : 'Bearer ' + token }
});

/*************************************************************
 * SPSLocalSites
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des opérations contenues en local (localstorage)
 *************************************************************/
export function SPSLocalSites()
{
  var sites = [];
  const ls = localStorage.getItem('sites');
  if(ls)
  {
    try {
      sites = JSON.parse(ls);
    } catch (e) {
      sites = [];
    }
    
  }
  if(!sites.length || sites[0].id == undefined) 
    sites = [];

  return sites;
}

/*************************************************************
 * SPSStoreLocalSites
 * -----------------------------------------------------------
 * data : tableau des operations locales
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
function SPSStoreLocalSites(data)
{
  // on supprime les éléments trop volumineux (images...)
  for(var i = 0;i < data.length;i++)
  {
    data[i].alter_pilote_Signature = '';
    data[i].pilote_Signature = '';
  }
  localStorage.setItem('sites', JSON.stringify(data));
}

/*************************************************************
 * SPSSiteLabelLocal
 * -----------------------------------------------------------
 * id : id de l'opération
 * -----------------------------------------------------------
 * Retourne le libellé de l'opération (pioche dans le local)
 *************************************************************/
export function SPSSiteLabelLocal(id)
{
  var siteLabel = '';
  if(id> 0)
  {
    const sites = SPSLocalSites();
      
    if(sites)
    {
      const s = sites.filter((site) => site.id == id);
      if(s.length > 0)
        siteLabel = s[0].Title+" "+s[0].Zip+" "+s[0].City;
    }
    
    if(siteLabel == "")
    {
      siteLabel = <em className="text-muted">Connexion internet necessaire</em>;
    }
  }
  else
    siteLabel = <em className="text-muted">Opération non sélectionnée</em>;

    return siteLabel;
}

/*************************************************************
 * SPSLoadSites
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Charge les opérations et les stock en local
 *************************************************************/
export async function SPSLoadSites()
{
  if(window.SpsServerAvailable)
  {
      const sites = await Xvisite.get('/');
      if(sites.data.length > 0)
        SPSStoreLocalSites(sites.data);
  }
}

/*************************************************************
 * SPSSites
 * -----------------------------------------------------------
 * SetSites : calback ou envoyer les données chargées
 * -----------------------------------------------------------
 * Charge les opérations (en ligne ou local) et les retourne au callback
 *************************************************************/
export async function SPSSites(SetSites, type, search)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    var qs = 'type='+type;
    if(search)qs+= '&search='+search;
    try {
      const sites = await Xvisite.get('/?'+qs).then();
      SetSites(sites.data);
    } catch (err) {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  
  if(returnlocal)
  {
    const sites = SPSLocalSites();
    SetSites(sites);
  }
}

/*************************************************************
 * SPSSiteLabel
 * -----------------------------------------------------------
 * id : id de l'opération
 * setSiteLabel : callback ou retouner le libellé de l'opération
 * -----------------------------------------------------------
 * Retourne le libellé de l'opération demandée
 *************************************************************/
export async function SPSSiteLabel(id, setSiteLabel)
{
  var siteLabel = "";
  if(id != 0)
  {
    const sites = SPSLocalSites();
    
    if(sites)
    {
      const s = sites.filter((site) => site.id == id);
      if(s.length > 0)
        siteLabel = s[0].Title+" "+s[0].Zip+" "+s[0].City;
    }
    
    if(siteLabel == "" && !navigator.onLine)
    {
      siteLabel = "Connexion internet necessaire"
    }
    else if(siteLabel == "")
    {
      const s = await Xvisite.get('/'+id);
      if(s.data.length > 0)
      {
        sites.push(s.data);
        SPSStoreLocalSites(sites);
        siteLabel = s.data.Title+" "+s.data.Zip+" "+s.data.City;
      }
      else
        siteLabel = "Opération inconnue !"
    }
    
   
  }
  else
    siteLabel = "Choisir...";

  setSiteLabel(siteLabel);
}

/*************************************************************
 * SPSGetSite
 * -----------------------------------------------------------
 * id : id de l'opération
 * SetSite : callback ou retouner l'operation demandée
 * -----------------------------------------------------------
 * Retourne les données l'opération demandée
 *************************************************************/
export async function SPSGetSite(id, SetSite)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      const s = await Xvisite.get('/'+id);
      if(s.data.length > 0)
      {
        SetSite(s.data[0]);
      }
    }
    catch(err) {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  
  if(returnlocal)
  {
    const sites = SPSLocalSites();
    const s = sites.filter((site) => site.id == id);
    if(s.length > 0)
      SetSite(s[0]);
  }
}

/*************************************************************
 * SPSRequestSites
 * -----------------------------------------------------------
 * words : mots clés recherchés
 * SetSite : callback ou retouner les opérations trouvées
 * -----------------------------------------------------------
 * Recherche des opérations suivant des mots clés
 *************************************************************/
export async function SPSRequestSites(words, SetSites)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      const s = await Xvisite.get('/search/'+words);
      SetSites(s.data);
    }
    catch (err)
    {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  if(returnlocal)
  {
    const sites = SPSLocalSites();
    const s = sites.filter((site) => (site.Title+" "+site.Zip+" "+site.City).search(words) >= 0);
    SetSites(s);
  }
  
}

/*************************************************************
 * SPSSaveSite
 * -----------------------------------------------------------
 * body : objet contenant les données de l'opération
 * afterSave : callback à appeler après enregistrement
 * -----------------------------------------------------------
 * Enregistre l'opération sur le serveur
 *************************************************************/
export async function SPSSaveSite(body, afterSave)
{
  if(window.SpsServerAvailable)
  {
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    
    var result = [];
    try {
      if(body.id > 0)
      {
        result = await Xvisite.put("/"+body.id, body);
        
        afterSave(0);
      }
      else
      {
        result = await Xvisite.post("/", body);
        afterSave(0);
      }
    }
    catch(err)
    {
      alert("Vous devez être connecté pour enregistrer.");
    }
    
  }
  else
  {
    alert("Vous devez être connecté pour enregistrer.");
  }
}
