import { useEffect, Component } from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons'

import {SPSCompanyTypes, SPSGetCompanyType, SPSSaveCompanyType, SPSDeleteCompanyType} from '../../functions/SPSCompanyType'


export class CompanyTypes extends Component {  
  constructor() {  
    super();  
    this.state = {
      id: 0,
        Label: '',
        stateWindowEdit: 0,
        types: []
    }
    this.SearchDatas();
    
  }  

  handleRetour = (event) => {
    this.props.parentStateModifier({"showSettingsTypeCompany" : false} );
  }

  handleAddCT = (event) => {
    this.setState({
      id: 0,
      Label: '',
      stateWindowEdit:1
   
  });
  }

  componentDidUpdate() {
    
    
    //setLoading(false);
 }

  

  handleClickLine = (id) => {
    SPSGetCompanyType(id, this.SetData);
    //setLoading(true);
    //SPSGetVisite(id, SetTheVisit);
  }
  handleDeleteTC = (id) => {
    SPSDeleteCompanyType(id, this.SearchDatas)
  }

  SearchDatas = () => {
    SPSCompanyTypes(this.SetTypes);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var datas = {id: this.state.id, Label: this.state.Label};
    
    SPSSaveCompanyType(datas, this.afterSave);
  }

  afterSave = () => {
    this.setState({id: 0, Label: '', stateWindowEdit: 0});
    this.SearchDatas();
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.afterSave();
  }
  SetTypes = (data) => {
    this.setState({
      types: data
    })
  };

  SetData = (data) => {
    this.setState({
        id: data.id,
        Label: data.Label, 
     
    });

    if(data.id > 0)
      this.state.stateWindowEdit = 1;
  }
  
  render() {  
    if(this.state.stateWindowEdit == 1)
    {
      
      
      return (
      <div className="full-screen containerxl ">
        <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
          <div className="col-6"><h1 className="h4 py-3">Type de société</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container d-block my-2">
          <form onSubmit={this.handleSubmit}>
          <div className="form-group row mb-3">
                <label className="col-md-3">Libellé</label>
                <div className="col-md-9">
                <input type="text" className="form-control" name="Label" value={this.state.Label} onChange={e => { this.setState({Label: e.target.value}); }} />
                </div>
            </div>
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </form>
          
        </div>
      </div>

      );

    }
    else
    {
      return (  
          <div className="full-screen containerxl ">
            <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Types de société</h1></div>
            <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={this.handleAddCT}><FontAwesomeIcon icon={faPlus} /></button></div>
          </div>
          <div className="d-grid gap-2 d-block">
            <div className="container py-3">
               <ul className="my-2 liste-settings ps-1">
                {this.state.types.map((r) =>(
                <li className="row mb-2 alternate-color py-2" key={r.id}  >
                    <div className="col-8 col-md-10" onClick={() => this.handleClickLine(r.id)}>{r.Label}</div>
                    <div className="col-4 col-md-2 text-end">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDeleteTC(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                    </div>
                </li>
                ))}
              </ul>
             
            </div>
          </div>
          
          
      </div>);
    }
  }  
}

export default CompanyTypes;
