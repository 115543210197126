import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faPaperPlane, faTrashCan, faPencil, faTriangleExclamation, faMagnifyingGlass, faRotate} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { SPSSiteLabel, SPSSiteLabelLocal } from '../functions/SPSSites';
import { SPSICs, SPSGetIC, SPSDeleteIC, SPSSaveIC, SPSBasicIC, SPSSendRapportIC, SPSEmptyIC, SPSMakeRapportIC, SPSSynchroICs } from '../functions/SPSICs';
import {SPSCompanies} from '../functions/SPSCompany';
import {SPSSearchContact} from '../functions/SPSContact';
import { datetime_to_fr, getCurrentDate,getCurrentTime } from '../functions/tools';
import ModalSearchSite from './ModalSearchSite';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SPSLoader from './SPSLoader';
import { Editor } from '@tinymce/tinymce-react';
import { initConfig, simpleConfig } from '../functions/initConfig';
import RecipientsList from './RecipientsList';
import SignatureCanvas from 'react-signature-canvas'
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import OfflineIco from './Offline';



function InspectionCommune(props) {

  const [stateWindow, setStateWindow] = useState(0);
  const [stateModalSearchSite, setStateSearchSite] = useState(false);
  const [showEnvoiRapport, setShowEnvoiRapport] = useState(false);
  const [ICs, SetICs] = useState([]);
  const [theIC, SetTheIC] = useState([]);
  const [currentFilter, SetFilter] = useState("MY");
  const [currentSearchFilter, SetSearchFilter] = useState("");
  const [firstChargementRecord, setFirstChargementRecord] = useState(false);
  const [firstInit, SetFirstInit] = useState(false);
  
  const [ICId, setICId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [siteLabel, setSiteLabel] = useState("Choisir une opération...");
  const [ICDate, setICDate] = useState(getCurrentDate('-'));
  const [ICTime, setICTime] = useState(new Date().toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",hour12: false}));
  const [IC, setIC] = useState(SPSEmptyIC()); 
  
  const [ongletEdit, setOngletEdit] = useState("1");
  const [companies, setCompanies] = useState([]);
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [representants, setRepresentants] = useState([]);
  const [RapportOriginal, setRapportOriginal] = useState("");
  const [listContacts, setContacts] = useState([]);
  const [chooseContacts, setChooseContacts] = useState([]);

  const [TravauxARealiserOrig, setTravauxARealiserOrig] = useState("");
  const [TravauxSsTraitesOrig, setTravauxSsTraitesOrig] = useState("");
  const [RemarqueOrig, setRemarqueOrig] = useState("");

  const [componentLoaded, setLoaded] = useState(false);

  const editorRef = useRef(null);

  let [loading, setLoading] = useState(false);

  const [showSignature, setShowSignature] = useState(false);
  const [typeSignature, setTypeSignature] = useState(false);
  const [updateNotSaved, setUpdateNotSaved] = useState(false);
  const [synchroState, setSynchroState] = useState(0);

  

  var idtimer = 0;

  var sigCanvas = {};
  if(firstInit==false)
  {
    if(props.idIC > 0)
    {
      setLoading(true);
      SPSGetIC(props.idIC, setIC);
    }
    else if(props.idOperation > 0)
    {
      prepareNewIC(props.idOperation);
    }
    SetFirstInit(true);
    SPSSynchroICs(SynchroProgress);
  }

  if(componentLoaded==false)
    setLoaded(true);
  
  useEffect(() => {
    SPSCompanies(setCompanies);
  },[componentLoaded]);
  
  useEffect(() => {
    
    var options = [];//{name:'', value: 0}
    for(var i = 0; i < companies.length; i++)
    {
      options.push({name: companies[i].Name, value: companies[i].id});
    }

    setOptionsCompany(options);
    
  }, [companies]);

  useEffect(() => {
    SPSSiteLabel(siteId, setSiteLabel);
    setIC({...IC, IdSite:siteId});
    setStateSearchSite(false);
  },[siteId]);

  useEffect(() => {
    if(ongletEdit == 2)
    {
      setLoading(true);
      // au passage sur l'onglet d'envoi de rapport, il faut enregistrer le contenu pour générer le rapport
      SPSSaveIC(IC,makeRapport);
      if(!window.SpsServerAvailable)
      {
        alert("Vous devez être connecté pour générer le rapport !");
        setOngletEdit(1);
        setLoading(false);
      }
    }
  }, [ongletEdit])

  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(synchroState == 0)
    {
      searchDatas();
    }
  }, [synchroState]);

  useEffect(() => {
    if(theIC.id != undefined)
    {
      setLoading(true);
      setSiteId(theIC.IdSite);
      setICDate(theIC.ICDate.substring(0, 10));
      setICTime(theIC.ICDate.substring(11, 16));
      
      
      
      setStateWindow(1);
      setLoading(false);
    }
  }, [theIC]);

  useEffect(() => {
    if(IC.id != 0 && firstChargementRecord == false)
    {
      setLoading(true);
      setSiteId(IC.IdSite);
      //setICDate(IC.DateIC.substring(0, 10));
      setRapportOriginal(IC.Rapport);
      setTravauxARealiserOrig(IC.TravauxARealiser);
      setTravauxSsTraitesOrig(IC.TravauxSousTraites);
      setRemarqueOrig(IC.Remarque);
      if(IC.IdCompany)
        SPSSearchContact(IC.IdCompany, '', setRepresentants);
      setStateWindow(1);
      setLoading(false);
      setFirstChargementRecord(true);
    }
    setUpdateNotSaved(true);
  }, [IC]);

  useEffect(() => {
    if(IC.IdCompany)
        SPSSearchContact(IC.IdCompany, '', setRepresentants);
  }, [IC.IdCompany]);

  useEffect(() => {
    searchDatas();
  }, [currentFilter]);
  useEffect(() => {
    searchDatas();
  }, [currentSearchFilter]);

  function searchDatas()
  {
    SPSICs(SetICs, currentFilter, currentSearchFilter);
  }

  const setRapport = (rapport) => {
    setIC({...IC, Rapport:rapport});
    setRapportOriginal(rapport);
  };

  const afterMakeRapport = (rapport) => {
    setRapport(rapport);
    setLoading(false);
  };

  const makeRapport = (id) => {
    setIC({...IC, id: id});
    SPSMakeRapportIC(id, afterMakeRapport);
  };
  
  const handleAddIC = (event) => {
    prepareNewIC(0);
  };

  function SynchroProgress(state)
  {
    setSynchroState(state);
  }

  function prepareNewIC(idSite)
  {
    setICId(0);
    setSiteId(idSite);
    setIC(SPSEmptyIC());
    setICDate(getCurrentDate('-'));
    setICTime(getCurrentTime(':'));
    setStateWindow(1);
    idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
  }

  const handleCancelAdd= (event) => {
    if(props.idIC > 0)
      props.screenHandling(0);
    else
    {
      if(updateNotSaved)
      {
        confirmAlert({
          title: 'Annulation',
          message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                setFirstChargementRecord(false);
                setStateWindow(0);
                setFirstChargementRecord(false);
              }
            },
            {
              label: 'Non',
              onClick: () => { 

              }
            }
          ]
        });
      }
      else
      {
        setStateWindow(0);
        setFirstChargementRecord(false);
      }
    }
  };
  const handleClickIC = (id) => {
    setLoading(true);
    SPSGetIC(id, loadIC);
  };

  const loadIC = (loadedIC) => {
    var vIC = {};
    SPSBasicIC(loadedIC, setIC);
    idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
  }

  const handleDeleteIC = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cette Inscpection Commune ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteIC(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    searchDatas();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveIC(afterSave);
    setFirstChargementRecord(false);
    
  };

  const afterSave = () => {
    setStateWindow(0)
    setIC(SPSEmptyIC());
  }
  
  const saveIC = (handleEnd, includeRapport) => {
    setLoading(true);
    SPSSaveIC(IC, handleInsertedId, handleEnd, includeRapport);
  };

  const handleSendIC = (event) => {
    setShowEnvoiRapport(true);
  };
  
  const handleInsertedId = (Id) => {
    setICId(Id);
    setIC({...IC, id:Id});
  }

  const handleBack = (event) => {
    props.screenHandling(0);
  };

  const handleShowModalSearchSite = () => {
    if(stateModalSearchSite==true)
      setStateSearchSite(false);
    setStateSearchSite(true);
  }

  const handleChangeTravauxARealiser = (value, editor) => {
    setIC({...IC, TravauxARealiser:value})
  }

  const handleChangeTravauxSousTraites = (value, editor) => {
    setIC({...IC, TravauxSousTraites:value})
  }

  const handleChangeRemarque = (value, editor) => {
    setIC({...IC, Remarque:value})
  }

  const handleTypeLivraison = (e) => {
    if(!IC.TypeLivraison)
      IC.TypeLivraison = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.TypeLivraison];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.TypeLivraison.filter(val => val != e.target.value);
    }
    setIC({...IC, TypeLivraison: values});
  }

  const handleTypeManutention = (e) => {
    if(!IC.TypeManutention)
      IC.TypeManutention = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.TypeManutention];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.TypeManutention.filter(val => val != e.target.value);
    }
    setIC({...IC, TypeManutention: values});
  }

  const handleEchafaudages = (e) => {
    if(!IC.Echafaudages)
      IC.Echafaudages = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.Echafaudages];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.Echafaudages.filter(val => val != e.target.value);
    }
    setIC({...IC, Echafaudages: values});
  }
  
  
  const handleMontage = (e) => {
    if(!IC.Montage)
      IC.Montage = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.Montage];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.Montage.filter(val => val != e.target.value);
    }
    setIC({...IC, Montage: values});
  }

  const handlePlateformeIndividuelle = (e) => {
    if(!IC.PlateformeIndividuelle)
      IC.PlateformeIndividuelle = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.PlateformeIndividuelle];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.PlateformeIndividuelle.filter(val => val != e.target.value);
    }
    setIC({...IC, PlateformeIndividuelle: values});
  }

  const handleNacelles = (e) => {
    if(!IC.Nacelles)
      IC.Nacelles = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.Nacelles];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.Nacelles.filter(val => val != e.target.value);
    }
    setIC({...IC, Nacelles: values});
  }

  const handleAutresEquipements = (e) => {
    if(!IC.AutresEquipements)
      IC.AutresEquipements = [];

      var values;

    if(e.target.checked)
    {
      values = [...IC.AutresEquipements];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = IC.AutresEquipements.filter(val => val != e.target.value);
    }
    setIC({...IC, AutresEquipements: values});
  }

  const handleClosedSignature = () => {
    setShowSignature(false);
  }

  const handleValideSignature = () => {
    const data = sigCanvas.toDataURL('image/png');
    switch(typeSignature)
    {
      case 'coordonateur':
        setIC({...IC, SignatureCoordonateur: data});  
        break;
      case 'representant':
        setIC({...IC, SignatureRepresentant: data});  
        break;
      case 'autre_participant':
        setIC({...IC, SignatureAutreParticipant: data});  
        break;
    }
    setShowSignature(false);
  }

  const handleSigner = (type) => {
    setTypeSignature(type);
    setShowSignature(true);
  }

  const handleChangeRapport = (value, editor) => {
    setIC({...IC, Rapport:value});
    //setRapport(value);
  };

  const handleSendRapport= () => {
    saveIC(sendRapport, true);
    
  };

  const sendRapport = () => {
    SPSSendRapportIC(IC.id, afterSendRapport);
  }

  const afterSendRapport = () => {
    setLoading(false);
    alert("Le rapport a été envoyé.");
  }
  
  

    switch(stateWindow)
    {
      case 1:

        return (
          <div className="containerxl">
            <div className="row headScreen fixed-top px-0">
              <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6"><h1 className="h4 py-3">Inspection Commune <OfflineIco /></h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <SPSLoader load={loading} />
            <div id="onglet1" className={ongletEdit==1 ? 'onglet d-block mt-2' : 'onglet d-none '}>
              <form onSubmit={handleSubmit}>
                <div className=" container p-3">
                  <div className="text-center"><input type="hidden" name="id" value={ICId} />{IC.SentDate && IC.SentDate != "0000-00-00 00:00:00" ? <span className="text-success font-sm">{datetime_to_fr(IC.SentDate).date}</span>:''}</div>
                  <div className="">
                    <div className="form-group row mb-3">
                      <label className="col-md-4">Opération</label>
                      <div className="col-md-8">
                        <input type="hidden" name="IdSite" value={siteId} />
                        <button type="button" className="btn btn-outline-secondary" onClick={handleShowModalSearchSite}>{siteLabel}</button>
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label className="col-md-4" >Date</label>
                      <div className="col-6 col-md-2">
                        <input type="date" value={IC.DateIC} className="form-control" onChange={e => { setIC({...IC, DateIC:e.target.value}) }}/>
                      </div>
                      
                    </div>
                    <div className="border p-3 mb-3">
                      <h2>Entreprise</h2>
                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-4" >Société</label>
                        <div className="col-md-8">
                          <SelectSearch search="true" autoComplete="false" options={optionsCompany} value={IC.IdCompany}  placeholder="Choisir la société"  onChange={(value) => { setIC({...IC, IdCompany:value}) }} />
                        </div>
                      </div>
                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-4" >Représentant</label>
                        <div className="col-md-8 text-end">
                          <select className="form-control" onChange={e => { setIC({...IC, IdRepresentant:e.target.value})}} value={IC.IdRepresentant}>
                            <option value="0"></option>
                            {representants.map((d) =>(
                              <option key={d.id} value={d.id}>
                                  {d.FirstName} {d.Name} 
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >Une visite sur site a-t-elle été réalisée</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="VisiteRealisee_O_N" id="VisiteOui" value="1" checked={IC.VisiteRealisee_O_N==1?'checked':''} onChange={e => { setIC({...IC, VisiteRealisee_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="VisiteOui">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="VisiteRealisee_O_N" id="VisiteNon" value="0" checked={IC.VisiteRealisee_O_N==0?'checked':''} onChange={e => { setIC({...IC, VisiteRealisee_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="VisiteNon">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-4" >Travaux à réaliser</label>
                        <div className="col-md-8 text-end">
                          <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={TravauxARealiserOrig}
                                init={simpleConfig}
                                tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                                onEditorChange={handleChangeTravauxARealiser}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >Travaux entrant dans la liste des travaux à risques particuliers</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxRisquesParticuliers_O_N" id="TravauxRisquesParticuliers_O" value="1" checked={IC.TravauxRisquesParticuliers_O_N==1?'checked':''} onChange={e => { setIC({...IC, TravauxRisquesParticuliers_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxRisquesParticuliers_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxRisquesParticuliers_O_N" id="TravauxRisquesParticuliers_N" value="0" checked={IC.TravauxRisquesParticuliers_O_N==0?'checked':''} onChange={e => { setIC({...IC, TravauxRisquesParticuliers_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxRisquesParticuliers_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-6" >Effectif des salariés affectés (minimum et maximum)</label>
                        <div className="col-5 col-md-2 text-end">
                          <input type="text" className="form-control" value={IC.EffectifSalarieMin} onChange={e => { setIC({...IC, EffectifSalarieMin:e.target.value})}} />
                        </div>
                        <label className="col-2 col-md-1" >à</label>
                        <div className="col-5 col-md-2 text-end">
                          <input type="text" className="form-control" value={IC.EffectifSalarieMax} onChange={e => { setIC({...IC, EffectifSalarieMax:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-2" >Travaux sous traités </label>
                        <div className="col-md-3">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxSousTraites_O_N" id="TravauxSousTraites_O" value="1" checked={IC.TravauxSousTraites_O_N==1?'checked':''} onChange={e => { setIC({...IC, TravauxSousTraites_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxSousTraites_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxSousTraites_O_N" id="TravauxSousTraites_N" value="0" checked={IC.TravauxSousTraites_O_N==0?'checked':''} onChange={e => { setIC({...IC, TravauxSousTraites_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxSousTraites_N">Non</label>
                          </div>
                        </div>
                        <label className="col-md-2" >Si oui lesquels</label>
                        <div className="col-md-5">
                          <Editor
                                  onInit={(evt, editor) => editorRef.current = editor}
                                  initialValue={TravauxSsTraitesOrig}
                                  init={simpleConfig}
                                  tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                                  onEditorChange={handleChangeTravauxSousTraites}
                          />
                          
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-3" >Date d'intervention du</label>
                        <div className="col-5 col-md-4">
                          <input type="text" className="form-control" value={IC.DateInterventionDepart} onChange={e => { setIC({...IC, DateInterventionDepart:e.target.value})}} />
                        </div>
                        <label className="col-2 col-md-1 text-center" >au</label>
                        <div className="col-5 col-md-4">
                          <input type="text" className="form-control" value={IC.DateInterventionFin} onChange={e => { setIC({...IC, DateInterventionFin:e.target.value})}} />
                        </div>
                      
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-3" >Horaires de chantier</label>
                        <div className="col-5 col-md-2">
                          <input type="time" className="form-control" value={IC.HeureDepartChantier} onChange={e => { setIC({...IC, HeureDepartChantier:e.target.value})}} />
                        </div>
                        <label className="col-2 col-md-1 text-center" >à</label>
                        <div className="col-5 col-md-2">
                          <input type="time" className="form-control" value={IC.HeureFinChantier} onChange={e => { setIC({...IC, HeureFinChantier:e.target.value})}} />
                        </div>
                        <label className="col-6 col-md-1 text-md-end" >Nuit</label>
                        <div className="col-6 col-md-3 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravailNuit" id="TravailNuit_O" value="1" checked={IC.TravailNuit==1?'checked':''} onChange={e => { setIC({...IC, TravailNuit:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravailNuit_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravailNuit" id="TravailNuit_N" value="0" checked={IC.TravailNuit==0?'checked':''} onChange={e => { setIC({...IC, TravailNuit:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravailNuit_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >L'entreprise est elle en possession du PGCSPS</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="EntreprisePossessionPGCSPS_O_N" id="EntreprisePossessionPGCSPS_O" value="1" checked={IC.EntreprisePossessionPGCSPS_O_N==1?'checked':''} onChange={e => { setIC({...IC, EntreprisePossessionPGCSPS_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="EntreprisePossessionPGCSPS_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="EntreprisePossessionPGCSPS_O_N" id="EntreprisePossessionPGCSPS_N" value="0" checked={IC.EntreprisePossessionPGCSPS_O_N==0?'checked':''} onChange={e => { setIC({...IC, EntreprisePossessionPGCSPS_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="EntreprisePossessionPGCSPS_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >Le PGCSPS et le PPSPS de l'entreprise titulaire a-t-il été transmis au sous-traitant</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PGCSPS_TransmisSousTraitant" id="PGCSPS_TransmisSousTraitant_NA" value="2" checked={IC.PGCSPS_TransmisSousTraitant==2?'checked':''} onChange={e => { setIC({...IC, PGCSPS_TransmisSousTraitant:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PGCSPS_TransmisSousTraitant_NA">Sans objet</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PGCSPS_TransmisSousTraitant" id="PGCSPS_TransmisSousTraitant_O" value="1" checked={IC.PGCSPS_TransmisSousTraitant==1?'checked':''} onChange={e => { setIC({...IC, PGCSPS_TransmisSousTraitant:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PGCSPS_TransmisSousTraitant_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PGCSPS_TransmisSousTraitant" id="PGCSPS_TransmisSousTraitant_N" value="0" checked={IC.PGCSPS_TransmisSousTraitant==0?'checked':''} onChange={e => { setIC({...IC, PGCSPS_TransmisSousTraitant:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PGCSPS_TransmisSousTraitant_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-12 col-md-1" >PPSPS</label>
                        <label className="col-6 col-md-2" >requis</label>
                        <div className="col-6 col-md-9">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PPSPSRequis_O_N" id="PPSPSRequis_O" value="1" checked={IC.PPSPSRequis_O_N==1?'checked':''} onChange={e => { setIC({...IC, PPSPSRequis_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PPSPSRequis_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PPSPSRequis_O_N" id="PPSPSRequis_N" value="0" checked={IC.PPSPSRequis_O_N==0?'checked':''} onChange={e => { setIC({...IC, PPSPSRequis_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PPSPSRequis_N">Non</label>
                          </div>
                        </div>
                        <label className="offset-md-1 col-6 col-md-2" >à transmettre</label>
                        <div className="col-6 col-md-4">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PPSPSATransmettre_O_N" id="PPSPSATransmettre_O" value="1" checked={IC.PPSPSATransmettre_O_N==1?'checked':''} onChange={e => { setIC({...IC, PPSPSATransmettre_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PPSPSATransmettre_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PPSPSATransmettre_O_N" id="PPSPSATransmettre_N" value="0" checked={IC.PPSPSATransmettre_O_N==0?'checked':''} onChange={e => { setIC({...IC, PPSPSATransmettre_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PPSPSATransmettre_N">Non</label>
                          </div>
                        </div>
                        <label className="col-6 col-md-2 text-md-end" >remis le</label>
                        <div className="col-6 col-md-2">
                          <input type="date" className="form-control" value={IC.PPSPSRemisLe} onChange={e => { setIC({...IC, PPSPSRemisLe:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >L'entreprise a-t-elle un CHSCT</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CHSCT_O_N" id="CHSCT_O" value="1" checked={IC.CHSCT_O_N==1?'checked':''} onChange={e => { setIC({...IC, CHSCT_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="CHSCT_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CHSCT_O_N" id="CHSCT_N" value="0" checked={IC.CHSCT_O_N==0?'checked':''} onChange={e => { setIC({...IC, CHSCT_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="CHSCT_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-3 alternate-color">
                        <label className="col-md-8" >L'entreprise a-t-elle un secouriste sur le chantier</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="SecouristeChantier_O_N" id="SecouristeChantier_O" value="1" checked={IC.SecouristeChantier_O_N==1?'checked':''} onChange={e => { setIC({...IC, SecouristeChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="SecouristeChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="SecouristeChantier_O_N" id="SecouristeChantier_N" value="0" checked={IC.SecouristeChantier_O_N==0?'checked':''} onChange={e => { setIC({...IC, SecouristeChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="SecouristeChantier_N">Non</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border p-3 mb-3">
                      <h2>Hygiène et organisation de chantier</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-8" >Téléphone de chantier / téléphone portable</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TelChantier_O_N" id="TelChantier_O" value="1" checked={IC.TelChantier_O_N==1?'checked':''} onChange={e => { setIC({...IC, TelChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TelChantier_O_N" id="TelChantier_N" value="0" checked={IC.TelChantier_O_N==0?'checked':''} onChange={e => { setIC({...IC, TelChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-8" >Cantonnements propres à chaque lot</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CantonnementPropre_O_N" id="CantonnementPropre_O" value="1" checked={IC.CantonnementPropre_O_N==1?'checked':''} onChange={e => { setIC({...IC, CantonnementPropre_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="CantonnementPropre_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CantonnementPropre_O_N" id="CantonnementPropre_N" value="0" checked={IC.CantonnementPropre_O_N==0?'checked':''} onChange={e => { setIC({...IC, CantonnementPropre_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="CantonnementPropre_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-4" >Locaux communs prévus à la charge de </label>
                        <div className="col-md-8">
                          <input type="text" className="form-control" value={IC.LocauxCommunsChargeDe} onChange={e => { setIC({...IC, LocauxCommunsChargeDe:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="offset-1 col-md-2" >Sanitaires</label>
                        <div className="col-md-9 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Sanitaire_O_N" id="Sanitaire_O" value="1" checked={IC.Sanitaire_O_N==1?'checked':''} onChange={e => { setIC({...IC, Sanitaire_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Sanitaire_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Sanitaire_O_N" id="Sanitaire_N" value="0" checked={IC.Sanitaire_O_N==0?'checked':''} onChange={e => { setIC({...IC, Sanitaire_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Sanitaire_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="offset-1 col-md-2" >Vestiaires</label>
                        <div className="col-md-9 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Vestiaires_O_N" id="Vestiaires_O" value="1" checked={IC.Vestiaires_O_N==1?'checked':''} onChange={e => { setIC({...IC, Vestiaires_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Vestiaires_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Vestiaires_O_N" id="Vestiaires_N" value="0" checked={IC.Vestiaires_O_N==0?'checked':''} onChange={e => { setIC({...IC, Vestiaires_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Vestiaires_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="offset-1 col-md-2" >Réfectoires</label>
                        <div className="col-md-9 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Réfectoires_O_N" id="Réfectoires_O" value="1" checked={IC.Réfectoires_O_N==1?'checked':''} onChange={e => { setIC({...IC, Réfectoires_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Réfectoires_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Réfectoires_O_N" id="Réfectoires_N" value="0" checked={IC.Réfectoires_O_N==0?'checked':''} onChange={e => { setIC({...IC, Réfectoires_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Réfectoires_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="offset-1 col-md-2" >Douches</label>
                        <div className="col-md-9 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Douches_O_N" id="Douches_O" value="1" checked={IC.Douches_O_N==1?'checked':''} onChange={e => { setIC({...IC, Douches_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Sanitaire_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Douches_O_N" id="Douches_N" value="0" checked={IC.Douches_O_N==0?'checked':''} onChange={e => { setIC({...IC, Douches_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Sanitaire_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Local de stockage</label>
                        <div className="col-md-10 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="LocalStockage_O_N" id="LocalStockage_O" value="1" checked={IC.LocalStockage_O_N==1?'checked':''} onChange={e => { setIC({...IC, LocalStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="LocalStockage_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="LocalStockage_O_N" id="LocalStockage_N" value="0" checked={IC.LocalStockage_O_N==0?'checked':''} onChange={e => { setIC({...IC, LocalStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="LocalStockage_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Aire de stockage</label>
                        <div className="col-md-10 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="AireStockage_O_N" id="AireStockage_O" value="1" checked={IC.AireStockage_O_N==1?'checked':''} onChange={e => { setIC({...IC, AireStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="AireStockage_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="AireStockage_O_N" id="AireStockage_N" value="0" checked={IC.AireStockage_O_N==0?'checked':''} onChange={e => { setIC({...IC, AireStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="AireStockage_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Localisation</label>
                        <div className="col-md-10">
                          <input type="text" className="form-control" value={IC.LocalisationStockage} onChange={e => { setIC({...IC, LocalisationStockage:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Plan d'installation de chantier prévu</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PlanChantier_O_N" id="PlanChantier_O" value="1" checked={IC.PlanChantier_O_N==1?'checked':''} onChange={e => { setIC({...IC, PlanChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PlanChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PlanChantier_O_N" id="PlanChantier_N" value="0" checked={IC.PlanChantier_O_N==0?'checked':''} onChange={e => { setIC({...IC, PlanChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PlanChantier_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Contrôle d'accès à prévoir sur le chantier</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ControleAccesChantier_O_N" id="ControleAccesChantier_O" value="1" checked={IC.ControleAccesChantier_O_N==1?'checked':''} onChange={e => { setIC({...IC, ControleAccesChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ControleAccesChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ControleAccesChantier_O_N" id="ControleAccesChantier_N" value="0" checked={IC.ControleAccesChantier_O_N==0?'checked':''} onChange={e => { setIC({...IC, ControleAccesChantier_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ControleAccesChantier_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Mise en place ou utilisation d'installation électrique de chantier</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="InstallationElectrique_O_N" id="InstallationElectrique_O" value="1" checked={IC.InstallationElectrique_O_N==1?'checked':''} onChange={e => { setIC({...IC, InstallationElectrique_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="InstallationElectrique_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="InstallationElectrique_O_N" id="InstallationElectrique_N" value="0" checked={IC.InstallationElectrique_O_N==0?'checked':''} onChange={e => { setIC({...IC, InstallationElectrique_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="InstallationElectrique_N">Non</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border p-3 mb-3">
                      <h2>Livraisons et manutentions</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Type de livraison</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeLivraison_1" value="1" onChange={e => {handleTypeLivraison(e)}} checked={IC.TypeLivraison.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeLivraison_1">Semi</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeLivraison_2" value="2" onChange={e => {handleTypeLivraison(e)}} checked={IC.TypeLivraison.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeLivraison_2">Camion auto-déchargeable</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeLivraison_3" value="3" onChange={e => {handleTypeLivraison(e)}} checked={IC.TypeLivraison.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeLivraison_3">Toupie béton</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeLivraison_4" value="4" onChange={e => {handleTypeLivraison(e)}} checked={IC.TypeLivraison.indexOf("4")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeLivraison_4">Camionnette</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Type de manutention</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_1" value="1" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_1">Grue à tour</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_5" value="5" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("5")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_5">Dumper</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_9" value="9" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("9")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_9">Tracs</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_13" value="13" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("13")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_13">Pelleuse</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_17" value="17" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("17")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_17">Camion benne</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_2" value="2" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_2">Grue mobile</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_6" value="6" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("6")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_6">Chariot élévateur</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_10" value="10" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("10")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_10">Niveleuse</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_14" value="14" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("14")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_14">Mini pelle</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_18" value="18" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("18")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_18">Raboteuse</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_3" value="3" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_3">Monte matériaux</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_7" value="7" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("7")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_7">Treuils</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_11" value="11" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("11")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_11">Monte-charges</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_15" value="15" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("15")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_15">Chargeur</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_19" value="19" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("19")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_19">Finisseur</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_4" value="4" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("4")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_4">Manuelle</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_8" value="8" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("8")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_8">Transpalette</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_12" value="12" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("12")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_12">Lift</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_16" value="16" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("16")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_16">Manuscopique</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="TypeManutention_20" value="20" onChange={e => {handleTypeManutention(e)}} checked={IC.TypeManutention.indexOf("20")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="TypeManutention_20">Compacteur</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border p-3 mb-3">
                      <h2>Travaux en hauteur - moyens utilisés</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Echafaudages</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Echafaudages_1" value="1" onChange={e => {handleEchafaudages(e)}} checked={IC.Echafaudages.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Echafaudages_1">Roulants</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Echafaudages_2" value="2" onChange={e => {handleEchafaudages(e)}} checked={IC.Echafaudages.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Echafaudages_2">De pied</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Echafaudages_3" value="3" onChange={e => {handleEchafaudages(e)}} checked={IC.Echafaudages.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Echafaudages_3">Volants</label>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Montage</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Montage_1" value="1" onChange={e => {handleMontage(e)}} checked={IC.Montage.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Montage_1">Interne à l'entreprise</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Montage_2" value="2" onChange={e => {handleMontage(e)}} checked={IC.Montage.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Montage_2">Entreprise extérieure</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Montage_3" value="3" onChange={e => {handleMontage(e)}} checked={IC.Montage.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Montage_3">PC réception montage / démontage</label>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Plate-forme individuelle</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="PlateformeIndividuelle_1" value="1" onChange={e => {handlePlateformeIndividuelle(e)}} checked={IC.PlateformeIndividuelle.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="PlateformeIndividuelle_1">PIR</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="PlateformeIndividuelle_2" value="2" onChange={e => {handlePlateformeIndividuelle(e)}} checked={IC.PlateformeIndividuelle.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="PlateformeIndividuelle_2">Plate-forme élévatrice</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="PlateformeIndividuelle_3" value="3" onChange={e => {handlePlateformeIndividuelle(e)}} checked={IC.PlateformeIndividuelle.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="PlateformeIndividuelle_3">Plate-forme en encorbellement</label>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Nacelles</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Nacelles_1" value="1" onChange={e => {handleNacelles(e)}} checked={IC.Nacelles.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Nacelles_1">Téléscopique</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Nacelles_2" value="2" onChange={e => {handleNacelles(e)}} checked={IC.Nacelles.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Nacelles_2">Elévatrice (ciseaux)</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Nacelles_3" value="3" onChange={e => {handleNacelles(e)}} checked={IC.Nacelles.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="Nacelles_3">Boom</label>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-2" >Autres équipements</label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="AutresEquipements_1" value="1" onChange={e => {handleAutresEquipements(e)}} checked={IC.AutresEquipements.indexOf("1")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="AutresEquipements_1">Filets en sous face antichute</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="AutresEquipements_4" value="4" onChange={e => {handleAutresEquipements(e)}} checked={IC.AutresEquipements.indexOf("4")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="AutresEquipements_4">Filets anti chute d'objets</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="AutresEquipements_2" value="2" onChange={e => {handleAutresEquipements(e)}} checked={IC.AutresEquipements.indexOf("2")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="AutresEquipements_2">Garde-corps provisoires</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="AutresEquipements_5" value="5" onChange={e => {handleAutresEquipements(e)}} checked={IC.AutresEquipements.indexOf("5")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="AutresEquipements_5">Pare gravats</label>
                              </div>
                             
                            </div>
                            <div className="col-md-4">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="AutresEquipements_3" value="3" onChange={e => {handleAutresEquipements(e)}} checked={IC.AutresEquipements.indexOf("3")>=0?"checked":""}/>
                                <label className="form-check-label" htmlFor="AutresEquipements_3">Harnais de sécurité</label>
                              </div>
                              
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border p-3 mb-3">
                      <h2>Dispositions particulières</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-3" >Permis spéciaux</label>
                        <div className="col-md-3 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PermisSpeciaux_O_N" id="PermisSpeciaux_O" value="1" checked={IC.PermisSpeciaux_O_N==1?'checked':''} onChange={e => { setIC({...IC, PermisSpeciaux_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PermisSpeciaux_O_N" id="PermisSpeciaux_N" value="0" checked={IC.PermisSpeciaux_O_N==0?'checked':''} onChange={e => { setIC({...IC, PermisSpeciaux_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_N">Non</label>
                          </div>
                        </div>
                        <label className="col-md-4" >Caces & Autorisation de conduite</label>
                        <div className="col-md-2 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Caces_O_N" id="Caces_O" value="1" checked={IC.Caces_O_N==1?'checked':''} onChange={e => { setIC({...IC, Caces_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Caces_O_N" id="Caces_N" value="0" checked={IC.Caces_O_N==0?'checked':''} onChange={e => { setIC({...IC, Caces_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-3" >Levage sous-loué</label>
                        <div className="col-md-3 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="LevageSousLoue_O_N" id="LevageSousLoue_O" value="1" checked={IC.LevageSousLoue_O_N==1?'checked':''} onChange={e => { setIC({...IC, LevageSousLoue_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="LevageSousLoue_O_N" id="LevageSousLoue_N" value="0" checked={IC.LevageSousLoue_O_N==0?'checked':''} onChange={e => { setIC({...IC, LevageSousLoue_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TelChantier_N">Non</label>
                          </div>
                        </div>
                        <label className="col-md-1" >Société</label>
                        <div className="col-md-5">
                          <input type="text" className="form-control" value={IC.LevageSociete} onChange={e => { setIC({...IC, LevageSociete:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Travaux par points chauds : nécessité d’établir un permis feu</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxPointsChauds" id="TravauxPointsChauds_O" value="1" checked={IC.TravauxPointsChauds==1?'checked':''} onChange={e => { setIC({...IC, TravauxPointsChauds:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxPointsChauds_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxPointsChauds" id="TravauxPointsChauds_N" value="0" checked={IC.TravauxPointsChauds==0?'checked':''} onChange={e => { setIC({...IC, TravauxPointsChauds:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxPointsChauds_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxPointsChauds" id="TravauxPointsChauds_NA" value="2" checked={IC.TravauxPointsChauds==2?'checked':''} onChange={e => { setIC({...IC, TravauxPointsChauds:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxPointsChauds_NA">Sans Objet</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Extincteurs aux postes de travail à prévoir</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PrevoirExtincteurs" id="PrevoirExtincteurs_O" value="1" checked={IC.PrevoirExtincteurs==1?'checked':''} onChange={e => { setIC({...IC, PrevoirExtincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PrevoirExtincteurs_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PrevoirExtincteurs" id="PrevoirExtincteurs_N" value="0" checked={IC.PrevoirExtincteurs==0?'checked':''} onChange={e => { setIC({...IC, PrevoirExtincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PrevoirExtincteurs_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PrevoirExtincteurs" id="PrevoirExtincteurs_NA" value="2" checked={IC.PrevoirExtincteurs==2?'checked':''} onChange={e => { setIC({...IC, PrevoirExtincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PrevoirExtincteurs_NA">Sans Objet</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-8" >Travaux en milieu confiné (vide sanitaire ; égouts ; galerie technique): agent de surface à prévoir  </label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxMilieuConfine" id="TravauxMilieuConfine_O" value="1" checked={IC.TravauxMilieuConfine==1?'checked':''} onChange={e => { setIC({...IC, TravauxMilieuConfine:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxMilieuConfine" id="TravauxMilieuConfine_N" value="0" checked={IC.TravauxMilieuConfine==0?'checked':''} onChange={e => { setIC({...IC, TravauxMilieuConfine:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="TravauxMilieuConfine" id="TravauxMilieuConfine_NA" value="2" checked={IC.TravauxMilieuConfine==2?'checked':''} onChange={e => { setIC({...IC, TravauxMilieuConfine:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_NA">Sans Objet</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Exposition à des micro-particules</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="MicroParticules_O_N" id="MicroParticules_O" value="1" checked={IC.MicroParticules_O_N==1?'checked':''} onChange={e => { setIC({...IC, MicroParticules_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="MicroParticules_O_N" id="MicroParticules_N" value="0" checked={IC.MicroParticules_O_N==0?'checked':''} onChange={e => { setIC({...IC, MicroParticules_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Situation sanitaire spécifique</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="SitutationSanitaireSpecifique_O_N" id="SitutationSanitaireSpecifique_O" value="1" checked={IC.SitutationSanitaireSpecifique_O_N==1?'checked':''} onChange={e => { setIC({...IC, SitutationSanitaireSpecifique_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="SitutationSanitaireSpecifique_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="SitutationSanitaireSpecifique_O_N" id="SitutationSanitaireSpecifique_N" value="0" checked={IC.SitutationSanitaireSpecifique_O_N==0?'checked':''} onChange={e => { setIC({...IC, SitutationSanitaireSpecifique_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="SitutationSanitaireSpecifique_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Dechet nécessitant un traitement spécifique</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="DechetTraitementSpecifique" id="DechetTraitementSpecifique_O" value="1" checked={IC.DechetTraitementSpecifique==1?'checked':''} onChange={e => { setIC({...IC, DechetTraitementSpecifique:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="DechetTraitementSpecifique_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="DechetTraitementSpecifique" id="DechetTraitementSpecifique_N" value="0" checked={IC.DechetTraitementSpecifique==0?'checked':''} onChange={e => { setIC({...IC, DechetTraitementSpecifique:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="DechetTraitementSpecifique_N">Non</label>
                          </div>
                        </div>
                      </div>

                    </div>

                    
                    

                    <div className="border p-3 mb-3">
                      <h2>Mesures complémentaires</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-9" >Appliquer les mesures d'organisation indiquées dans le PGC</label>
                        <div className="col-md-3 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="AppliquerMesuresPGC_O_N" id="AppliquerMesuresPGC_O" value="1"  checked={IC.AppliquerMesuresPGC_O_N==1?'checked':''} onChange={e => { setIC({...IC, AppliquerMesuresPGC_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="AppliquerMesuresPGC_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="AppliquerMesuresPGC_O_N" id="AppliquerMesuresPGC_N" value="0"  checked={IC.AppliquerMesuresPGC_O_N==0?'checked':''} onChange={e => { setIC({...IC, AppliquerMesuresPGC_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="AppliquerMesuresPGC_N">Non</label>
                          </div>
                        </div>
                        
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-9" >Le port des EPI est obligatoire</label>
                        <div className="col-md-3 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="EPIObligatoire_O_N" id="EPIObligatoire_O" value="1"  checked={IC.EPIObligatoire_O_N==1?'checked':''} onChange={e => { setIC({...IC, EPIObligatoire_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="EPIObligatoire_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="EPIObligatoire_O_N" id="EPIObligatoire_N" value="0"  checked={IC.EPIObligatoire_O_N==0?'checked':''} onChange={e => { setIC({...IC, EPIObligatoire_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="EPIObligatoire_N">Non</label>
                          </div>
                        </div>
                        
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Balisage des zones de travaux et stockage</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="BalisageTravauxStockage_O_N" id="BalisageTravauxStockage_O" value="1"  checked={IC.BalisageTravauxStockage_O_N==1?'checked':''} onChange={e => { setIC({...IC, BalisageTravauxStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="BalisageTravauxStockage_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="BalisageTravauxStockage_O_N" id="BalisageTravauxStockage_N" value="0"  checked={IC.BalisageTravauxStockage_O_N==0?'checked':''} onChange={e => { setIC({...IC, BalisageTravauxStockage_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="BalisageTravauxStockage_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Mise à disposition sur le site Le PPSPS</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="DispoPPSPS_O_N" id="DispoPPSPS_O" value="1" checked={IC.DispoPPSPS_O_N==1?'checked':''} onChange={e => { setIC({...IC, DispoPPSPS_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="DispoPPSPS_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="DispoPPSPS_O_N" id="DispoPPSPS_N" value="0" checked={IC.DispoPPSPS_O_N==0?'checked':''} onChange={e => { setIC({...IC, DispoPPSPS_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="DispoPPSPS_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-8" >Le Classeur de registre du personnel</label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ClasseurRegistrePersonnel_O_N" id="ClasseurRegistrePersonnel_O" value="1"  checked={IC.ClasseurRegistrePersonnel_O_N==1?'checked':''} onChange={e => { setIC({...IC, ClasseurRegistrePersonnel_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ClasseurRegistrePersonnel_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ClasseurRegistrePersonnel_O_N" id="ClasseurRegistrePersonnel_N" value="0" checked={IC.ClasseurRegistrePersonnel_O_N==0?'checked':''} onChange={e => { setIC({...IC, ClasseurRegistrePersonnel_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ClasseurRegistrePersonnel_N">Non</label>
                          </div>
                          
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Présence obligatoire des cartes de PRO BTP de chaque intervenant</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CartesProBTP_O_N" id="CartesProBTP_O" value="1" checked={IC.CartesProBTP_O_N==1?'checked':''} onChange={e => { setIC({...IC, CartesProBTP_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="TravauxMilieuConfine_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="CartesProBTP_O_N" id="CartesProBTP_N" value="0" checked={IC.CartesProBTP_O_N==0?'checked':''} onChange={e => { setIC({...IC, CartesProBTP_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="CartesProBTP_N">Non</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-12" >Travaux par points chauds</label>
                        
                        <label className="col-md-6">Permis feu</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PermisFeu" id="PermisFeu_O" value="1" checked={IC.PermisFeu==1?'checked':''} onChange={e => { setIC({...IC, PermisFeu:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PermisFeu_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PermisFeu" id="PermisFeu_N" value="0" checked={IC.PermisFeu==0?'checked':''} onChange={e => { setIC({...IC, PermisFeu:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PermisFeu_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="PermisFeu" id="PermisFeu_NA" value="2" checked={IC.PermisFeu==2?'checked':''} onChange={e => { setIC({...IC, PermisFeu:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="PermisFeu_NA">Sans objet</label>
                          </div>
                        </div>
                        <label className="col-md-6">Extincteurs</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Extincteurs" id="Extincteurs_O" value="1" checked={IC.Extincteurs==1?'checked':''} onChange={e => { setIC({...IC, Extincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Extincteurs_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Extincteurs" id="Extincteurs_N" value="0" checked={IC.Extincteurs==0?'checked':''} onChange={e => { setIC({...IC, Extincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Extincteurs_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="Extincteurs" id="Extincteurs_NA" value="2" checked={IC.Extincteurs==2?'checked':''} onChange={e => { setIC({...IC, Extincteurs:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="Extincteurs_NA">Sans objet</label>
                          </div>
                        </div>

                        <label className="col-md-6">Bouteilles de gaz sur chariot adaptable ou attachées</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="BouteilleGaz" id="BouteilleGaz_O" value="1" checked={IC.BouteilleGaz==1?'checked':''} onChange={e => { setIC({...IC, BouteilleGaz:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="BouteilleGaz_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="BouteilleGaz" id="BouteilleGaz_N" value="0" checked={IC.BouteilleGaz==0?'checked':''} onChange={e => { setIC({...IC, BouteilleGaz:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="BouteilleGaz_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="BouteilleGaz" id="BouteilleGaz_NA" value="2" checked={IC.BouteilleGaz==2?'checked':''} onChange={e => { setIC({...IC, BouteilleGaz:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="BouteilleGaz_NA">Sans objet</label>
                          </div>
                        </div>

                        <label className="col-md-6">Arrêt des travaux par point chaud 2 heures avant de quitter le chantier avec surveillance pendant ces 2 heures</label>
                        <div className="col-md-6 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ArretTravauxPC" id="ArretTravauxPC_O" value="1" checked={IC.ArretTravauxPC==1?'checked':''} onChange={e => { setIC({...IC, ArretTravauxPC:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ArretTravauxPC_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ArretTravauxPC" id="ArretTravauxPC_N" value="0" checked={IC.ArretTravauxPC==0?'checked':''} onChange={e => { setIC({...IC, ArretTravauxPC:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ArretTravauxPC_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ArretTravauxPC" id="ArretTravauxPC_NA" value="2" checked={IC.ArretTravauxPC==2?'checked':''} onChange={e => { setIC({...IC, ArretTravauxPC:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ArretTravauxPC_NA">Sans objet</label>
                          </div>
                        </div>
                      </div>

                     

                    </div>
                    
                    <div className="border p-3 mb-3">
                      <h2>Obligations de l'entreprise</h2>
                      <div className="form-group row alternate-color">
                        <label className="col-md-5" >Obligation de formation des travailleurs de l’entreprise</label>
                        <div className="col-md-2 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ObligationFormation_O_N" id="ObligationFormation_O" value="1" checked={IC.ObligationFormation_O_N==1?'checked':''} onChange={e => { setIC({...IC, ObligationFormation_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ObligationFormation_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="ObligationFormation_O_N" id="ObligationFormation_N" value="0" checked={IC.ObligationFormation_O_N==0?'checked':''} onChange={e => { setIC({...IC, ObligationFormation_O_N:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="ObligationFormation_N">Non</label>
                          </div>
                        </div>
                        <label className={IC.ObligationFormation_O_N>0?"col-md-2":"d-none"} >Si oui lesquelles</label>
                        <div className={IC.ObligationFormation_O_N>0?"col-md-3 text-end":"d-none"}>
                          <input type="text" className="form-control" value={IC.ObligationFormationLibelle} onChange={e => { setIC({...IC, ObligationFormationLibelle:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-6" >Obligation de surveillance médicale - surveillance(s) particulière(s)</label>
                        <div className="col-md-6">
                          <input type="text" className="form-control" value={IC.ObligationSurveillanceMedicale} onChange={e => { setIC({...IC, ObligationSurveillanceMedicale:e.target.value})}} />
                        </div>
                      </div>

                      <div className="form-group row alternate-color">
                        <label className="col-md-8" ><strong>Nécessité de participer au CISSCT</strong></label>
                        <div className="col-md-4 text-end">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="NecessiteParticiperCISSCT" id="NecessiteParticiperCISSCT_O" value="1" checked={IC.NecessiteParticiperCISSCT==1?'checked':''} onChange={e => { setIC({...IC, NecessiteParticiperCISSCT:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="NecessiteParticiperCISSCT_O">Oui</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="NecessiteParticiperCISSCT" id="NecessiteParticiperCISSCT_N" value="0" checked={IC.NecessiteParticiperCISSCT==0?'checked':''} onChange={e => { setIC({...IC, NecessiteParticiperCISSCT:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="NecessiteParticiperCISSCT_N">Non</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="NecessiteParticiperCISSCT" id="NecessiteParticiperCISSCT_NA" value="2" checked={IC.NecessiteParticiperCISSCT==2?'checked':''} onChange={e => { setIC({...IC, NecessiteParticiperCISSCT:e.target.value})}}/>
                            <label className="form-check-label" htmlFor="NecessiteParticiperCISSCT_NA">Sans Objet</label>
                          </div>
                        </div>
                      </div>
                      <div className="border p-3 mb-3">
                        <label className="col-md-8" >Rappel du coordonateur</label>
                        <ul>
                          <li>Respect du PGCSPS</li>
                          <li>Aucune modification du programme travaux décrit dans le dossier de consultation porté à notre connaissance.</li>
                          <li>Fournir le plan d’installation de chantier.</li>
                          <li>Fournir le PPSPS précisant les modes opératoires et les mesures de prévention liées aux risques.</li>
                          <li>Appliquer les mesures d’organisation indiquées dans le PGC et les mesures de prévention du PPSPS (PGC fourni par la Maîtrise d’Oeuvre).</li>
                          <li>Respecter les principes généraux de prévention.</li>
                          <li>Privilégier les protections collectives aux protections individuelles et les moyens de levage mécanique aux manutentions manuelles.</li>
                          <li>Port des EPI obligatoire, notamment adapté à la présence de microparticules.</li>
                          <li>Le travail dans les zones empoussiérées devra se faire avec une protection pour les voies respiratoires avec cartouche filtrante ou aspiration intégrée.</li>
                          <li>Amiante, Plomb, Pollution de sol et Légionellose : fournir mode opératoire, transmettre le plan de retrait à l’inspection du travail, Carsat et OPPBTP et justifier habilitations du personnel intervenant et leur suivi médical.</li>
                          <li>Démolitions-Terrassements et VRD : DICT et réponses des concessionnaires et autres administrations diverses à fournir.</li>
                          <li>Pas de restauration sur les zones de travaux, utiliser les zones prévues à cet effet (base-vie ou autre).</li>
                          <li>La gestion des déchets par benne collective/benne propre à l’entreprise ou évacuation quotidienne.</li>
                          <li>Toute intervention à plus d’un mètre de hauteur doit se faire sous la protection d’un garde-corps, le risque de chute est considéré à partir d’un mètre.</li>
                          <li>Tout travail en hauteur doit se faire depuis un plan de travail stable et protégé (gazelle ou échafaudage), l’usage d’échelle et d’escabeaux est à proscrire, une exception pourra être tolérée pour les tâches ponctuelles non répétitives. Maintenir et respecter obligatoirement les protections collectives.</li>
                          <li>Vérification électrique des installations de chantier et de la base vie à fournir.</li>
                          <li>Rapports de consignation et de déconsignation des réseaux électriques et de gaz des zones de travaux à fournir.</li>
                          <li>Les machines devront être équipées d’une coupure locale avec cadenas et situées dans une enceinte non accessible aux personnes non autorisées.</li>
                          <li>Aération des zones de travaux impérative.</li>
                          <li>Tout le personnel devra être facilement identifiable (t-shirt de société, badge, casque de société et carte BTP).</li>
                          <li>Tout le personnel devra avoir sur lui une pièce d’identité la preuve de la légalité de sa présence sur le sol français et une preuve d’affiliation à sa société.</li>
                          <li>Tous les conducteurs d’engin devront avoir sur eux leur habilitation de conduite (tenir à jour sur site un classeur regroupant les vérifications périodiques du matériel utilisé, Caces et autorisations de conduite).</li>
                          <li>Prévoir un homme trafic lors des entrées et des sorties de matériaux, matériels, d’engins et de camions.</li>
                          <li>Prévoir chef de manœuvre pour tous chargements et déchargements (grue, engins, camions, etc…)</li>
                          <li>Prévoir une trousse de premiers soins et un extincteur sur site.</li>
                          <li>Prévoir un sécuriste dans chaque équipe et un extincteur à proximité des travaux par points chauds ou engendrant des projections incandescentes.</li>
                          <li>Aucun travailleur isolé n’est autorisé et maintenir en bon ordre les zones de travail, de circulation et de cantonnement.</li>
                        </ul>

                      </div>
                      <div className="border p-3 mb-3">
                        <h2>Remarques</h2>
                        <Editor
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={RemarqueOrig}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                              'searchreplace', 'visualblocks', 'code', 'fullscreen',
                              'insertdatetime', 'media', 'table', 'help', 'wordcount'
                            ],
                            toolbar: 'formatselect | ' +
                            'bold italic underline backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                            'removeformat | help',
                          }}
                          tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                          onEditorChange={handleChangeRemarque}
                        />
                      </div>
                      <div className="form-group row alternate-color">
                        <div className="col-md-4">
                          <div className="border me-md-2 mb-2 p-2 pos-relative bg-white">
                            <label className="mb-2">Coordonateur</label>
                            <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner('coordonateur')}> Signer</button>
                            {IC.SignatureCoordonateur !== "" ? <img onClick={() => handleSigner('controller')} src={IC.SignatureCoordonateur} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner('coordonateur')}>Aucune signature</label>}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border me-md-2 mb-2 p-2 pos-relative bg-white">
                            <label className="mb-2">Représentant entreprise</label>
                            <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner('representant')}> Signer</button>
                            {IC.SignatureRepresentant !== "" ? <img onClick={() => handleSigner('representant')} src={IC.SignatureRepresentant} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner('representant')}>Aucune signature</label>}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border me-md-2 mb-2 p-2 pos-relative bg-white">
                            <label className="mb-2">Autre participant</label><input type="text" className="form-control" value={IC.AutreParticipant} onChange={e => { setIC({...IC, AutreParticipant:e.target.value})}} />
                            <br /><input type="text" className="form-control" placeholder="Nom et prénom" value={IC.NomAutreParticipant} onChange={e => { setIC({...IC, NomAutreParticipant:e.target.value})}} />
                            <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner('autre_participant')}> Signer</button>
                            {IC.SignatureAutreParticipant !== "" ? <img onClick={() => handleSigner('autre_participant')} src={IC.SignatureAutreParticipant} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner('autre_participant')}>Aucune signature</label>}
                          </div>
                        </div>
                      </div>

                    </div>
                    

                  </div>
                </div>
                
              </form>
              
              <ModalSearchSite showModal={stateModalSearchSite} setSiteId={setSiteId} handleClosed={() => setStateSearchSite(false)}/>
              <Modal show={showSignature} onHide={handleClosedSignature} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Signature</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <SignatureCanvas canvasProps={{height: 500, className: 'sigCanvas'}} ref={(ref) => { sigCanvas = ref }} />
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedSignature}>Annuler</button>
                      <button type="button" className="btn btn-primary" onClick={handleValideSignature}>Valider</button>
                  </Modal.Footer>
              </Modal>
            </div>
            <div id="onglet2" className={ongletEdit==2 ? 'onglet d-block': 'onglet d-none'}>
              <div className="container-xxl mt-2">
                <Tabs defaultActiveKey="content" id="uncontrolled-tab-example" className="mb-3" >
                  <Tab eventKey="content" title="Contenu">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={RapportOriginal}
                        init={{
                          height: 500,
                          menubar: true,
                          plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                            'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'help', 'wordcount'
                          ],
                          toolbar: 'formatselect | ' +
                          'bold italic underline backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                          'removeformat | help',
                        }}
                        tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                        onEditorChange={handleChangeRapport}
                      />
                  </Tab>
                  <Tab eventKey="diffusion" title="Liste de diffusion">
                    <RecipientsList contactsList={listContacts} chooseContact={true} setRecipients={setChooseContacts}/>
                  </Tab>
                </Tabs>
                <div className="text-center py-2">
                  <button type="button" className="btn btn-primary" onClick={handleSendRapport}>Envoyer</button>
                </div>
              </div>
            </div>
            
            <div className="row footerToolbar px-0">
              <div className="col text-center px-0"><button className={ongletEdit==1 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(1)}}><FontAwesomeIcon icon={faPencil} /></button></div>
              <div className="col text-center px-0"><button className={ongletEdit==2 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(2)}}><FontAwesomeIcon icon={faPaperPlane} /></button></div>
            </div>
          </div>
        );
        break;
      default:
        if(!props.idIC)
        {
          var liste_vide = "";
          if(ICs.length == 0)
          {
            liste_vide = <div className="lineEmptyTab">Aucune Inspection Commune</div>
          }
          return (
            <div className="containerxl" >
              <div className="row headScreen">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
                <div className="col-6"><h1 className="h4 py-3">Inspections communes <OfflineIco /></h1></div>
                <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAddIC}><FontAwesomeIcon icon={faPlus} /></button></div>
              </div>
              <SPSLoader load={loading} />
              <div className={synchroState > 0?"synchro_bar active":"synchro_bar"}>Synchronisation en cours <FontAwesomeIcon icon={faRotate} /></div>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="px-0">
                      <ul className="container">
                        {ICs.map((r) =>(
                        <li className={r.local? "row mb-2 alternate-color bg-warning-subtle py-2":  "row mb-2 alternate-color py-2"} key={r.id}  >
                            <div className="d-none">{r.id}</div>
                            <div className="col-12 col-md-7" onClick={() => handleClickIC(r.id)}>{r.local?<span className="text-warning me-3"><FontAwesomeIcon icon={faTriangleExclamation} /></span>:""}{r.site_Title!= undefined?r.site_Title+" "+r.site_Zip+" "+r.site_City: SPSSiteLabelLocal(r.IdSite)} {r.company_Name}</div>
                            <div className="col-8 col-md-4 text-start font-mobile-sm" onClick={() => handleClickIC(r.id)}>
                              {datetime_to_fr(r.DateIC).date} {(r.SentDate=="0000-00-00 00:00:00" || r.SentDate==undefined || r.SentDate=='') ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}
                            </div>
                            <div className="col-4 col-md-1 text-end">
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteIC(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                            </div>
                        </li>
                        ))}
                      </ul>
                      {liste_vide}
                      <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAddIC}>Créer une Inspection Commune</button></div>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row footerToolbar px-0">
                <div className="col-6 text-center px-2">
                  <select className="form-select text-center border-0 my-2" onChange={e => { SetFilter(e.target.value); }} value={currentFilter}>
                    <option value="MY">Mes Inspections en cours</option>
                    <option value="ALLMY">Toutes mes inspections</option>
                    <option value="ALLCURRENT">Toutes les inspections en cours</option>
                    <option value="ALL">Toutes les inspections</option>
                  </select>
                </div>
                <div className="col-6 text-center px-2">
                  <div className="input-group my-2">
                    <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    <input type="text" className="form-control" value={currentSearchFilter} placeholder="Opération, Code postal..." aria-label="Username" aria-describedby="basic-addon1"  onChange={e => { SetSearchFilter(e.target.value); }}/>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else
          return('');
        break;
    }
  
}

export default InspectionCommune;
