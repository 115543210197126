import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faShare, faTrash, faPencil, faAddressBook, faLightbulb, faMagnifyingGlass, faFileLines, faDownload, faCheck, faPaperPlane, faCopy, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {SPSGetSite, SPSSaveSite, SPSSites} from '../functions/SPSSites';
import { SPSUserQuickList } from '../functions/SPSUsers';
import { SPSCompanies } from '../functions/SPSCompany';
import { SPSSearchContact } from '../functions/SPSContact';
import { SPSGetSitePGCs, SPSGetSiteDIUOs, SPSGetSiteInvitCISSCTs, SPSGetSitePVCISSCTs, SPSGetSiteCouriers, SPSMakeCourier, SPSSaveCourier, SPSGetCourier, SPSSendCourier, SPSDeleteCourier, SPSDuplicateCourier } from '../functions/SPSCouriers';
import { SPSGetSiteVisits, SPSDownloadRapportVisit } from '../functions/SPSVisites';
import { SPSGetSiteStopTasks, SPSDownloadRapportST } from '../functions/SPSStopTask';
import { SPSGetSiteDocs, SPSGetDoc, SPSSaveDoc, SPSDeleteDoc, SPSSendDoc, SPSPDFDocExec } from '../functions/SPSDocExec';
import {SPSGetSiteICs, SPSDownloadRapportIC} from '../functions/SPSICs';
import { SPSFonctions } from '../functions/SPSFonctions';
import { SPSGetMemos } from '../functions/SPSSettings';
import  {SPSMemos, SPSCatMemos} from '../functions/SPSMemos';
import { datetime_to_fr, getCurrentDate } from '../functions/tools';
import { Editor } from '@tinymce/tinymce-react';
import { initConfig, simpleConfig } from '../functions/initConfig';
import EditCourier from './EditCourier';
import SPSLoader from './SPSLoader';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Toast from 'react-bootstrap/Toast';
import SiteVisit from './SiteVisit';
import StopTask from './StopTask';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import PDFViewer from 'pdf-viewer-reactjs'
import { Document, Page } from 'react-pdf';
import InspectionCommune from './InspectionCommune';


function Operations(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(0);
  const [operations, SetOperations] = useState([]);
  const [theOperation, SetTheOperation] = useState([]);
  const [currentFilter, SetFilter] = useState("MY");
  const [currentSearchFilter, SetSearchFilter] = useState("");
  const [componentOpen, OpenComponent] = useState("");
  const [firstInit, setFirstInit] = useState(false);
  
  const [operationId, setOperationId] = useState(0);
  const [Type, setType] = useState("");
  const [Title, setTitle] = useState("");
  const [ContractNumber, setContractNumber] = useState("");
  const [ContractDate, setContractDate] = useState("");
  const [Address, setAddress] = useState("");
  const [Zip, setZip] = useState("");
  const [City, setCity] = useState("");
  const [CaseNumber, setCaseNumber] = useState("");
  const [Mission, setMission] = useState("");
  const [Description, setDescription] = useState("");
  const [DebutTravaux, setDebutTravaux] = useState("");
  const [DelaisTravaux, setDelaisTravaux] = useState("");
  const [ProgrammeTravaux, setProgrammeTravaux] = useState("");
  const [ProgrammeTravauxOrig, setProgrammeTravauxOrig] = useState("");
  
  const [pilotId, setPilotId] = useState(0);
  const [alternatePilotId, setAlternatePilotId] = useState(0);
  const [IdMaitreOeuvre, setIdMaitreOeuvre] = useState(0);
  const [IdMaitreOuvrage, setIdMaitreOuvrage] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [contactsCissct, setContactsCissct] = useState([]);
  const [contactsPGC, setContactsPGC] = useState([]);

  const [PGCs, setPGCs] = useState([]);
  const [DIUOs, setDIUOs] = useState([]);
  const [InvitCISSCTs, setInvitCISSCTs] = useState([]);
  const [PVCISSCTs, setPVCISSCTs] = useState([]);
  const [Courriers, setCourriers] = useState([]);
  const [SiteVisits, setSitesVisits] = useState([]);
  const [StopTasks, setStopTasks] = useState([]);
  const [ICs, setICs] = useState([]);
  const [DocExecs, setDocExecs] = useState([]);
  const [DocExecUpdated, setDocExecUpdated] = useState(true);
  const [docexectosend, setDocExecToSend] = useState('');

  const [Fonctions, setFonctions] = useState([]);

  const [clickedSiteVisit, setClickedSiteVisit] = useState(0);
  const [clickedStopTask, setClickedStopTask] = useState(0);
  const [clickedIC, setClickedIC] = useState(0);

  const [courierToCreate, setCourierToCreate] = useState(0);

  const [editCourier, setEditCourier] = useState({Courier:"", Type:0, IdContact:0});
  const [showEditCourier, setShowEditCourier] = useState(false);

  const [ongletEdit, setOngletEdit] = useState(1);
  
  const [typeContactEdit, setTypeContactEdit] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [company, setSearchCompany] = useState('');
  const [keywords, setKeywords] = useState('');
  const [companies, setCompanies] = useState([]);
  const [searchContacts, setSearchContacts] = useState([]);
  const [searchContactsCopy, setSearchContactsCopy] = useState([]);
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [externCompany,setExternCompany] = useState(false);

  const [showEditContact, setShowEditContact] = useState(false);
  const [idContactEdit, setIdContactEdit] = useState(0);
  const [NomContactEdit, setNomContactEdit] = useState("");
  
  const [labelMaitreOeuvre, setLabelMaitreOeuvre] = useState("Choisir...");
  const [labelMaitreOuvrage, setLabelMaitreOuvrage] = useState("Choisir...");

  // gestion de l'edition des documents d'execution
  const editorRef = useRef(null);
  const [showModalDocExec, setShowModalDocExec] = useState(false);
  const [editdocexec, setEditDocExec] = useState({id:0, IdSite: 0, IdCompany: 0, Type: "", Document: "", DateDocument: "0000-00-00", Commentaire: ""});
  const [CommentaireDocExecOrig, setCommentaireDocExecOrig] = useState("");
  const [DocumentDocExecOrig, setDocumentDocExecOrig] = useState("");

  const [showModalSendDocExec, setShowModalSendDocExec] = useState(false);

  const [memoSticker, setMemoSticker] = useState('');
  const [displayMemoSticker, setDisplayMemoSticker] = useState(false);
  const [memocats, setmemoCats] = useState([]);
  const [memocat, setmemoCat] = useState(0);
  const [memos, setMemos] = useState([]);

  const [updateNotSaved, setUpdateNotSaved] = useState(false);
  var idtimer = 0;
  
  var sendCourier = false;

  
  const [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(false);
  
  if(firstInit == false)
  {
    SPSUserQuickList(setUsers);
    setFirstInit(true);
    SPSFonctions(setFonctions);
    SPSGetMemos(setMemo);
    SPSMemos(setMemos);
    SPSCatMemos(setmemoCats);
  }

  useEffect(() => {
    setUpdateNotSaved(true);
  }, [Type, Title, ContractNumber, ContractDate, Address, Zip, City, CaseNumber, Description, DebutTravaux, DelaisTravaux, ProgrammeTravaux]);
  useEffect(() => {
    if(memocats.length > 0)
      setmemoCat(memocats[0].id);
  }, [memocats])
  useEffect(() => {
    
    if(stateWindowEdit == 0)
    {
      searchDatas();
    }
    else
    {
      SPSCompanies(setCompanies, operationId);
    }
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    
    if(showContact)
    {
      // on ne charge les contenus que si l'interface est ouverte
      SPSSearchContact(company, keywords, setSearchContacts);
    }
    
  }, [company, keywords]);

  useEffect(() => {
    
    var options = [];
    var group = {type: "group", name: "Autres sociétés", items: []};
    var group_started=1;
    for(var i = 0; i < companies.length; i++)
    {
      if(group_started < 2 && !companies[i].count)
        group_started = 2;
      
      if(group_started == 2)
        group.items.push({name: companies[i].Name, value: companies[i].id});
      else
        options.push({name: companies[i].Name, value: companies[i].id});
    }
    if(group.items.length > 0)
      options.push(group);

    setOptionsCompany(options);
    
  }, [companies]);
  

  useEffect(() => {
    if(theOperation.id != undefined)
    {
      
      setLoading(true);
      setOperationId(theOperation.id);
      setType(theOperation.Type);
      setTitle(theOperation.Title);
      setContractNumber(theOperation.ContractNumber);
      setContractDate(theOperation.ContractDate);
      setAddress(theOperation.Address);
      setZip(theOperation.Zip);
      setCity(theOperation.City);
      setCaseNumber(theOperation.CaseNumber);
      setMission(theOperation.Mission);
      setDescription(theOperation.Description);
      setDebutTravaux(theOperation.DebutTravaux);
      setDelaisTravaux(theOperation.DelaisTravaux);
      setProgrammeTravaux(theOperation.ProgrammeTravaux);
      setProgrammeTravauxOrig(theOperation.ProgrammeTravaux);
      setPilotId(theOperation.IdPilot);
      setAlternatePilotId(theOperation.IdAlternatePilot);
      setIdMaitreOeuvre(theOperation.IdMaitreOeuvre);
      setIdMaitreOuvrage(theOperation.IdMaitreOuvrage);
      setLabelMaitreOeuvre(theOperation.LabelMaitreOeuvre);
      setLabelMaitreOuvrage(theOperation.LabelMaitreOuvrage);
      setContacts(theOperation.Contacts);
      //setContactsCissct(theOperation.ContactsCissct);
      //setContactsPGC(theOperation.ContactsPGC);
      setStateWindowEdit(1);
      setLoading(false);
      // chargement des PGC
      SPSGetSitePGCs(theOperation.id, setPGCs);
      SPSGetSiteDIUOs(theOperation.id, setDIUOs);
      SPSGetSiteInvitCISSCTs(theOperation.id, setInvitCISSCTs);
      SPSGetSitePVCISSCTs(theOperation.id, setPVCISSCTs);
      SPSGetSiteCouriers(theOperation.id, setCourriers);
      SPSGetSiteVisits(theOperation.id, setSitesVisits);
      SPSGetSiteStopTasks(theOperation.id, setStopTasks);
      SPSGetSiteDocs(theOperation.id, setDocExecs);
      SPSGetSiteICs(theOperation.id, setICs);

      idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
    }
  }, [theOperation]);

  useEffect(() => {
    var contactscopy = [...searchContacts];
    setSearchContactsCopy(contactscopy);
    
  }, [searchContacts]);

  useEffect(() => {
    setDocExecUpdated(true);
    
  }, [editdocexec.IdCompany, editdocexec.Type, editdocexec.Document, editdocexec.DateDocument, editdocexec.Commentaire]);
  
  useEffect(() => {
    setLoading(false);
  }, [PGCs, DIUOs, InvitCISSCTs, PVCISSCTs, Courriers]);

  const handleAddOperation = (event) => {
    setOperationId(0);
    setType("");
    setTitle("");
    setContractNumber("");
    setContractDate("");
    setAddress("");
    setZip("");
    setCity("");
    setCaseNumber("");
    setMission("");
    setDescription("");
    setDebutTravaux("");
    setDelaisTravaux("");
    setProgrammeTravaux("");
    setProgrammeTravauxOrig("");
    setPilotId(0)
    setAlternatePilotId(0)
    setIdMaitreOeuvre(0);
    setIdMaitreOuvrage(0);
    setStateWindowEdit(1);
    idtimer = setInterval(() => {setUpdateNotSaved(false); clearInterval(idtimer);  }, 1000);
  };
  const handleCancelAddOperation= (event) => {
    if(updateNotSaved)
    {
      confirmAlert({
        title: 'Annulation',
        message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              setStateWindowEdit(0);
            }
          },
          {
            label: 'Non',
            onClick: () => { 

            }
          }
        ]
      });
    }
    else
      setStateWindowEdit(0);
  };
  const handleClickOperation = (id) => {
    setLoading(true);
    SPSGetSite(id, SetTheOperation);
  };

  useEffect(() => {
    searchDatas();
  }, [currentFilter]);
  useEffect(() => {
    searchDatas();
  }, [currentSearchFilter]);

  function searchDatas()
  {
    SPSSites(SetOperations, currentFilter, currentSearchFilter);
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const body = {
      id:operationId,
      Type: Type,
      Title: Title,
      ContractNumber: ContractNumber,
      ContractDate: ContractDate,
      Address: Address,
      Zip: Zip,
      City: City,
      CaseNumber: CaseNumber,
      Mission: Mission,
      Description: Description,
      DebutTravaux: DebutTravaux,
      DelaisTravaux: DelaisTravaux,
      ProgrammeTravaux: ProgrammeTravaux,
      IdPilot: pilotId,
      IdAlternatePilot: alternatePilotId,
      IdMaitreOeuvre: IdMaitreOeuvre,
      IdMaitreOuvrage: IdMaitreOuvrage,
      Contacts: contacts,
      ContactsCissct: contactsCissct,
      ContactsPGC: contactsPGC
    };
    SPSSaveSite(body, setStateWindowEdit);
    //setStateWindowVisit(0);
  };

  const handleAddContact = () => {
    setTypeContactEdit(0);
    setShowContact(true);
  };

  const handleAddContactCissct = () => {
    setTypeContactEdit(1);
    setShowContact(true);
  };

  const handleAddContactPGC = () => {
    setTypeContactEdit(2);
    setShowContact(true);
  };

  const handleMaitreOeuvre = () => {
    setTypeContactEdit(4);
    setShowContact(true);
  };

  const handleMaitreOuvrage = () => {
    setTypeContactEdit(5);
    setShowContact(true);
  };
  const handleChangeProgrammeTravaux = (value, editor) => {
    setProgrammeTravaux(value);
  };
  const handleEditNumMission = (value) => {
    setCaseNumber(value);
    // si le numéro de contrat n'est pas renseigné ou s'il commence comme le numéro de mission, on recopie jusqu'au tiret
    if(ContractNumber == '' || value.indexOf(ContractNumber) == 0)
    {
      const indexOfFirst = value.indexOf('-');
      if(indexOfFirst > 0)
        setContractNumber(value.substring(0, indexOfFirst));
      else
        setContractNumber(value);
    }
  };

  const handleDeleteContact = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce contact ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            var contactcopy = contacts.filter((c) => c.id !== id);
            setContacts(contactcopy);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
    
  };

  const handleEditContact = (id) => {
    
    var current = contacts.filter((c) => c.id === id);
    if(current)
    {
      setIdContactEdit(id);
      setNomContactEdit(current[0].Civility+" "+current[0].FirstName+" "+current[0].Name);
      setTypeContactEdit(current[0].Type);
      setShowEditContact(true);
    }
    
  }

  const handleValidEditContact = () => {
    var tmpContacts = [...contacts];
    var current = tmpContacts.filter((c) => c.id === idContactEdit);
    
    if(current)
    {
      current[0].Type=typeContactEdit;
    }
    setContacts(tmpContacts);
    setShowEditContact(false);
  }

  const handleClosedEditContact = () => {
    setIdContactEdit(0);
    setNomContactEdit("");
    setShowEditContact(false);
  }

  const handleChangeFonction = (idContact, idFonction) => {
    var contactcopy = [...contacts];
    var contactrech = contacts.filter((c) => c.id === idContact);
    if(contactrech && contactrech.length > 0)
    {
      contactrech[0].IdFonction = idFonction;

    }
    setContacts(contactcopy);
  }

  const handleChangeDetailFonction = (idContact, detailFonction) => {
    var contactcopy = [...contacts];
    var contactrech = contactcopy.filter((c) => c.id === idContact);
    if(contactrech && contactrech.length > 0)
    {
      contactrech[0].DetailFonction = detailFonction;
      
    }
    setContacts(contactcopy)
  }

  
  const handleAddPGC = () => {
    SPSMakeCourier(operationId, 1, editNewCourier, 0);
  }

  const handleAddDUIO = () => {
    SPSMakeCourier(operationId, 3, editNewCourier, 0);
    /*setCourierToCreate(3);
    setTypeContactEdit(6);
    setShowContact(true);*/
  }

  const handleAddInvitCISSCT = () => {
    SPSMakeCourier(operationId, 4, editNewCourier, 0);
  }

  const handleAddPVCISSCT = () => {
    SPSMakeCourier(operationId, 5, editNewCourier, 0);
  }

  const handleAddCourrier= () => {
    SPSMakeCourier(operationId, 6, editNewCourier, 0);
  }
  const handleAddDocExec = () => {
    
    setEditDocExec({id:0, IdSite: operationId, IdCompany: 0, Type: "", DateDocument: getCurrentDate('-'),Commentaire: ""});
    setCommentaireDocExecOrig("");
    setDocumentDocExecOrig("");
    idtimer = setInterval(() => {setDocExecUpdated(false); clearInterval(idtimer);  }, 1000);
    setShowModalDocExec(true);
  }

  const editNewCourier = (type, courier, contact) => {
    setEditCourier({id: 0, Courier: courier.Courier, Type: type, IdContact: contact});
    setShowEditCourier(true);
  };

  const handleDeletePGC = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce PGC ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCourier(id, loadPGCs);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }

  
  const handleDeleteDIUO = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce DIUO ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCourier(id, loadDIUOs);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }
  const handleCopyDIUO = (id) => {
    SPSDuplicateCourier(id, loadDIUOs);
          
  }

  const handleCopyPGC = (id) => {
    SPSDuplicateCourier(id, loadPGCs);
          
  }

  const handleDeleteInvitCISSCT= (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever cette invitation ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCourier(id, loadInvitCISSCTs);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }

  const handleDeletePVCISSCT= (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce PV CISSCT ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCourier(id, loadPVCISSCTs);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }

  const handleDeleteCourrier= (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce courrier ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteCourier(id, loadCouriers);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }

  const handleEditPGC = (id) => {
    SPSGetCourier(id, editThisCourier);
  }

  
  const editThisCourier = (courier) => {
    setEditCourier(courier[0]);
    setShowEditCourier(true);
  }

  const handleSaveCourier = (courier, send=false) => {
    setLoading(true);
    var tmpCourier = editCourier;
    tmpCourier.Courier = courier;
    setEditCourier(tmpCourier);
    sendCourier = send;
    saveCurrentCourier();
  };

  const saveCurrentCourier = () => {
    SPSSaveCourier(operationId, editCourier, afterSaveCourier);
  }

  const afterSaveCourier = (id) => {
    if(sendCourier)
    {
      sendCourier = false;
      SPSSendCourier(id, afterSentCourier);
    }
    else
    {
      setShowEditCourier(false);
      switch(editCourier.Type)
      {
        case 1:
          loadPGCs();
          break;
        case 3:
          loadDIUOs();
          break;
        case 4:
          loadInvitCISSCTs();
          break;
        case 5:
          loadPVCISSCTs();
          break;
        case 6:
          loadCouriers();
          break;
      }
      setLoading(false);
    }
    
  }

  const afterSentCourier = () => {
    setShowEditCourier(false);
    switch(editCourier.Type)
    {
      case '1':
        loadPGCs();
        break;
      case '3':
        loadDIUOs();
        break;
      case '4':
        loadInvitCISSCTs();
        break;
      case '5':
        loadPVCISSCTs();
        break;
      case '6':
        loadCouriers();
        break;
    }
    //setLoading(true);
  }
  
  const handleClickVisit = (id) => {
    setClickedSiteVisit(id);
    OpenComponent("SiteVisit");
  }

  const handleAddSiteVisit = () => {
    setClickedSiteVisit(0);
    OpenComponent("SiteVisit");
  }

  const handleDownloadVisit = (id, ref) => {
    SPSDownloadRapportVisit(id, ref);
  }

  const handleClickST = (id) => {
    setClickedStopTask(id);
    OpenComponent("StopTask");
  }
  const handleAddST = () => {
    setClickedStopTask(0);
    OpenComponent("StopTask");
  }

  const handleDownloadST = (id, ref) => {
    SPSDownloadRapportST(id, ref);
  }

  const handleAddIC = () => {
    setClickedIC(0);
    OpenComponent("IC");
  }

  const handleClickIC = (id) => {
    setClickedIC(id);
    OpenComponent("IC");
  }

  const handleDownloadIC = (id) => {
    SPSDownloadRapportIC(id);
  }

  const loadPGCs = () => {
    // chargement des PGC
    SPSGetSitePGCs(theOperation.id, setPGCs);
  }

  const loadDIUOs = ()=> {
    // chargement des DIUO
    SPSGetSiteDIUOs(theOperation.id, setDIUOs);
  }

  const loadInvitCISSCTs = ()=> {
    // chargement des DIUO
    SPSGetSiteInvitCISSCTs(theOperation.id, setInvitCISSCTs);
  }

  const loadPVCISSCTs = () => {
    // chargement des pV CISSCT
    SPSGetSitePVCISSCTs(theOperation.id, setPVCISSCTs);
  }

  const loadCouriers = () => {
    // chargement des courriers
    SPSGetSiteCouriers(theOperation.id, setCourriers);
  }

  const handleCancelCourier = () => {
    setShowEditCourier(false);
  }

  const handleSearchContact = (event) => {
    event.preventDefault();
  };
  
  const handleClosedContact = () => {
    setShowContact(false);
  };
  
  const handleClickContact = (idContact) => {
    var contact;
    switch(typeContactEdit)
    {
      case 0:
      case 1:
      case 2:
        // on ajoute le contacte uniquement s'il n'existe pas déjà
        contact = contacts.filter((c) => c.id == idContact);
        if(contact.length==0)
        {
          var contactcopy = [...contacts];
          // récupération de toutes les informations du contact dans l'historique de recherche
          var contact_info = searchContactsCopy.filter((c) => c.id == idContact);
          if(contact_info.length > 0)
          {
            contactcopy.push({id:idContact, Type:typeContactEdit, IdFonction:'', DetailFonction:'', Civility:contact_info[0].Civility, FirstName:contact_info[0].FirstName, Name: contact_info[0].Name, Email: contact_info[0].Email, Mobile: contact_info[0].Mobile});
          }

          setContacts(contactcopy);
         }
        break;
      case 4:
        setIdMaitreOeuvre(idContact);
        var contact_info = searchContactsCopy.filter((c) => c.id == idContact);
        var labelContact = '';
        if(contact_info.length > 0)
        {
          labelContact = contact_info[0].Civility+' '+contact_info[0].FirstName+' '+contact_info[0].Name;
        }
        setLabelMaitreOeuvre(labelContact);
        setShowContact(false);
        break;
      case 5:
        setIdMaitreOuvrage(idContact);
        var contact_info = searchContactsCopy.filter((c) => c.id == idContact);
        var labelContact = '';
        if(contact_info.length > 0)
        {
          labelContact = contact_info[0].Civility+' '+contact_info[0].FirstName+' '+contact_info[0].Name;
        }
        setLabelMaitreOuvrage(labelContact);
        setShowContact(false);
        break;
      // selection d'un contact pour un courrier  
      case 6:
        setShowContact(false);
        SPSMakeCourier(operationId, courierToCreate, editNewCourier, idContact);
    }
  };

  // handles des documents d'execution
  const handleClosedDocExec = () => {
    if(DocExecUpdated == true)
    {
      confirmAlert({
        title: 'Annulation',
        message: 'Vous avez effectué des modifications. Souhaitez-vous tout de même quitter sans enregistrer ?',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
              setShowModalDocExec(false);
            }
          },
          {
            label: 'Non',
            onClick: () => { 

            }
          }
        ],
        overlayClassName: "confirm-alert"
      });
    }
    else
      setShowModalDocExec(false);
  };
  const handleChangeEditDocCommentaire = (value) => {
    setEditDocExec({...editdocexec, Commentaire: value});
  }
  const handleChangeEditDocDocument = (value) => {
    setEditDocExec({...editdocexec, Document: value});
  }
  const editDocExec = (datas) => {
    setEditDocExec(datas[0]);
    setCommentaireDocExecOrig(datas[0].Commentaire);
    setDocumentDocExecOrig(datas[0].Document);
    idtimer = setInterval(() => {setDocExecUpdated(false); clearInterval(idtimer);  }, 1000);
    setShowModalDocExec(true);
  };
  const handleEditDocExec = (id) => {
    SPSGetDoc(id, editDocExec);
  }
  const handleDeleteDocExec = (id) => {
    
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce document d\'execution ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SPSDeleteDoc(id, afterDeleteDocExec);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }
  const handleSaveDocExec = (event) => {
    event.preventDefault();
    if(editDocExec.IdCompany== 0 || editDocExec.DateDocument == '0000-00-00')
      alert("Vous devez choisir la société et la date du document !");
    else
    {
      SPSSaveDoc(editdocexec.id, editdocexec, afterSaveDocExec);
    }
  }
  const afterDeleteDocExec = () => {
    SPSGetSiteDocs(operationId, setDocExecs);
    
  }
  const afterSaveDocExec = () => {
    SPSGetSiteDocs(operationId, setDocExecs);
    setShowModalDocExec(false);
  }

  const handleSendDocExec = () => {
    //setShowModalDocExec(true);
    var doc = SPSPDFDocExec(operationId);
    setDocExecToSend(doc);
    setShowModalSendDocExec(true);
  }
  
  const handleClosedSendDocExec = () => {
    setShowModalSendDocExec(false);
  };

  const handleValidSendDocExec = () => {
    SPSSendDoc(operationId, afterSendDocExec)
  };

  const afterSendDocExec = (datas) => {
    setShowModalSendDocExec(false);
    alert("EMail envoyé avec succès !");
  }

  const labelCompany = (id) => {
    var label = '';
    const c = companies.filter((c) => c.id == id);
    if(c && c.length > 0)
      label = c[0].Name;

    return label;
  }

  const indiceDocEx = (number) => {
    if(number > 0)
    {
      const strindice = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      return strindice.substring(number-1, number);
    }
    else
      return '';
  }

  

  const handleBack = (event) => {
    props.screenHandling(0);
  };

  function setMemo (data) {
    
    if(data.length)
    {
      // on ne récupère que celle qui nous interesse
      const memo = data.filter((m) => m.KeySetting == 'MEMO_DOCEXEC');
      if(memo)
      {
        setMemoSticker(memo[0].Value);
      }
    }
  }

  const handleDisplayMemo = () => {
    setDisplayMemoSticker(true);
  }

  const handleCloseMemo = () => {
    setDisplayMemoSticker(false);
  }

  function checkCompanyDocExec(id_company)
  {
    // il faut vérifier si la société fait partie de l'annuaire
    var selcompany = companies.filter((c) => c.id == id_company);
    if(selcompany && !selcompany[0].count)
      setExternCompany(true);
    else
      setExternCompany(false);
  }

  if(componentOpen != '')
  {
    switch(componentOpen)
    {
      case 'SiteVisit':
        if(clickedSiteVisit > 0)
        {
          return (
            <div>
              <SiteVisit screenHandling={OpenComponent} idVisit={clickedSiteVisit}/>
            </div>);
        }
        else
        {
          return (
            <div>
              <SiteVisit screenHandling={OpenComponent} idOperation={operationId}/>
            </div>);
        }
        break;
      case 'StopTask':
        if(clickedStopTask > 0)
        {
          return (
            <div>
              <StopTask screenHandling={OpenComponent} idStopTask={clickedStopTask}/>
            </div>);
          
        }
        else
        {
          return (
            <div>
              <StopTask screenHandling={OpenComponent} idOperation={operationId}/>
            </div>);
        }
        break;
      case 'IC':
        if(clickedIC > 0)
        {
          return (
            <div>
              <InspectionCommune screenHandling={OpenComponent} idIC={clickedIC}/>
            </div>);
          
        }
        else
        {
          return (
            <div>
              <InspectionCommune screenHandling={OpenComponent} idOperation={operationId}/>
            </div>);
        }
        break;
      default:
          OpenComponent("");
    }
  }

  if(componentOpen == '')
  {
    switch(stateWindowEdit)
    {
      case 1:
        return (
          <div className="containerxl">
            <div className="row headScreen fixed-top px-0">
              <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleCancelAddOperation}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6"><h1 className="h4 py-3">Opération</h1></div>
              <div className="col-3 py-2 text-end"><button className="btn btn-link" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <SPSLoader load={loading} />
            <div className="container mt-5">
              <form onSubmit={handleSubmit} className={showEditCourier?"d-none":""}>
                <div className="p-3">
                  <div id="onglet1" className={ongletEdit==1 ? 'onglet d-block pt-2' : 'onglet d-none'}>
                    <div className="row">
                      <div className="col-md-6">
                        <input type="hidden" name="id" value={operationId} />
                        <div className="form-group row mb-3">
                          <label className="col-md-3">Titre</label>
                          <div className="col-md-9">
                            <input type="text" className="form-control" name="Title" value={Title} onChange={e => { setTitle(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-md-3">N° d'affaire</label>
                            <div className="col-md-9">
                              <input type="text" className="form-control" name="CaseNumber" value={CaseNumber} onChange={e => { handleEditNumMission(e.target.value);  }} />
                            </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Type</label>
                          <div className="col-md-9">
                            <input type="text" className="form-control" name="Title" value={Type} onChange={e => { setType(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">N° de contrat</label>
                          <div className="col-md-9">
                            <input type="text" className="form-control" name="ContractNumber" value={ContractNumber} onChange={e => { setContractNumber(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Date contrat</label>
                          <div className="col-md-5">
                            <input type="date" className="form-control" name="ContractDate" value={ContractDate} onChange={e => { setContractDate(e.target.value); }} />
                          </div>
                        </div>
                        
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Adresse</label>
                          <div className="col-md-9">
                            <textarea className="form-control" name="Address" rows="2" value={Address} onChange={e => { setAddress(e.target.value); }}></textarea>
                          </div>
                        </div>
                        
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">CP</label>
                          <div className="col-md-3 mb-3 mb-md-0">
                            <input type="text" className="form-control" name="Zip" value={Zip} onChange={e => { setZip(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Ville</label>
                          <div className="col-md-9 ">
                            <input type="text" className="form-control" name="City" value={City} onChange={e => { setCity(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Pilote</label>
                          <div className="col-md-9">
                            <select name="IdPilot" value={pilotId} className="form-select" onChange={e => { setPilotId(e.target.value); }}>
                              <option value="0">Choisir</option>
                              {users.map((user) =>(
                              <option key={user.id} value={user.id}>{user.Name} {user.FirstName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Suppléant</label>
                          <div className="col-md-9">
                            <select name="IdPilot" value={alternatePilotId} className="form-select" onChange={e => { setAlternatePilotId(e.target.value); }}>
                              <option value="0">Choisir</option>
                              {users.map((user) =>(
                              <option key={user.id} value={user.id}>{user.Name} {user.FirstName}</option>
                              ))}
                            </select>
                          </div>      
                        </div>  
                        
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Maître d'Oeuvre</label>
                          <div className="col-md-9 ">
                            <button type="button" className="btn btn-outline-secondary" onClick={handleMaitreOeuvre}>{labelMaitreOeuvre}</button>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Maître d'Ouvrage</label>
                          <div className="col-md-9 ">
                            <button type="button" className="btn btn-outline-secondary" onClick={handleMaitreOuvrage}>{labelMaitreOuvrage}</button>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Début travaux</label>
                          <div className="col-md-9 ">
                            <input type="text" className="form-control" name="DebutTravaux" value={DebutTravaux} onChange={e => { setDebutTravaux(e.target.value); }} />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Délais</label>
                          <div className="col-md-9 ">
                            <input type="text" className="form-control" name="DelaisTravaux" value={DelaisTravaux} onChange={e => { setDelaisTravaux(e.target.value); }} />
                          </div>
                        </div>
                        
                        <div className="form-group mb-3 row">
                          <label className="col-md-3">Commentaire interne</label>
                          <div className="col-md-9">
                            <textarea className="form-control" name="Description" rows="5" value={Description} onChange={e => { setDescription(e.target.value); }}></textarea>
                          </div>
                        </div>
                        
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12 px-4">
                        <label>Programme des travaux</label>
                        <div>
                          <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={ProgrammeTravauxOrig}
                                init={simpleConfig}
                                tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                                onEditorChange={handleChangeProgrammeTravaux}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="onglet2" className={ongletEdit==2 ? 'onglet d-block pt-2' : 'onglet d-none'}>
                    <h4 className="text-center mb-3">Listes de diffusion</h4>
                    <h5 className="text-center mb-3">{Title} - {CaseNumber}</h5>
                    
                    <div className="card mb-3">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-8">Liste</div>
                          <div className="col-4 text-end">
                            <Dropdown className="btn btn-primary btn-sm">
                              <Dropdown.Toggle >
                                Ajouter
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleAddContactPGC}>Conception</Dropdown.Item>
                                <Dropdown.Item onClick={handleAddContact}>Chantier</Dropdown.Item>
                                <Dropdown.Item onClick={handleAddContactCissct}>CISSCT</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                      
                        <table className="table table-striped liste liste-incluse">
                            <thead>
                              <tr className="row"   >
                                <th className="col-10 col-md-4">Nom / Coordonnées</th>
                                <th className="col-12 col-md-3 text-start">Fonction</th>
                                <th className="col-4 col-md-1 text-center">Conception</th>
                                <th className="col-4 col-md-1 text-center">Chantier</th>
                                <th className="col-4 col-md-1 text-center">CISSCT</th>
                                <th className="col-2 col-md-1 text-end pt-2"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {contacts.length == 0 &&
                              <tr><td colspan="6" className="text-center text-muted">Aucun contact</td></tr>
                              }
                              {contacts.map((r) =>(
                              <tr className="row" key={r.id}  >
                                  <td className="col-10 col-md-4">{r.Civility} {r.FirstName} {r.Name}<br />{r.Email} - {r.Mobile}</td>
                                  <td className="col-12 col-md-3 text-start">
                                    <select className="form-select form-select-sm mb-1" value={r.IdFonction} onChange={(e) => handleChangeFonction(r.id, e.target.value)}>
                                      <option value="0">Fonction...</option>
                                      {Fonctions.map((f) => (
                                        <option key={f.id} value={f.id}>{f.Libelle}</option>
                                      ))}
                                    </select>
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => handleChangeDetailFonction(r.id, e.target.value)} value={r.DetailFonction} placeholder='détail'/>
                                  </td>
                                  <td className="col-4 col-md-1 text-center">{r.Type == 2 ?<FontAwesomeIcon icon={faCheck} />:''}</td>
                                  <td className="col-4 col-md-1 text-center">{r.Type == 2 || r.Type == 0 ?<FontAwesomeIcon icon={faCheck} />:''}</td>
                                  <td className="col-4 col-md-1 text-center">{r.Type >= 0 ?<FontAwesomeIcon icon={faCheck} />:''}</td>
                                  <td className="col-2 col-md-1 text-end pt-2">
                                    <button type="button" onClick={() => handleEditContact(r.id)} className="btn btn-primary btn-sm me-2"><FontAwesomeIcon icon={faPencil} /></button>
                                    <button type="button" onClick={() => handleDeleteContact(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button>
                                  </td>
                              </tr>
                              ))}
                            </tbody>
                          
                        </table>
                      </div>
                    </div>

                    

                    
                  </div>
                  <div id="onglet3" className={ongletEdit==3 ? 'onglet d-block pt-2' : 'onglet d-none'}>
                    <h4 className="text-center mb-3">Documents</h4>
                    <h5 className="text-center mb-3">{Title} - {CaseNumber}</h5>
                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        PGC
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddPGC}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {PGCs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleEditPGC(r.id)}>PGC du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleEditPGC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button title="Dupliquer PGC" type="button" onClick={() => handleCopyPGC(r.id)} className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faCopy} /></button> <button type="button" onClick={() => handleDeletePGC(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddPGC}>Créer un PGC</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        DIUO
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddDUIO}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {DIUOs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleEditPGC(r.id)}>DIUO {r.company_Name} du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleEditPGC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button title="Dupliquer DIUO" type="button" onClick={() => handleCopyDIUO(r.id)} className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faCopy} /></button> <button type="button" onClick={() => handleDeleteDIUO(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddDUIO}>Créer un DIUO</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Invitations CISSCT
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddInvitCISSCT}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {InvitCISSCTs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleEditPGC(r.id)}>Invitation du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleEditPGC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDeleteInvitCISSCT(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddInvitCISSCT}>Créer une invitation</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        PV CISSCT
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddPVCISSCT}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {PVCISSCTs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleEditPGC(r.id)}>PV CISSCT du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleEditPGC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDeletePVCISSCT(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddPVCISSCT}>Créer un PV CISSCT</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Documents d'execution
                        <div className="pos-right">
                          <button type="button" title="Envoyer le rapport" className="btn btn-primary btn-sm me-2" onClick={handleSendDocExec}><FontAwesomeIcon icon={faPaperPlane} /></button>
                          <button type="button" className="btn btn-primary btn-sm" onClick={handleAddDocExec}>+</button>
                        </div>
                        
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {DocExecs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-11 h5 pt-1 border-bottom" onClick={() => handleEditDocExec(r.id)}>{labelCompany(r.IdCompany)}  </div>
                              <div className='col-1 text-end pt-2'><button type="button" onClick={() => handleDeleteDocExec(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                              <div className="col-3 col-md-2 text-start"onClick={() => handleEditDocExec(r.id)}>{datetime_to_fr(r.DateDocument).date}</div>
                              <div className="col-9 col-md-5 text-start" onClick={() => handleEditDocExec(r.id)}><label>Document</label><div className="fst-italic" dangerouslySetInnerHTML={{__html : r.Document}}></div></div>
                              <div className="col-12 col-md-5 text-start" onClick={() => handleEditDocExec(r.id)}><label>Avis</label><div className="fst-italic" dangerouslySetInnerHTML={{__html : r.Commentaire}}></div></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddDocExec}>Ajouter un document d'execution</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Courriers
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddCourrier}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {Courriers.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleEditPGC(r.id)}>Courrier du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleEditPGC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDeleteCourrier(r.id)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></button></div>
                          </li>
                          ))}
                          <li className="row mb-2 alternate-color">
                            <div className="col-12 text-center"><button className="btn btn-link" type="button" onClick={handleAddCourrier}>Créer un courrier</button></div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Visites de chantier
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddSiteVisit}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {SiteVisits.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleClickVisit(r.id)}>Visite du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleClickVisit(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDownloadVisit(r.id, r.site_CaseNumber+'-ERJ-'+r.indiceERJ)} className="btn btn-default btn-sm"><FontAwesomeIcon icon={faDownload} /></button></div>
                          </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Arrêts de tâche
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddST}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {StopTasks.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleClickST(r.id)}>Arrêt de tâche du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleClickST(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyé</span>:<span className="text-success">Envoyé le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDownloadST(r.id, r.site_CaseNumber+'-AT-'+r.SentDate.substr(0, 10))} className="btn btn-default btn-sm"><FontAwesomeIcon icon={faDownload} /></button></div>
                          </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-header pos-relative">
                        Inspections communes
                        <button type="button" className="btn btn-primary btn-sm pos-right" onClick={handleAddIC}>+</button>
                      </div>
                      <div className="card-body">
                      
                        <ul className="liste liste-incluse">
                          {ICs.map((r) =>(
                          <li className="row mb-2 alternate-color" key={r.id}  >
                              <div className="col-10 col-md-5" onClick={() => handleClickIC(r.id)}>Inspection commune du {datetime_to_fr(r.Date_Creation).date}</div>
                              <div className="col-10 col-md-5 text-start"onClick={() => handleClickIC(r.id)}>{r.SentDate=="0000-00-00 00:00:00" ? <span className="text-danger">Non envoyée</span>:<span className="text-success">Envoyée le {datetime_to_fr(r.SentDate).date}</span>}</div>
                              <div className="col-2 col-md-2 text-end pt-2"><button type="button" onClick={() => handleDownloadIC(r.id)} className="btn btn-default btn-sm"><FontAwesomeIcon icon={faDownload} /></button></div>
                          </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              
              </form>
              <Modal show={showEditContact} onHide={handleClosedEditContact} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>{NomContactEdit}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                        <select className="form-select" value={typeContactEdit} onChange={(e) => {setTypeContactEdit(e.target.value)}}>
                          <option value="2" >Conception</option>
                          <option value="0" >Chantier</option>
                          <option value="1" >CISSCT</option>
                        </select>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="button" onClick={() => {setShowEditContact(false)}}className="btn btn-secondary">Annuler</button>
                    <button type="button" onClick={handleValidEditContact} className="btn btn-primary">Valider</button>
                  </Modal.Footer>
              </Modal>
              <Modal show={showContact} onHide={handleClosedContact} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Contacts</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="container-fluid">
                          <form role="search" onSubmit={handleSearchContact}>
                            <div className="row">
                              <div className="col-md-6">
                                <SelectSearch search="true" autoComplete="false" placeHolder="Société" options={optionsCompany} value={company} onChange={(value) => { setSearchCompany(value); }} />
                              </div>
                              <div className="col-md-6">
                                <input className="form-control me-2" type="search" placeholder="Rechercher" value={keywords}  onChange={e => { setKeywords(e.target.value); }} aria-label="Search" />
                              </div>
                            </div>
                          </form>
                        
                      </div>
                    <ul className="liste liste-incluse mt-3">
                      {searchContacts.map((r) =>(
                        <li className="alternate-color px-3" key={r.id} >
                          <div className="row">
                            <div className="col-11">{r.FirstName} {r.Name} ({r.Title})<br/>{r.company_Name}</div>
                            <div className="col-1 pos-relative"><button title="Ajouter à la liste de diffusion" className="btn btn-outline-primary btn-sm pos-right" type="button" onClick={() => handleClickContact(r.id)}><FontAwesomeIcon icon={faShare} /></button></div>
                          </div>
                        </li>
                      ))}
                    </ul>
                      
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedContact}>Fermer</button>
                  </Modal.Footer>
              </Modal>

              <Modal show={showModalDocExec} backdrop="static" onHide={handleClosedDocExec} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <form onSubmit={handleSaveDocExec}>
                  <Modal.Header closeButton>
                  <Modal.Title>Document d'execution</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                  {memoSticker != '' && displayMemoSticker == false ?
                      <div className="btn-memo">
                        <button className="btn btn-warning btn-lg" onClick={() => {handleDisplayMemo()}}><FontAwesomeIcon icon={faLightbulb} /></button>
                      </div>
                      :''
                    }
                    {displayMemoSticker ?
                      <Toast id="toast_memo" show={displayMemoSticker} onClose={handleCloseMemo}>
                      <Toast.Header>
                        <strong className="me-auto">Mémo</strong>
                      </Toast.Header>
                      <Toast.Body>
                        <select className="form-select" name="memocats" onChange={e => { setmemoCat(e.target.value)}}>
                        {memocats.map((r) =>(
                        <option key={r.id} value={r.id}>{r.Libelle}</option>
                        ))}
                        </select>
                        <div>
                          {memos.filter(m => m.Type==2 &&  m.IdCatMemo == memocat).map((r) =>(
                          <div className="border p-2 mt-2" key={r.id}  dangerouslySetInnerHTML={{__html: r.Descriptif}}></div>
                          ))}
                        </div>
                      </Toast.Body>
                    </Toast>
                      
                    :""}
                      <div className="row mb-2">
                        <label className="form-label col-md-3">Société</label>
                        <div className="col-md-9">
                          <div className="d-inline-block">
                            <SelectSearch search="true"  autoComplete="false" options={optionsCompany} value={editdocexec.IdCompany} placeholder="Choisir la société" onChange={(data) => { checkCompanyDocExec(data); setEditDocExec({...editdocexec, IdCompany: data}); }} />
                            </div>
                          {externCompany?<div className="h2 d-inline text-warning ms-3"><FontAwesomeIcon icon={faTriangleExclamation} /></div>:''}
                        </div>
                        
                      </div>
                      <div className="row mb-2">
                        <label className="form-label col-md-3">Type</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control" value={editdocexec.Type} onChange={e => { setEditDocExec({...editdocexec, Type: e.target.value}); }} />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="form-label col-md-3">Date</label>
                        <div className="col-md-9">
                          <input type="date" className="form-control" required value={editdocexec.DateDocument} onChange={e => { setEditDocExec({...editdocexec, DateDocument: e.target.value}); }} />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label col-12">Document</label>
                        <div className="col-12">
                          <Editor
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={DocumentDocExecOrig}
                              init={simpleConfig}
                              tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                              onEditorChange={handleChangeEditDocDocument}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label col-12">Commentaires</label>
                        <div className="col-12">
                          <Editor
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={CommentaireDocExecOrig}
                              init={simpleConfig}
                              tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                              onEditorChange={handleChangeEditDocCommentaire}
                          />
                        </div>
                      </div>
                      
                    
                       
                    
                      
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedDocExec}>Annuler</button>
                      <button type="submit" className="btn btn-primary" >Valider</button>
                  </Modal.Footer>
                </form>
              </Modal>

              <Modal show={showModalSendDocExec} onHide={handleClosedSendDocExec} size="xl" fullscreen="xxl-down" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Header closeButton>
                  <Modal.Title>Envoi du document d'execution</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                  <embed src={docexectosend} width="100%" height="800" type="application/pdf" />
                  
                  </Modal.Body>

                  <Modal.Footer>
                      <button type="button" className="btn btn-secondary" onClick={handleClosedSendDocExec}>Annuler</button>
                      <button type="button" className="btn btn-primary" onClick={handleValidSendDocExec}>Envoyer</button>
                  </Modal.Footer>
               
              </Modal>

              
            </div>
            <div className="row footerToolbar px-0">
                <div className="col text-center px-0"><button className={ongletEdit==1 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(1)}}><FontAwesomeIcon icon={faPencil} /></button></div>
                <div className="col text-center px-0"><button className={ongletEdit==2 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(2)}}><FontAwesomeIcon icon={faAddressBook} /></button></div>
                <div className="col text-center px-0"><button className={ongletEdit==3 ? "btn btn-link": "btn btn-link text-secondary"} type="button" onClick={() => {setOngletEdit(3)}}><FontAwesomeIcon icon={faFileLines} /></button></div>
            </div>
            <EditCourier show={showEditCourier} courier={editCourier.Courier} onValidate={handleSaveCourier} onCancel={handleCancelCourier}></EditCourier>
          </div>
        );
        break;
      default:
        var liste_vide = "";
        if(operations.length == 0)
        {
          liste_vide = <div className="lineEmptyTab">Aucune opération</div>
        }
        return (
          <div className="containerxl" >
            <div className="row headScreen">
              <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Opérations</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAddOperation}><FontAwesomeIcon icon={faPlus} /></button></div>
            </div>
            <SPSLoader load={loading} />
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    <ul className="container">
                      {operations.map((r) =>(
                      <li className="row mb-2 alternate-color py-2" key={r.id} onClick={() => handleClickOperation(r.id)} >
                          <div className="col-8 col-md-8">{r.Title}</div>
                          <div className="col-4 col-md-4 text-start">{r.Zip} {r.City}</div>
                      </li>
                      ))}
                    </ul>
                    {liste_vide}
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAddOperation}>Créer une opération</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row footerToolbar px-0">
              <div className="col-6 text-center px-2">
                <select className="form-select text-center border-0 my-2" onChange={e => { SetFilter(e.target.value); }} value={currentFilter}>
                  <option value="MY">Mes opération</option>
                  <option value="ALL">Toutes les opérations</option>
                </select>
              </div>
              <div className="col-6 text-center px-2">
                <div className="input-group my-2">
                  <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                  <input type="text" className="form-control" value={currentSearchFilter} placeholder="Libelle, Code postal..." aria-describedby="basic-addon1"  onChange={e => { SetSearchFilter(e.target.value); }}/>
                </div>
                </div>
            </div>
          </div>
        );
        break;
    }
    
  }
  
}


export default Operations;
