import { useState, useEffect } from 'react';

function RecipientsList({contactsList, chooseContact, setRecipients}) {
    const [checkedContacts, setCheckedContacts] = useState([]);
    var contacts = [];

    useEffect(() => {
        for(var i=0; i < contactsList.length; i++)
        {
            contacts.push(contactsList[i]);
            contacts[i].checked = 'checked';
        }
        setCheckedContacts(contacts);
    },[contactsList]);

    useEffect(() => {
        var vContacts = [];
        for(var i=0; i < checkedContacts.length; i++)
        {
            if(checkedContacts[i].checked)
            {
                vContacts.push(checkedContacts[i].id);
            }
        }
         
        setRecipients(vContacts);
    },[checkedContacts]);
    

    const handleSelect = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;
        
          var contactsCopy = [...checkedContacts];
          var theContact = contactsCopy.filter((item) => item.id === value);
          if(theContact)
          {
            if (isChecked)
                theContact[0].checked = "checked";
            else
            theContact[0].checked = "";
          }
          setCheckedContacts(contactsCopy);
        
      };
    
    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;

        var contactsCopy = [...checkedContacts];
        for(var i=0; i < contactsCopy.length; i++)
        {
            contactsCopy[i].checked=isChecked?'checked':'';
        }
        setCheckedContacts(contactsCopy);

    };

    return(
    <ul className="container">
        <li className="row mb-2 py-2 fw-bold">
        <div className="col col-md-4" >
            <label>{chooseContact ? <input className="form-check-input me-1" type="checkbox" value="1" onChange={handleSelectAll}  />: ''}
            Identité</label>
        </div>
        <div className="col col-md-4">Société</div>
        <div className="col col-md-4">Email</div>
        </li>
        {checkedContacts.map((contact) =>(
        <li className="row mb-2 alternate-color py-2" key={contact.id}  >
            <div className="col-12 col-md-4" ><label>{chooseContact ? <input className="form-check-input" type="checkbox" value={contact.id} onChange={handleSelect} checked={contact.checked} />: ''}{contact.Civility} {contact.FirstName} {contact.Name}</label></div>
            <div className="col-12 col-md-4 ms-4 ms-md-0">{contact.company_Name}</div>
            <div className="col-12 col-md-4 ms-4 ms-md-0">{contact.Email}</div>
        </li>
        ))}
    </ul>);

}

export default RecipientsList;