import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XUsers = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "user", 
  headers : { Authorization : 'Bearer ' + token },
  timeout: 15000
});

const XAuth = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "auth", 
  headers : { Authorization : 'Bearer ' + token }
});


export async function SPSLogin(login, password, afterLogin)
{
  try {
    const body = {login: login, password: password};
    const res = await XAuth.post('/login/', body);
    console.log(JSON.stringify(res.data));
    if(res.data.token)
    {
      localStorage.setItem('userAuth', JSON.stringify(res.data));
      afterLogin();
    }
  }
  catch(err)
  {
    alert("Veuillez vérifier votre connexion internet!")
  }
}

export async function SPSLogout(afterLogout)
{
  try {
    const res = await XAuth.get('/logout/');
    if(res.data.logout)
    {
      localStorage.removeItem('userAuth');
      afterLogout();
    }
  }
  catch(err) {
    localStorage.removeItem('userAuth');
    afterLogout();
  }
}

export async function SPSGetUsers(SetUsers)
{
  try {
    const result = await XUsers.get('/');
    SetUsers(result.data);
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function SPSGetUser(id, SetUser)
{
  try {
    const result = await XUsers.get('/'+id);
    SetUser(result.data);
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function SPSDeleteUser(id, afterDelete)
{
  try {
    const result = await XUsers.delete('/'+id);
    afterDelete();
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function SPSSaveUser(newuser, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Active: newuser.Active, 
      Type: newuser.Type, 
      FirstName: newuser.FirstName, 
      Name: newuser.Name, 
      Poste: newuser.Poste, 
      Email: newuser.Email,
      MailSignature: newuser.MailSignature, 
      Password: newuser.Password
    };
    var result = [];
    try {
      if(newuser.id > 0)
      {
        result = await XUsers.put("/"+newuser.id, body);
        setShowForm(false);
      }
      else
      {
        result = await XUsers.post("/", body);
        setShowForm(false);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status = 1;
    }
    catch(err) {
      alert("Veuillez vérifier votre connexion internet !");
    }
  }
  else
    setShowForm(false);
  
}

export async function SPSUserQuickList(SetUsers)
{
  try {
    const result = await XUsers.get('/list/');
    SetUsers(result.data);
  }
  catch(err) {

  }
}

export async function SPSActionBadToken(actionKO)
{
  // si nous sommes en ligne on vérifie le token
  if(window.SpsServerAvailable)
  {
    var t = SPSAuthToken();
    if(t != "")
    {
      try {
        const result = await XUsers.get('/checktoken/');
        if(result.data.ok == 0)
        {
          actionKO();
        }
      }
      catch(err) {
        // si nous n'y parvenons pas, on passe en mode hors ligne
        window.SpsServerAvailable = false;
      }
    }
  }
}

export async function SPSSaveAccount(body, afterSave)
{
  if(window.SpsServerAvailable)
  {
    try {
      await XUsers.put("/account/", body);
      
      afterSave(0);
    }
    catch(err) {

    }
  }
}

export async function SPSGetAccount(loadAccount)
{
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XUsers.get("/account/");
        
      loadAccount(result.data);
    }
    catch(err) {

    }
  }
}

export async function SPSCheckNotification(setNotifications)
{
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XUsers.get("/notifications/");
        
      setNotifications(result.data);
    }
    catch(err) {

    }
  }
}

