import { SPSAuthToken } from './SPSSettings';
import { SPSBatch, SPSGetBatch, SPSStatusBatch, SPSDeleteBatch } from './SPSBatchs';

import { getCurrentDate  } from '../functions/tools';
import axios from "axios";
const token = SPSAuthToken();
const Xdatas = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "ic", 
  headers : { Authorization : 'Bearer ' + token }
});

var SetICCallBack = false;
var idICLocalInSaved = 0;
var dbTableNameIC = 'InspectionCommune';
let openRequestIC = indexedDB.open(dbTableNameIC, 1);
let db = null;

openRequestIC.onupgradeneeded = function(event) {
  // déclenche si le client n'avait pas de base de données
  // ...effectuer l'initialisation...
  db = openRequestIC.result;
  switch(event.oldVersion) { // version db existante
    case 0:
      // la version 0 signifie que le client n'avait pas de base de données
      // effectuer l'initialisation
      if (!db.objectStoreNames.contains(dbTableNameIC)) {
        // s'il n'y a pas de magasin "books"
        db.createObjectStore(dbTableNameIC, {keyPath: 'id'}); // créez-le
      }
    case 1:
      // le client avait la version 1
      // mettre à jour
     
  }
};

openRequestIC.onerror = function() {
  console.error("Error", openRequestIC.error);
};

openRequestIC.onsuccess = function() {
  console.log("db IC chargée");
  db = openRequestIC.result;
};

function SPSAddLocalIC(data)
{
  console.log("save local IC");
  let transaction = db.transaction(dbTableNameIC, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let ic = transaction.objectStore(dbTableNameIC); // (2)

  let request = ic.add(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("IC added to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSUpdateLocalIC(data)
{
  let transaction = db.transaction(dbTableNameIC, "readwrite"); // (1)
  transaction.oncomplete = (event) => {
   console.log("IC transaction OK");
  };
  
  transaction.onerror = (event) => {
    console.log("IC transaction KO : ".event);
  };
  // obtenir un magasin d'objets pour opérer dessus
  let ic = transaction.objectStore(dbTableNameIC); // (2)
  let request = ic.put(data); // (3)

  request.onsuccess = function() { // (4)
    console.log("IC updated to the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSDeleteLocalIC(id)
{
  let transaction = db.transaction(dbTableNameIC, "readwrite"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let ic = transaction.objectStore(dbTableNameIC); // (2)

  let request = ic.delete(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("IC deleted of the store", request.result);
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };
}

function SPSGetLocalIC(id, SetDatas)
{
  let transaction = db.transaction(dbTableNameIC, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let ic = transaction.objectStore(dbTableNameIC); // (2)

  let request = ic.get(id); // (3)

  request.onsuccess = function() { // (4)
    console.log("IC loaded of the store", request.result);
    SetDatas(request.result);
  };

  request.onerror = function() {
    console.log("Error ouverture local IC", request.error);
    SetDatas([]);
  };
}

/*************************************************************
 * SPSLocalST
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des arrets de tache contenues en local (localstorage)
 *************************************************************/
function SPSLocalICs(SetDatas)
{
  console.log(dbTableNameIC);
  let transaction = db.transaction(dbTableNameIC, "readonly"); // (1)

  // obtenir un magasin d'objets pour opérer dessus
  let ic = transaction.objectStore(dbTableNameIC); // (2)

  let request = ic.getAll(); // (3)

  request.onsuccess = function() { // (4)
    if (request.result !== undefined) {
      request.result.forEach((d) => {
        if(SPSStatusBatch("ICs", d.id)==0)
          d.local=true;
        else
          d.local = false;
      });
      SetDatas(request.result);
    } else {
      console.log("aucun IC local");
    }
  };

  request.onerror = function() {
    console.log("Error", request.error);
  };

}

export async function SPSSynchroICs(callback)
{
  console.log("SYNCHRO IC");
  // on ne lance un synchro que si le navigateur est en ligne...
  if(window.SpsServerAvailable)
  {
    console.log("SYNCHRO IC : ONLINE");
    // ensuite on ne lance une synchro que s'il y a des batchs à mettre à jour
    const batchs = SPSGetBatch('ICs');
    var idbatchs = [];
    for(var i=0; i < batchs.length; i++)
    {
      if(batchs[i].status==0)
      {
        idbatchs.push(batchs[i].id);
      }
    }
    console.log("SYNCHRO IC BATCHES : "+JSON.stringify(idbatchs));
    // nous avons plusieurs batchs à mettre à jour
    if(idbatchs.length > 0)
    {
      // il faut savoir si nous avons reellement une connexion internet
      try {
        const st = await Xdatas.get('/');
        if(st)
        {
          callback(1);
          // nous avons eu un retour donc on peut synchroniser
          let transaction = db.transaction(dbTableNameIC, "readonly"); // (1)

          let ic = transaction.objectStore(dbTableNameIC); // (2)

          let request = ic.getAll(); // (3)

          request.onsuccess = function() { // (4)
            if (request.result !== undefined) {
              const filtered = request.result.filter(i => idbatchs.includes(i['id']));
              filtered.forEach((d) => {
                SPSSaveIC(d, null, null, true, true);
                SPSDeleteLocalIC(d.id);
                SPSDeleteBatch('ICs', d.id);
              });
              console.log("SYNCHRO IC : TERMINE");
              callback(0);
            } 
          };

          request.onerror = function() {
            callback(-1);
            console.log("SYNCHRO IC ERROR : RECHERCHE LOCAL");
            console.log("Error", request.error);
          };
        }


      } catch(err) {
        // erreur donc pas de connexion on arrete la
        console.log("SYNCHRO IC ERROR : NO INTERNET : "+err);
        callback(-1);
      }
    }
  }
}

/*************************************************************
 * SPSICs
 * -----------------------------------------------------------
 * SetData : Callback ou envoyer les enregistrements
 * -----------------------------------------------------------
 * Charge les inpections communes depuis le serveur REST ou en Local 
 * si non connecté
 *************************************************************/
export async function SPSICs(SetData, type, search)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      var qs = 'type='+type;
      if(search)qs+= '&search='+search;
      const datas = await Xdatas.get("?"+qs);
      // attention, il ne faut pas écraser les visites locales qui ne sont pas enregistrées
      // il faut donc récupérer toutes les fiches qui sont en batch non enregistrées
      SPSLocalICs((localIC) => {
        var ICs = [];
        for(var i=0; i < localIC.length; i++)
        {
          if(SPSStatusBatch('ICs', localIC[i].id) == 0)
          {
            localIC[i].local = true;
            ICs.push(localIC[i]);
          }
          else
          {
            // on supprime cette version locale qui est peut etre oboslete
            SPSDeleteLocalIC(localIC[i].id);
          }
        }  
        ICs = ICs.concat(datas.data);
        SetData(ICs);
      });    
    }
    catch(err)
    {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  
  if(returnlocal)  
  {
    SPSLocalICs(SetData);
  }
}

/*************************************************************
 * SPSSaveLocalIC
 * -----------------------------------------------------------
 * IC : objet contenant l'inspection commune a enregistrer
 * SetData : Callback ou envoyer les données
 * -----------------------------------------------------------
 * Enregistre une inspection commune enregistrée en local vers le serveur
 *************************************************************/
async function SPSSaveLocalIC(IC, SetData)
{
  SetICCallBack = SetData;
  idICLocalInSaved = IC.id;
  SPSSaveIC(IC, SPSAfterSavedLocalIC, false, false);
}

/*************************************************************
 * SPSLocalICs
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des inspections communes contenues en local (localstorage)
 *************************************************************/
/*function SPSLocalICs()
{
  var datas = [];
  const ls = localStorage.getItem('ICs');
  if(ls)
  {
    
    try {
      datas = JSON.parse(ls);
    } catch (e) {
      datas = [];
    }
  }
  if(!datas || !datas.length || datas[0].id == undefined) 
    datas = [];

  return datas;
}*/

/*************************************************************
 * SPSStoreLocalICs
 * -----------------------------------------------------------
 * data : tableau des inspections communes
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
/*function SPSStoreLocalICs(data)
{
  localStorage.setItem('ICs', JSON.stringify(data));
}*/

/*************************************************************
 * SPSAfterSavedLocalIC
 * -----------------------------------------------------------
 * id : id de l'inspection commune fraichement enregistrée
 * -----------------------------------------------------------
 * nettoie les batch et enlève l'enregistrement temporaire en local
 * Ensuite charge l'inspection commune pour l'afficher
 *************************************************************/
function SPSAfterSavedLocalIC(id)
{
  // il faut nettoyer les batchs et les enregistrements locaux
  SPSDeleteBatch('ICs', idICLocalInSaved);
  // suppression de la fiche de cet ID en local
  SPSDeleteLocalIC(idICLocalInSaved);
  SPSGetIC(id, SetICCallBack);
}

/*************************************************************
 * SPSGetIC
 * -----------------------------------------------------------
 * id : id de l'inspection commune à charger
 * SetData : Callback à appeler suite au chargement des données
 * -----------------------------------------------------------
 * Charge a visite depuis le serveur ou en local si le périphérique 
 * n'est pas connecté
 *************************************************************/
export async function SPSGetIC(id, SetData)
{
  var storelocal = false;
  var modeoffline = false;
  if(!window.SpsServerAvailable)
    modeoffline = true;
  
  if(id > 0)
  {
    const status = SPSStatusBatch('ICs', id);
    if(status == 0) // si c'est egale à 0, cela veut dire que la derniere version est en local 
    {
      storelocal = true;
    }
  }
  else  // l'Id est negatif donc c'est du local
    storelocal = true;

  
  if(window.SpsServerAvailable && storelocal == false)
  {
    // recherche du batch de cette visite
    const status = SPSStatusBatch('ICs', id);
    // si le status est à 0 cela veut dire qu'il existe une version non enregistrée de la visite en local
    if(status == 0)
    {
      storelocal = true;
    }
    else
    {
      try {
        const ics = await Xdatas.get('/'+id);
        // recherche cette visite dans le JSON local pour l'avoir en local
        SPSGetLocalIC(id, (datas) => {
          if(!datas)
            SPSAddLocalIC(ics.data[0]); //temp.push(datas.data[0]);
          else
            SPSUpdateLocalIC(ics.data[0]); //temp[index] = datas.data[0];
          
          SetData(ics.data[0]);
        });
      }
      catch(err) {
        console.log(err);
        storelocal = true;
        modeoffline = true;
      }
    }
  }
  else
    storelocal = true;
  
  if(storelocal)
  {
    SPSGetLocalIC(id, (datas) => {
      if(datas && datas.id)
      {
        if(!modeoffline)
        {
          console.log("enregistrement sur le Web ")
          SPSSaveLocalIC(datas, SetData);
        }
        else
        SetData(datas);
      }
      else
        SetData([]);
    });
    
  }
  
  
}

export async function SPSGetSiteICs(idSite, setData)
{
  const ic = await Xdatas.get('/site/'+idSite);
  setData(ic.data);
}

export async function SPSDownloadRapportIC(IdIC)
{
  const pdf = await Xdatas.get('/rapport/?id='+IdIC, {responseType: 'blob'});
  const url = URL.createObjectURL(new Blob([pdf.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inspection_commune.pdf");
      link.click();
}

export function SPSEmptyIC()
{
  return {id:0,IdSite: 0,IdCompany: "",IdRepresentant: 0, DateIC: getCurrentDate('-') ,
    VisiteRealisee_O_N: 0,
    TravauxARealiser: "",
    TravauxRisquesParticuliers_O_N: 0,
    EffectifSalarieMin: 0,
    EffectifSalarieMax: 0,
    TravauxSousTraites_O_N: 0,
    TravauxSousTraites: "",
    DateInterventionDepart: "",
    DateInterventionFin: "",
    HeureDepartChantier: "",
    HeureFinChantier: "",
    TravailNuit: 0,
    EntreprisePossessionPGCSPS_O_N: 0,
    PGCSPS_TransmisSousTraitant: 0,
    PPSPSRequis_O_N: 0,
    PPSPSATransmettre_O_N: 0,
    PPSPSRemisLe: "",
    CHSCT_O_N: 0,
    SecouristeChantier_O_N: 0,
    TelChantier_O_N: 0,
    CantonnementPropre_O_N: 0,
    LocauxCommunsChargeDe: "",
    Sanitaire_O_N: 0,
    Vestiaires_O_N: 0,
    Refectoires_O_N: 0,
    Douches_O_N: 0,
    LocalStockage_O_N: 0,
    AireStockage_O_N: 0,
    LocalisationStockage: "",
    PlanChantier_O_N: 0,
    ControleAccesChantier_O_N: 0,
    InstallationElectrique_O_N: 0,
    TypeLivraison: [],
    TypeManutention: [],
    Echafaudages: [],
    Montage: [],
    PlateformeIndividuelle: [],
    Nacelles: [],
    AutresEquipements: [],
    PermisSpeciaux_O_N: 0,
    Caces_O_N: 0,
    LevageSousLoue_O_N: 0,
    LevageSociété: "",
    TravauxPointsChauds: 0,
    PrevoirExtincteurs: 0,
    TravauxMilieuConfine: 0,
    MicroParticules_O_N: 0,
    SitutationSanitaireSpecifique_O_N: 0,
    DechetTraitementSpecifique: 0,
    AppliquerMesuresPGC_O_N: 0,
    EPIObligatoire_O_N: 0,
    BalisageTravauxStockage_O_N: 0,
    DispoPPSPS_O_N: 0,
    ClasseurRegistrePersonnel_O_N: 0,
    CartesProBTP_O_N: 0,
    PermisFeu: 0,
    Extincteurs: 0,
    BouteilleGaz: 0,
    ArretTravauxPC: 0,
    ObligationFormation_O_N: 0,
    ObligationFormationLibelle: "",
    ObligationSurveillanceMedicale: "",
    NecessiteParticiperCISSCT: 0,
    SignatureCoordonateur: "",
    SignatureRepresentant: "",
    SignatureAutreParticipant: "",
    AutreParticipant: "",
    NomAutreParticipant: "",
    Rapport: "",
    Remarque: ""}
}

export function SPSBasicIC(theIC, SetData)
{
  // ne récupère que les éléments de base de l'objet. 
  // enlève les liaisons sur les autres tables
  var vIC = {};
  vIC.id = theIC.id;
  vIC.IdSite = theIC.IdSite;
  vIC.IdCompany = theIC.IdCompany;
  vIC.IdRepresentant = theIC.IdRepresentant;
  vIC.DateIC = theIC.DateIC;
  vIC.VisiteRealisee_O_N = theIC.VisiteRealisee_O_N;
  vIC.TravauxARealiser = theIC.TravauxARealiser;
  vIC.TravauxRisquesParticuliers_O_N = theIC.TravauxRisquesParticuliers_O_N;
  vIC.EffectifSalarieMin = theIC.EffectifSalarieMin;
  vIC.EffectifSalarieMax = theIC.EffectifSalarieMax;
  vIC.TravauxSousTraites_O_N = theIC.TravauxSousTraites_O_N;
  vIC.TravauxSousTraites = theIC.TravauxSousTraites;
  vIC.DateInterventionDepart = theIC.DateInterventionDepart;
  vIC.DateInterventionFin = theIC.DateInterventionFin;
  vIC.HeureDepartChantier = theIC.HeureDepartChantier;
  vIC.HeureFinChantier = theIC.HeureFinChantier;
  vIC.TravailNuit = theIC.TravailNuit;
  vIC.EntreprisePossessionPGCSPS_O_N = theIC.EntreprisePossessionPGCSPS_O_N;
  vIC.PGCSPS_TransmisSousTraitant = theIC.PGCSPS_TransmisSousTraitant;
  vIC.PPSPSRequis_O_N = theIC.PPSPSRequis_O_N;
  vIC.PPSPSATransmettre_O_N = theIC.PPSPSATransmettre_O_N;
  vIC.PPSPSRemisLe = theIC.PPSPSRemisLe;
  vIC.CHSCT_O_N = theIC.CHSCT_O_N;
  vIC.SecouristeChantier_O_N = theIC.SecouristeChantier_O_N;
  vIC.TelChantier_O_N = theIC.TelChantier_O_N;
  vIC.CantonnementPropre_O_N = theIC.CantonnementPropre_O_N;
  vIC.LocauxCommunsChargeDe = theIC.CantonnementPropre_O_N;
  vIC.Sanitaire_O_N = theIC.Sanitaire_O_N;
  vIC.Vestiaires_O_N = theIC.Vestiaires_O_N;
  vIC.Refectoires_O_N = theIC.Refectoires_O_N;
  vIC.Douches_O_N = theIC.Douches_O_N;
  vIC.LocalStockage_O_N = theIC.LocalStockage_O_N;
  vIC.AireStockage_O_N = theIC.AireStockage_O_N;
  vIC.LocalisationStockage = theIC.LocalisationStockage;
  vIC.PlanChantier_O_N = theIC.PlanChantier_O_N;
  vIC.ControleAccesChantier_O_N = theIC.ControleAccesChantier_O_N;
  vIC.InstallationElectrique_O_N = theIC.InstallationElectrique_O_N;
  vIC.TypeLivraison = theIC.TypeLivraison;
  vIC.TypeManutention = theIC.TypeManutention;
  vIC.Echafaudages = theIC.Echafaudages;
  vIC.Montage = theIC.Montage;
  vIC.PlateformeIndividuelle = theIC.PlateformeIndividuelle;
  vIC.Nacelles = theIC.Nacelles;
  vIC.AutresEquipements = theIC.AutresEquipements;
  vIC.PermisSpeciaux_O_N = theIC.PermisSpeciaux_O_N;
  vIC.Caces_O_N = theIC.Caces_O_N;
  vIC.LevageSousLoue_O_N = theIC.LevageSousLoue_O_N;
  vIC.LevageSociété = theIC.LevageSociété;
  vIC.TravauxPointsChauds = theIC.TravauxPointsChauds;
  vIC.PrevoirExtincteurs = theIC.PrevoirExtincteurs;
  vIC.TravauxMilieuConfine = theIC.TravauxMilieuConfine;
  vIC.MicroParticules_O_N = theIC.MicroParticules_O_N;
  vIC.SitutationSanitaireSpecifique_O_N = theIC.SitutationSanitaireSpecifique_O_N;
  vIC.DechetTraitementSpecifique = theIC.DechetTraitementSpecifique;
  vIC.AppliquerMesuresPGC_O_N = theIC.AppliquerMesuresPGC_O_N;
  vIC.EPIObligatoire_O_N = theIC.EPIObligatoire_O_N;
  vIC.BalisageTravauxStockage_O_N = theIC.BalisageTravauxStockage_O_N;
  vIC.DispoPPSPS_O_N = theIC.DispoPPSPS_O_N;
  vIC.ClasseurRegistrePersonnel_O_N = theIC.ClasseurRegistrePersonnel_O_N;
  vIC.CartesProBTP_O_N = theIC.CartesProBTP_O_N;
  vIC.PermisFeu = theIC.PermisFeu;
  vIC.Extincteurs = theIC.Extincteurs;
  vIC.BouteilleGaz = theIC.BouteilleGaz;
  vIC.ArretTravauxPC = theIC.ArretTravauxPC;
  vIC.ObligationFormation_O_N = theIC.ObligationFormation_O_N;
  vIC.ObligationFormationLibelle = theIC.ObligationFormationLibelle;
  vIC.ObligationSurveillanceMedicale = theIC.ObligationSurveillanceMedicale;
  vIC.NecessiteParticiperCISSCT = theIC.NecessiteParticiperCISSCT;
  vIC.SignatureCoordonateur = theIC.SignatureCoordonateur;
  vIC.SignatureRepresentant = theIC.SignatureRepresentant;
  vIC.SignatureAutreParticipant = theIC.SignatureAutreParticipant;
  vIC.AutreParticipant = theIC.AutreParticipant;
  vIC.NomAutreParticipant = theIC.NomAutreParticipant;
  vIC.Rapport = theIC.Rapport;
  vIC.Remarque = theIC.Remarque;

  SetData(vIC);
}

/*************************************************************
 * findIC
 * -----------------------------------------------------------
 * id : id de l'inspection à chercher
 * -----------------------------------------------------------
 * Cherche l'index de l'inspection dans le localstorage
 *************************************************************/
function findIC(id)
{
  const temp = SPSLocalICs();
  let index = -1;
  for(var i = 0; i < temp.length && index < 0; i++)
  {
    if(temp[i].id == id)
      index = i;
  }

  return index;
}

/*************************************************************
 * SPSDeleteIC
 * -----------------------------------------------------------
 * id : id de l'inspection à supprimer
 * afterDelete : Callback à appeler après la suppression
 * -----------------------------------------------------------
 * Supprime une inspection sur le serveur
 *************************************************************/
export async function SPSDeleteIC(id, afterDelete)
{
  if(id > 0)
  {
    if(window.SpsServerAvailable)
    {
      try {
        const result = await Xdatas.delete("/"+id);
      } catch(err) {
        alert("Vous devez être connecté pour utiliser cette fonction!");
      }
    }
    else
      alert("Vous devez être connecté pour utiliser cette fonction!");
  }
  //else
  //{
    SPSDeleteLocalIC(id);
  //}

  // il faut supprimer les batchs de cette visite
  SPSDeleteBatch("ICs", id);

  afterDelete();
}

/*************************************************************
 * SPSSaveIC
 * -----------------------------------------------------------
 * newData : objet de l'inspection à enregistrer (Create / Update)
 * setIntertedId : Callback à appeler avec l'Id nouvellement créé
 * setShowForm : Callback à appeler pour masquer une interface
 * -----------------------------------------------------------
 * Enregistre l'inspection envoyée en paramètre 
 * (en local ou sur le serveur suivant l'état de la connexion)
 *************************************************************/
export async function SPSSaveIC(newData, setIntertedId, setShowForm, includeRapport, onlineuniquement)
{
  var status = 0;
  if(!includeRapport)
    newData.Rapport = '';
  
  var storelocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      // si l'utilisateur est connecté, on met à jour les données sur le serveur
      var result = [];
      if(newData.id > 0)
      {
        result = await Xdatas.put("/"+newData.id, newData);
        setIntertedId(newData.id);
        if(setShowForm)
          setShowForm(0);
      }
      else
      {
        result = await Xdatas.post("/", newData);
        setIntertedId(result.data[0].id);
        if(setShowForm)
          setShowForm(0);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status = 1;
    }
    catch(err)
    {
      storelocal = true;
    }
  }
  else
    storelocal = true;
  
  if(storelocal)
  {
    if(onlineuniquement)
    {
      // si on est en mode online uniquement, on se contente de charger le local à l'affichage
      if(SetICCallBack)
        SetICCallBack(newData);
    }
    else
    {
      if(newData.id == 0)
      {
        newData.id = (new Date().getTime()) * -1;
        newData.local = true;
        SPSAddLocalIC(newData);
        if(setShowForm)
          setShowForm(0);
        SPSBatch("ICs", newData.id, status);
      }
      else
      {
        // autrement on cherche s'il existe en local 
        SPSGetLocalIC(newData.id, (datas) => {
          if(datas.id)
            SPSUpdateLocalIC(newData);
          else
            SPSAddLocalIC(newData);
            if(setShowForm)
              setShowForm(0);
          SPSBatch("ICs", newData.id, status);
        });
      }
    }
    
  }

  SPSBatch("ICs", newData.id, status);
}

/*************************************************************
 * SPSSendRapportIC
 * -----------------------------------------------------------
 * IdVisit : id de l'inspection
 * -----------------------------------------------------------
 * Demande au serveur d'envoyer le rapport d'inscpection' concerné
 *************************************************************/
export async function SPSSendRapportIC(Id, afterSent)
{
  if(window.SpsServerAvailable)
  {
    var result = [];
    result = await Xdatas.get("/sendrapport/?id="+Id);
    
    afterSent();
  }
  else
    alert("Vous devez être connecté pour utiliser cette fonction!");
}

export async function SPSMakeRapportIC(Id, afterMake)
{
  if(window.SpsServerAvailable)
  {
    const result = await Xdatas.get("/makerapport/?id="+Id);
    afterMake(result.data.rapport);
  }
  else
    alert("Vous devez être connecté pour utiliser cette fonction!");
}
