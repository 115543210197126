import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faPaperPlane, faTrashCan,  faCamera, faPencil} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {SPSGetAccount, SPSSaveAccount} from '../functions/SPSUsers';
import SPSLoader from './SPSLoader';
import { Editor } from '@tinymce/tinymce-react';
import Modal from 'react-bootstrap/Modal';
import SignatureCanvas from 'react-signature-canvas'



function Account(props) {

  const editorRef = useRef(null);
  const [showSignature, setShowSignature] = useState(false);
  const [dataLoaded, setDataloaded] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [Name, setName] = useState("");
  const [Poste, setPoste] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Signature, setSignature] = useState("");
  const [MailSignature, setMailSignature] = useState("");
  const [MailSignatureOrig, setMailSignatureOrig] = useState("");

  var sigCanvas = {};

  let [loading, setLoading] = useState(false);

  const loadAccount = (data) => {
    setFirstName(data.FirstName);
    setName(data.Name);
    setPoste(data.Poste);
    setEmail(data.Email);
    setPhone(data.Phone);
    setSignature(data.Signature);
    setMailSignature(data.MailSignature);
    setMailSignatureOrig(data.MailSignature);
    setDataloaded(true);
    //setLoading(false);
  };

  if(dataLoaded == false)
  {
    //setLoading(true);
    SPSGetAccount(loadAccount);
  }

  

  const handleBack = (event) => {
    props.screenHandling(0);
  };
  
  const handleClosedSignature = () => {
    setShowSignature(false);
  }

  const handleValideSignature = () => {
    const data = sigCanvas.toDataURL('image/png');
    setSignature(data);
    setShowSignature(false);
  }

  const handleSigner = (type) => {
    setShowSignature(true);
  }

  const handleChangeSignatureMail = (value, editor) => {
    setMailSignature(value);
  };


  const handleSubmit = () => {
    const data = {FirstName: FirstName, Name: Name, Poste: Poste, Email: Email, Phone: Phone, Signature: Signature, MailSignature: MailSignature};
    SPSSaveAccount(data, afterSave);
  };

  const afterSave = () => {
    props.screenHandling(0);
  };
  
    
        return (
          <div className="containerxl">
            <div className="row headScreen px-0">
              <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" type="button" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6"><h1 className="h4 py-3">Mon compte</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <SPSLoader load={loading} />
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="p-3">
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Prénom</label>
                    <div className="col-12 col-md-9">
                        <input type="text" name="Firstname" value={FirstName} className="form-control" onChange={e => { setFirstName(e.target.value); }}/>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Nom</label>
                    <div className="col-12 col-md-9">
                        <input type="text" name="Name" value={Name} className="form-control" onChange={e => { setName(e.target.value); }}/>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Poste</label>
                    <div className="col-12 col-md-9">
                        <input type="text" name="Poste" value={Poste} className="form-control" onChange={e => { setPoste(e.target.value); }}/>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Email</label>
                    <div className="col-12 col-md-9">
                        <input type="email" name="Email" value={Email} className="form-control" onChange={e => { setEmail(e.target.value); }}/>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Téléphone</label>
                    <div className="col-12 col-md-9">
                        <input type="text" name="Phone" value={Phone} className="form-control" onChange={e => { setPhone(e.target.value); }}/>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Signature</label>
                    <div className="col-12 col-md-9">
                      <div className="border mb-2 p-2 pos-relative">
                        
                        <button type="button" className="btn btn-secondary pos-top-right me-2 mt-2" onClick={() => handleSigner()}> Signer</button>
                        {Signature !== "" ? <img onClick={() => handleSigner()} src={Signature} className="mw-100"/> : <label className="pasSignature" onClick={() => handleSigner()}>Aucune signature</label>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-12 col-md-3">Signature Email</label>
                    <div className="col-12 col-md-9">
                    <Editor
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={MailSignatureOrig}
                          init={{
                              height: 400,
                              menubar: true,
                              plugins: [
                              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                              'searchreplace', 'visualblocks', 'code', 'fullscreen',
                              'insertdatetime', 'media', 'table', 'help', 'wordcount'
                              ],
                              toolbar: 'formatselect | ' +
                              'bold italic underline backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                              'removeformat | help',
                          }}
                          tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                          onEditorChange={handleChangeSignatureMail}
                      />
                    </div>
                  </div>
                  
                  
                </div>
                  
              </form>
            </div>
            <Modal show={showSignature} onHide={handleClosedSignature} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                <Modal.Title>Signature</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <SignatureCanvas canvasProps={{height: 500, className: 'sigCanvas'}} ref={(ref) => { sigCanvas = ref }} />
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClosedSignature}>Annuler</button>
                    <button type="button" className="btn btn-primary" onClick={handleValideSignature}>Valider</button>
                </Modal.Footer>
            </Modal>  
          </div>
        );
        
  
}

export default Account;
