import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XRequest = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "companytype", 
  headers : { Authorization : 'Bearer ' + token }
});


export async function SPSCompanyTypes(SetValues)
{
  const result = await XRequest.get('');
  SetValues(result.data);
  
}

export async function SPSGetCompanyType(id, SetValues)
{
  const result = await XRequest.get(id);
  SetValues(result.data[0]);
  
}

export async function SPSSaveCompanyType(newcompanyType, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Label: newcompanyType.Label,
    };
    var result = [];
    if(newcompanyType.id > 0)
    {
      result = await XRequest.put("/"+newcompanyType.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XRequest.post("/", body);
      setShowForm(false);
    }
    if(result.data.length > 0 && result.data[0].id != undefined)
      status = 1;
  }
  else
    setShowForm(false);
  
  
}

export async function SPSDeleteCompanyType(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    await XRequest.delete("/"+id);
    afterDelete();
  }
}


