import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import SiteVisit from './components/SiteVisit';
import Settings from './components/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPersonDigging, faGear} from '@fortawesome/free-solid-svg-icons'
import { SPSLoadSites } from './functions/SPSSites';
import { SPSLoadMemos } from './functions/SPSSettings';
import { SPSLoadCompanies } from './functions/SPSCompany';
import { useEffect, useState } from 'react';

function App() {
  
  const userDatas = localStorage.getItem('userAuth');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  window.SpsServerAvailable = navigator.onLine;

  useEffect(() => {
    window.addEventListener('offline', handleStatusChange);
      
    window.addEventListener('online', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  
  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
    window.SpsServerAvailable = navigator.onLine;  

    console.log(navigator.onLine?'online':'offline');
  }
  // chargement des operations en cache
  SPSLoadSites();
  SPSLoadMemos();
  SPSLoadCompanies();
  
    /*let userToken = '';
    if(userDatas)
    {
        userToken = JSON.parse(userDatas).token;
    }
    if(userToken)
    {*/
      return (
        <div className="App">
          <header className="App-header">
          <nav className="navbar navbar-expand-md fixed-bottom bg-light d-none">
            <div className="container-fluid">
              <div className="navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/"><FontAwesomeIcon icon={faHouse} /></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/visite-chantier"><FontAwesomeIcon icon={faPersonDigging} /></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/reglages"><FontAwesomeIcon icon={faGear} /></a>
                  </li>
                </ul>
              </div>  
            </div>
          </nav>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/visite-chantier" element={<SiteVisit />} />
                <Route path="/reglages" element={<Settings />} />
              </Routes>
            </BrowserRouter>
          </header>
        </div>
      );
    /*}
    else
    {
      return (
        <div className="App">
          <header className="App-header">
            <Login props/>
            
          </header>
        </div>
      
      );
    }*/
}



export default App;
