import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XRequest = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "memo", 
  headers : { Authorization : 'Bearer ' + token }
});
const XRequestCat = axios.create({
    baseURL: process.env.REACT_APP_USER_API_BASE_URL + "catmemo", 
    headers : { Authorization : 'Bearer ' + token }
  });


export async function SPSMemos(SetValues)
{
  if(window.SpsServerAvailable)
  {
    try {
    const result = await XRequest.get();
    SetValues(result.data);
    }
    catch(err) {
      SetValues([]);
    }
  }
  else
    SetValues([]);
}

export async function SPSGetMemo(id, SetValues)
{
  try {
    const result = await XRequest.get(id);
    SetValues(result.data[0]);
  }
  catch(err)
  {
    SetValues([]);
  }
  
}

export async function SPSSaveMemo(newmemo, afterSave)
{
  if(window.SpsServerAvailable)
  {
    try{
      var status = 0;
      // si l'utilisateur est connecté, on met à jour les données sur le serveur
      const body = {
        IdCatMemo: newmemo.IdCatMemo,
        Type: newmemo.Type,
        Descriptif: newmemo.Descriptif,
        documents: newmemo.documents,
        newdocs: newmemo.newdocs,
      };
      var result = [];
      if(newmemo.id > 0)
      {
        result = await XRequest.put("/"+newmemo.id, body);
        afterSave();
      }
      else
      {
        result = await XRequest.post("/", body);
        afterSave();
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status= 1;
    }
    catch(err)
    {
      alert("Pas de connection internet disponible. Enregistrement impossible !");
      afterSave();
    }
  }
  else
    afterSave();
  
  
}

export async function SPSDeleteMemo(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    try {
      await XRequest.delete("/"+id);
      afterDelete();
    }
    catch(err)
    {
      afterDelete();
    }
  }
  else
    afterDelete();
}

export async function SPSCatMemos(SetValues)
{
  try {
  const result = await XRequestCat.get();
  SetValues(result.data);
  }
  catch(err) {
    SetValues([]);
  }
  
}

export async function SPSGetCatMemo(id, SetValues)
{
  try {
    const result = await XRequestCat.get(id);
    SetValues(result.data[0]);
  }
  catch(err) {
    SetValues([]);
  }
}

export async function SPSSaveCatMemo(newmemo, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    try {
      var status = 0;
      // si l'utilisateur est connecté, on met à jour les données sur le serveur
      const body = {
        Libelle: newmemo.Libelle,
        
      };
      var result = [];
      if(newmemo.id > 0)
      {
        result = await XRequestCat.put("/"+newmemo.id, body);
        setShowForm(false);
      }
      else
      {
        result = await XRequestCat.post("/", body);
        setShowForm(false);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status = 1;
    }
    catch(err) {
      setShowForm(false);
    }
  }
  else
    setShowForm(false);
  
  
}

export async function SPSDeleteCatMemo(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XRequestCat.delete("/"+id);
      if(result.data.length > 0 && result.data[0].error != undefined)
        afterDelete(result.data[0].error);
      else
      afterDelete();
    }
    catch(err)
    {
      afterDelete();
    }
  }
}

export function PathDocumentMemo(idmemo, ref)
{
  return process.env.REACT_APP_USER_API_BASE_URL + "memo/"+idmemo+"/document/"+ref+"?token="+token;
}


