import { useState } from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFilePdf} from '@fortawesome/free-solid-svg-icons'
import { PathDocumentMemo } from '../functions/SPSMemos';


function MemoDocuments({idmemo, documents}) {
    const [showModal, setShowModal] = useState(false);
    const [docs, setDocs] = useState(documents);
    const [docToShow, setDocToShow] = useState('');
    const handleClosed = () => {
        setDocToShow('');
    }

    useEffect(() => {
        if(docToShow != '')
            setShowModal(true);
        else
            setShowModal(false); 
        
    },[docToShow]);
   
    return(
        <div>
            <div className="pjmemos">
                <hr />
                <ul  className="row">
                    {docs.map((doc) =>(
                    <li className="col text-center">
                    {doc.substr(-3) == 'pdf' ?
                        <a onClick={() => {setDocToShow(doc)}}><FontAwesomeIcon  icon={faFilePdf} /></a>
                        :
                        <a onClick={() => {setDocToShow(doc)}}><img src={PathDocumentMemo(idmemo, doc)}  onClick={() => {setDocToShow(doc)}}/></a>
                    }
                    </li>
                    ))}
                </ul>
            </div>
            <Modal show={showModal} onHide={handleClosed} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
    
                <Modal.Body>
                    {docToShow.substr(-3) == 'pdf'?
                        <object data={PathDocumentMemo(idmemo, docToShow)} type="application/pdf" width="100%" height="500px">
                        <p>Imposible d'ouvrir le fichier <a href={PathDocumentMemo(idmemo, docToShow)}>Téléchargez-le</a>.</p>
                      </object>
                    :
                        <img src={PathDocumentMemo(idmemo, docToShow)}  />
                    }
                </Modal.Body>
    
                
            </Modal>
        </div>
    );
}



export default MemoDocuments;