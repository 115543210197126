import React, { Component } from 'react' 
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'

export class SettingsPhoto extends React.Component {  

  constructor(props) {  
    super(props);  
    this.props = props;
    this.state = { width: 0 };
    this.state = { height: 0 };
    this.handleSettingsPhoto = (event) => {
        event.preventDefault();
        // enregistre tous les paramètres
        axios.post(process.env.REACT_APP_USER_API_BASE_URL + "settings/PHOTO_RESOLUTION_X", { Value: this.state.width }).then(res => {
            alert("X saved");
        }) ;
        axios.post(process.env.REACT_APP_USER_API_BASE_URL + "settings/PHOTO_RESOLUTION_Y", { Value: this.state.height }).then(res => {
            alert("Y saved");
        }) ;
    }

    this.handleRetour = (event) => {
        this.props.parentStateModifier({"showSettingsPhoto" : false} );
    }

    axios.get(process.env.REACT_APP_USER_API_BASE_URL + "settings/PHOTO_RESOLUTION_X")
      .then((res) => {
        //setData(res.data)
      })

    
  }  
  
   

  render() {  
    return (  
        <div className="full-screen text-start">
            <div className="container">
            <div className="row headScreen mb-4">
                <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
                <div className="col-6 text-center"><h1 className="h4 py-3">Photos</h1></div>
                <div className="col-3 text-end py-2"></div>
            </div>
            <form action="submit"  onSubmit={this.handleSettingsPhoto}>
                <div className="form-group row">
                    <label className="col-md-3 text-left">Résolution horizontale</label>
                    <div className="col-md-9"><input type="number" className="form-control" name="width" onChange={e => { this.state.width = e.target.value; }}/></div>
                </div>
                <div className="form-group row">
                    <label className="col-md-3 text-left">Résolution verticale</label>
                    <div className="col-md-9"><input type="password" className="form-control" name="password" onChange={e => { this.state.height = e.target.value; }}/></div>
                </div>
                <button className="btn btn-primary" type="submit" >Enregistrer</button>
            </form>
            </div>
        </div>
    );  
  }  
}

export default SettingsPhoto;
