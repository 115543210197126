import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate  } from 'react-router-dom';
import { SPSLogin } from "../functions/SPSUsers";

const Login = (props) => { 
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const handleLogin = (event) => {
    event.preventDefault();
    SPSLogin(login, password, props.screenHandling);
    /*axios.post(process.env.REACT_APP_USER_API_BASE_URL + "auth/login", { login: login, password: password }).then(res => {
      localStorage.setItem('userAuth', JSON.stringify(res.data));
      setIsRedirect(true);
    })*/
    
  };

  const [isRedirect, setIsRedirect] = useState(false);
  
  useEffect(() => {
    //redirectToHome();
  }, [isRedirect]);
  
  
  
  const loginOk = () => {
    setIsRedirect(true);
    props.screenHandling(0);
  };

  return (
    <div className="container">
      <img src="/images/acb.png"/>
      <h1 className="my-3">Identification</h1>
      <form action="submit" onSubmit={handleLogin}>
          <input type="text" className="form-control mb-2" name="login" onChange={e => { setLogin(e.target.value); }}/>
          <input type="password" className="form-control mb-2" name="password" onChange={e => { setPassword(e.target.value); }}/>

          <button className="btn btn-primary" type="submit" >Connexion</button>
      </form>
    </div>
  );
}



export function LoadScreen(page)
{
  //Navigate(page);
}

export default Login;
