/*************************************************************
 * SPSLocalBatchs
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des batches contenues en local (localstorage)
 *************************************************************/
function SPSLocalBatchs()
{
  var datas = [];
  const ls = localStorage.getItem('batchs');
  if(ls)
  {
    datas = JSON.parse(ls);
  }
  return datas;
}

/*************************************************************
 * SPSStoreLocalBatchs
 * -----------------------------------------------------------
 * data : tableau des batchs
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
function SPSStoreLocalBatchs(data)
{
  localStorage.setItem('batchs', JSON.stringify(data));
}

/*************************************************************
 * SPSBatch
 * -----------------------------------------------------------
 * table : table concernée par le batch
 * id : identifiant dans la table de l'enregistrement
 * status : status à enregistrer pour ce batch 
 *           - 0 : non enregistrée sur le serveur
 *           - 1 : enregistré sur le serveur
 * -----------------------------------------------------------
 * Enregistre en localstorage le batch envoyé
 *************************************************************/
export function SPSBatch(table, id, status)
{
    const date = new Date();
    const dateformat = date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
    var updated = false;
    var batchs = SPSLocalBatchs();
   
    if(batchs && batchs.length > 0)
    {
        const v = batchs.filter((batch) => batch.table == table && batch.id == id);
        if(v.length > 0)
        {
            v[0].status = status;
            v[0].date = dateformat;
            updated = true;
        }
    }
   
    if(!updated)
    {
       console.log("Ajoutdu batche : "+JSON.stringify({table:table, id:id, status:status, date:dateformat}));
        batchs.push({table:table, id:id, status:status, date:dateformat});
    }
    SPSStoreLocalBatchs(batchs);
}

/*************************************************************
 * SPSStatusBatch
 * -----------------------------------------------------------
 * table : table concernée par le batch
 * id : identifiant dans la table 
 * -----------------------------------------------------------
 * Retourne le status de l'id pour cette table :
 *           - 0 : non enregistrée sur le serveur
 *           - 1 : enregistré sur le serveur
 *************************************************************/
export function SPSStatusBatch(table, id)
{
    var status = -1;
   
    const batchs = SPSLocalBatchs();
    const v = batchs.filter((batch) => batch.table == table && batch.id == id);
    if(v.length > 0)
    {
        status = v[0].status;
    }
    return status;
}

/*************************************************************
 * SPSDeleteBatch
 * -----------------------------------------------------------
 * table : table concernée par le batch
 * id : identifiant dans la table 
 * -----------------------------------------------------------
 * Détruit l'enregistrement  bach en localstorage
 *************************************************************/
export function SPSDeleteBatch(table, id)
{
    const batchs = SPSLocalBatchs();
    if(batchs && batchs.length > 0)
    {
        const newbatchs = batchs.filter((batch) => batch.table != table || batch.id != id);
        SPSStoreLocalBatchs(newbatchs);
    }
}

/*************************************************************
 * SPSGetBatch
 * -----------------------------------------------------------
 * table : table concernée par le batch
 * -----------------------------------------------------------
 * retourne les batchs d'une table
 *************************************************************/
export function SPSGetBatch(table)
{
    var retour = [];
    var batchs = SPSLocalBatchs();
    
    if(batchs && batchs.length > 0)
    {
        const v = batchs.filter((batch) => batch.table == table);
        retour = [...v];
    }

    return retour;
}