import { useState } from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import {SPSRequestSites} from "../functions/SPSSites";

function ModalSearchSite({showModal, setSiteId, handleClosed}) {
    const [show, setShow] = useState(showModal);
    const [sites, SetSites] = useState(Array());
    const handleClose = () => { setShow(false); handleClosed()}
    useEffect(() => {
        setShow(showModal);
    },[showModal]);

    const [search, setSearch] = useState();

    useEffect(() => {
        handleSearch();
    },[search]);

    const handleSearch = () => {
        
        SPSRequestSites(search, SetSites);
    };

    const handleChoose = (id) => {
        setShow(false);
        setSiteId(id);
    };

    return(
    <Modal show={show} onHide={() => {handleClosed()}}  size="lg" className="modalSites" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
        <Modal.Title>Recherche d'opération</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Recherche par libellé / CP / Ville" onChange={e => { setSearch(e.target.value); }} aria-label="Recherche d'opération" aria-describedby="button-searchsite" />
            </div>
            <ul className="">
                {sites.map((site) =>(
                <li className="row alternate-color" key={site.id}>
                    <div className="col-10">{site.Title}<br/>{site.Zip} {site.City}</div>
                    <div className="col-2 text-end"><button className="btn btn-primary my-2" onClick={() => handleChoose(site.id)}><span className="d-none d-md-block">Choisir</span><span className="d-block d-md-none"><FontAwesomeIcon icon={faCheck} /></span></button></div>
                </li>
                ))}
                
            </ul>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Fermer</button>
        </Modal.Footer>
    </Modal>);
}



export default ModalSearchSite;