import { createRef, Component } from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPlus, faTrashCan, faFilePdf, faImage, faXmark} from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert'; // Import


import {SPSGetSetting, SPSSaveSetting} from '../../functions/SPSSettings'
import {SPSMemos, SPSSaveMemo, SPSGetMemo, SPSDeleteMemo, SPSCatMemos, PathDocumentMemo} from '../../functions/SPSMemos';

import { Editor } from '@tinymce/tinymce-react';


export class SettingsMemo extends Component {  
  editorRef = createRef();
  constructor() {  
    super();  
    this.state = {
      idMemo: -1,
      IdCatMemo: 0,
      Type: 0,
      Descriptif: '',
      length: '',
      cats: [],
      newdocs: [],
      documents: [],
      Memos: []
    }

    
    this.chargeMemos();
    this.chargeCategories();
   
    
  }  
  
  handleRetour = (event) => {
    this.props.parentStateModifier({"showSettingsMemo" : false} );
  }

  handleChange = (value, editor) => {
    this.setState({Descriptif: value});
    this.setState({length: editor.getContent({ format: 'text' }).length});
  }

  handleClickMemo = (id) => {
    SPSGetMemo(id, this.SetTheMemo);
  }



  handleDeleteMemo = (id) => {
    SPSDeleteMemo(id, this.chargeMemos)
  }
  
  handleDocument = (e) => {
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('application/pdf') === -1 && file.type.indexOf('image/jpeg') === -1 && file.type.indexOf('image/png') === -1 && file.type.indexOf('image/gif') === -1) {
      alert("Type de fichier non autorisé");
      console.log('Type de fichier non autorisé', file.type, file);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      var docs = this.state.newdocs;
      docs.push(event.target.result);
      this.setState({newdocs: docs});
    });
    reader.readAsDataURL(file);
  };

  handleEnleverDocument = (ref) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce document ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            var content = this.state.documents.filter((v) => v != ref);
            this.setState({documents: content});
           
            
            //setDocument("remove");
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
    
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var datas = {id: this.state.idMemo, IdCatMemo: 0, Type: 0, Descriptif: '', documents: [], newdocs: []};
    datas.IdCatMemo = this.state.IdCatMemo;
    datas.Type = this.state.Type;
    datas.Descriptif = this.state.Descriptif;
    datas.documents = this.state.documents;
    datas.newdocs = this.state.newdocs;
    
    SPSSaveMemo(datas, this.afterSaveMemo);
  }

  afterSaveMemo = () => {
    this.setState({idMemo: -1, IdCatMemo: 0, Type: 0, Descriptif: '', documents: [], newdocs: []});
  }
  

  handleCancel = (e) => {
    e.preventDefault();
    this.afterSaveMemo();
  }

  handleAddMemo = (e) => {
    this.setState({idMemo: 0, IdCatMemo: 0, Type: 0, Descriptif: '', documents: [], newdocs: []});
  }

  

  SetTheMemo = (data) => {
    this.setState({
      idMemo: data.id, 
      IdCatMemo: data.IdCatMemo,
      Type: data.Type, 
      Descriptif: data.Descriptif,
      documents: data.documents, 
      newdocs: []
    });
    
    
  }

  chargeMemos = () => {
    SPSMemos(this.setMemos)
  }

  setMemos = (datas) => {
    this.setState({Memos: datas});
  }
  
  chargeCategories = () => {
    SPSCatMemos(this.setCats);
  }

  setCats = (datas) => {
    this.setState({cats: datas});
  }

  pictoDocument = (doc) => {
    if(doc.substr(-3) == 'pdf')
      return <FontAwesomeIcon icon={faFilePdf} />
    else
      return <FontAwesomeIcon icon={faImage} />
  }

  render() {  
    if(this.state.idMemo >= 0)
    {
      
      return (
      <div className="full-screen containerxl ">
        <div className="row headScreen px-0">
          <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
          <div className="col-6"><h1 className="h4 py-3">Mémo</h1></div>
          <div className="col-3 text-end py-2"></div>
        </div>
        <div className="container d-block my-2">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group row mb-3">
              <label className="col-md-4">Catégorie</label>
              <div className="col-md-8">
                <select name="catmemo" className="form-select" value={this.state.IdCatMemo} onChange={e => { this.setState({IdCatMemo: e.target.value})}}>
                  <option value="0">Choisir la catégorie</option>
                  {this.state.cats.map((r) =>(
                  <option key={r.id} value={r.id}>{r.Libelle}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row mb-3">
              <label className="col-md-4">Type</label>
              <div className="col-md-8">
                <select name="type" className="form-select" value={this.state.Type} onChange={e => { this.setState({Type: e.target.value})}}>
                  <option value="0">Choisir le type</option>
                  <option value="1">Visite</option>
                  <option value="2">Doc Exec</option>
                </select>
              </div>
            </div>
            <div className="form-group mb-3">
                <Editor
                    onInit={(evt, editor) => this.editorRef.current = editor}
                    value={this.state.Descriptif}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                        'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                        ],
                        toolbar: 'formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        
                    }}
                    tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                    onEditorChange={this.handleChange}
                />
              </div>
              <div className="form-group row mb-3">
                <label className="col-md-4">Documents</label>
                <div className="col-md-8">
                  <div className="d-block">
                    <ul className="row liste-photo">
                      {this.state.documents.map((r) =>(
                        <span>
                        {r != 'remove' &&
                      <li className="col-3 col-md-1 mb-2 line-photo text-start" key={r}>
                          <a href={PathDocumentMemo(this.state.idMemo, r)} target="_blank" className="btn btn-light color-primary btn-xl w-100" >{ this.pictoDocument(r)}</a>
                          <button type="button" className="btn-delete-line" onClick={() => { this.handleEnleverDocument(r);}}><FontAwesomeIcon icon={faXmark} /></button>
                      </li>
                        }</span>
                      ))}
                    </ul>
                    
                  </div>
                  <div className="d-inline">
                    {this.state.newdocs.map((r) =>(
                      <div key={r} className="d-inline">
                      {r && <div className="text-success">Document prêt à être envoyé</div>}
                      </div>
                    ))}
                  </div>
                  <button id="btnDocument" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faFilePdf} /><input type="file" onChange={this.handleDocument} /> Ajouter un document</label>
                  </button>
                  
                </div>
              </div>
            
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </form>
          
        </div>
      </div>

      );

    }
    else
    {
      return (  
          <div className="full-screen containerxl ">
            <div className="row headScreen px-0">
            <div className="col-3 text-start px-0 py-2"><button className="btn btn-link" onClick={this.handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6"><h1 className="h4 py-3">Mémos</h1></div>
            <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={this.handleAddMemo}><FontAwesomeIcon icon={faPlus} /></button></div>
          </div>
          <div className="container py-3">
            <h2 className="text-center">Mémos</h2>
            <ul className="my-2 liste-settings ps-1">
            {this.state.Memos.map((r) =>(
              <li className="row mb-2 alternate-color py-2" key={r.id}  >
                  <div className="col-8" onClick={() => this.handleClickMemo(r.id)}>{r.cat_Libelle}</div>
                  <div className="col-2" onClick={() => this.handleClickMemo(r.id)}>{r.Type==1?'Visite':''}{r.Type==2?'Doc Exec':''}</div>
                  <div className="col-2 text-end">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDeleteMemo(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                  </div>
              </li>
              ))}
              
            </ul>
            
          </div>
          
          
          
      </div>);
    }
  }  
}

export default SettingsMemo;
