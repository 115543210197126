import axios from "axios";
import { SPSAuthToken } from "./SPSSettings";
const token = SPSAuthToken();
const XRequest = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "company", 
  headers : { Authorization : 'Bearer ' + token }
});


export async function SPSCompanies(SetValues, idsite)
{
  var returnlocal=false;
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XRequest.get(idsite?'?site='+idsite:'');
      SetValues(result.data);
    }
    catch(err)
    {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  if(returnlocal)
  {
    const companies = SPSLocalCompanies();
    SetValues(companies);
  }
  
}

export async function SPSGetCompany(id, SetValues)
{
  try {
    const result = await XRequest.get(id);
    SetValues(result.data[0]);
  }
  catch(err)
  {
    const companies = SPSLocalCompanies();
    const s = companies.filter((c) => c.id == id);
    if(s.length > 0)
      SetValues(s[0]);
  }
  
}


/*************************************************************
 * SPSLocalCompanies
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Retourne la liste des société contenues en local (localstorage)
 *************************************************************/
function SPSLocalCompanies()
{
  var companies = [];
  const ls = localStorage.getItem('companies');
  if(ls)
  {
    try {
      companies = JSON.parse(ls);
    } catch (e) {
      companies = [];
    }
    
  }
  if(!companies.length || companies[0].id == undefined) 
  companies = [];

  return companies;
}

/*************************************************************
 * SPSStoreLocalCompanies
 * -----------------------------------------------------------
 * data : tableau des société en locale
 * -----------------------------------------------------------
 * Enregistre le tableau envoyé dans le localstorage
 *************************************************************/
function SPSStoreLocalCompanies(data)
{
  localStorage.setItem('companies', JSON.stringify(data));
}

/*************************************************************
 * SPSLoadCompanies
 * -----------------------------------------------------------
 * aucun
 * -----------------------------------------------------------
 * Charge les opérations et les stock en local
 *************************************************************/
export async function SPSLoadCompanies()
{
  if(window.SpsServerAvailable)
  {
      const companies = await XRequest.get('/');
      if(companies.data.length > 0)
      SPSStoreLocalCompanies(companies.data);
  }
}
/*************************************************************
 * SPSRequestCompanies
 * -----------------------------------------------------------
 * words : mots clés recherchés
 * SetSite : callback ou retouner les opérations trouvées
 * -----------------------------------------------------------
 * Recherche des opérations suivant des mots clés
 *************************************************************/
export async function SPSRequestCompanies(words, SetData)
{
  var returnlocal = false;
  if(window.SpsServerAvailable)
  {
    try {
      const s = await XRequest.get('/search/'+words);
      SetData(s.data);
    }
    catch(err)
    {
      returnlocal = true;
    }
  }
  else
    returnlocal = true;
  
  if(returnlocal)
  {
    const companies = SPSLocalCompanies();
    const c = companies.filter((companie) => (companie.Name+" "+companie.ZipCode+" "+companie.City).search(words) >= 0);
    SetData(c);
  }
  
  
}

export async function SPSSaveCompany(newcompany, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Name: newcompany.Name,
      Address: newcompany.Address,
      ZipCode: newcompany.ZipCode,
      City: newcompany.City,
      IdTypeCompany: newcompany.IdTypeCompany,
      Contacts: newcompany.Contacts
    };
    var result = [];
    if(newcompany.id > 0)
    {
      result = await XRequest.put("/"+newcompany.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XRequest.post("/", body);
      setShowForm(false);
    }
    if(result.data.length > 0 && result.data[0].id != undefined)
      status = 1;
  }
  else
    setShowForm(false);
  
  
}

export async function SPSDeleteCompany(id, afterDelete)
{
  if(window.SpsServerAvailable)
  {
    await XRequest.delete("/"+id);
    afterDelete();
  }
}


