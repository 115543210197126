export function datetime_to_fr(datetime)
{
    var date = new Date(datetime);
    var retour = {};

    //retour.date = date.getDate().toFixed(2)+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
    retour.date = date.toLocaleDateString('fr-FR');
    retour.hour = date.getHours()+":"+date.getMinutes();

    return retour;
}

export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
}

export function getCurrentTime(separator=''){

    let newDate = new Date()
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    
    return `${hour<10?`0${hour}`:`${hour}`}${separator}${minutes<10?`0${minutes}`:`${minutes}`}`;
}