import { useState } from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons'

function ModalGallery({showModal, handleValidate, handleClosed, gallery}) {
    const [show, setShow] = useState(showModal);
    const handleClose = () => setShow(false);
    const [localGallery, setGallery] = useState(gallery);
    useEffect(() => {
        setShow(showModal);
        
    },[showModal]);

    useEffect(() => {
        setGallery(gallery);
        
    },[gallery]);

    const handleOk = () => {
        
        
        const selection = gallery.filter((image) => image.selected == true);
        handleValidate(selection);
        handleClosed();
        
    };

    const handleClickPhoto = (idPhoto) => {
        
        const gCopy = [...localGallery];
        const p = gCopy.filter((photo) => photo.id == idPhoto);
        if(p)
        {
            if(p[0].selected == undefined || p[0].selected == false)
                p[0].selected = true;
            else
                p[0].selected = false;
        }
        setGallery(gCopy);
    };
   
    return(
    <div>
        <Modal show={show} onHide={handleClosed} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
            <Modal.Title>Galerie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    {localGallery.map((p) =>(
                        <div key={p.id} className="col-3 col-md-2 pos-relative">
                            {p.selected ? <FontAwesomeIcon icon={faCheck} className="puce-notification"/>: ''}
                            <img src={p.Photo} className={p.selected ? 'w-100 rounded border-selected': 'w-100 rouded'} onClick={() => handleClickPhoto(p.id)}/>
                        </div>
                    ))}
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleClosed}>Annuler</button>
                <button type="button" className="btn btn-primary" onClick={handleOk}>Valider</button>
            </Modal.Footer>
        </Modal>
    </div>);
}



export default ModalGallery;