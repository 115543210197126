import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi} from '@fortawesome/free-solid-svg-icons'

function OfflineIco(props) {
   if(window.SpsServerAvailable)
        return(<span></span>);
    else
        return(<div class="offline-ico"><FontAwesomeIcon icon={faWifi} /></div>);
}


export default OfflineIco;