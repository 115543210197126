import { useState } from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import ModalCamera from './ModalCamera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faImage} from '@fortawesome/free-solid-svg-icons'

function ModalPhoto({showModal, handleAdd, handleClosed, editPhoto}) {
    const [show, setShow] = useState(showModal);
    const [showCamera, setShowCamera] = useState(false);
    const [photoEdited, setEditPhoto] = useState(editPhoto);
    const [filenInputImage, setFilenInputImage] = useState();
    const [imagePreview, setImagePreview] = useState();
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(showModal);
        
    },[showModal]);

    useEffect(() => {
        setEditPhoto(editPhoto);
    },[editPhoto]);

    useEffect(() => {

    }, [photoEdited]);
    

    const setDescription = (description) => {
        const edit = photoEdited;
        edit.Description = description;
        setEditPhoto(edit);
    };

    
    const handleCamera = () => {
        
        if(showCamera==true)
            setShowCamera(false);
        setShowCamera(true);
    };
    
    function handleTakePhoto (dataUri) {
        const edit = photoEdited;
        edit.Photo = dataUri;
        setEditPhoto(edit);
    }

    function handleChangeFile(e)
    {
        setFilenInputImage(URL.createObjectURL(e.target.files[0]));
        readImage(e.target.files[0]);
        
        console.log(e.target.files[0]);
    }

    const handleOk = () => {
        if(photoEdited.id==0)
        {
            const edit = photoEdited;
            edit.id = (new Date().getTime()) * -1;
            setEditPhoto(edit);
        }
        //setShow(false);
        handleAdd(photoEdited);
        handleClosed();
        
    };
    let image = "";
    if(photoEdited.Photo != "")
    {
     console.log("mise à jour de la photo");
       
        image = <img src={photoEdited.Photo} className="w-100"/>;
        //setImagePreview(image);
    }

    function readImage(file) {
        // Check if the file is an image.
        if (file.type && file.type.indexOf('image') === -1) {
          console.log('File is not an image.', file.type, file);
          return;
        }
      
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            //let image = "";
            image = <img src={event.target.result} className="w-100"/>;
            setImagePreview(image);
            const edit = photoEdited;
            edit.Photo = event.target.result;
            setEditPhoto(edit);
        });
        reader.readAsDataURL(file);
      }

    return(
    <div>
        <Modal show={show} onHide={handleClosed} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
            <Modal.Title>Photo</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="editPhoto pos-relative">
                    {image}
                    <div className="tool-btn-bottom text-center">
                        <div className="btn-group">
                            <button className="btn btn-light btn-lg" type="button" onClick={handleCamera}><FontAwesomeIcon icon={faCamera} /></button>
                            <button className="btn btn-light btn-lg btn-file" type="button"><label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleChangeFile} required/></label></button>
                        </div>
                    </div>
                </div>
                <label>Descriptif</label>
                <textarea className="form-control" onChange={e => { setDescription(e.target.value); }} value={editPhoto.Description}></textarea>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClosed}>Annuler</Button>
                <Button variant="primary" onClick={handleOk}>Valider</Button>
            </Modal.Footer>
        </Modal>
        <ModalCamera showModal={showCamera} handleClosed={() => setShowCamera(false)} takePhoto={handleTakePhoto}></ModalCamera>
    </div>);
}



export default ModalPhoto;